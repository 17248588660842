import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { List } from 'immutable';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Link } from 'components/atoms/Link';
import { ServiceIcon } from 'components/atoms/ServiceIcon';
import { StickyHeader, Title } from 'components/atoms/StickyHeader';
import { CompetitorSearchCondition } from 'components/pageComponents/Competitor/CompetitorSearchCondition';
import { CompetitorTable } from 'components/pageComponents/Competitor/CompetitorTable';
import { TagEditModal } from 'components/pageComponents/Competitor/TagEditModal';
import { MainWrapper, WideBody } from 'components/templates/MainWrapper';
import { getPageTitle } from 'helpers/utils';
import { Competitor } from 'models/Domain/Competitor/Competitor';
import { CompetitorActions } from 'modules/competitor/actions';
import { CompetitorSelectors } from 'modules/competitor/selectors';
import { Path } from 'routes';

export const CompetitorIndex = React.memo(() => {
  const [editingCompetitor, setEditingCompetitor] = useState<Competitor | null>(null);
  const dispatch = useDispatch();
  const { currentUser, stores } = useSelector((state) => ({
    currentUser: state.app.currentUser,
    stores: state.store.stores,
  }));
  const { isLoading, searchCondition, competitors } = useSelector(CompetitorSelectors.selectState);
  const { initializePage, commitSearchCondition, deleteCompetitor, putCompetitor } = useMemo(
    () => bindActionCreators(CompetitorActions, dispatch),
    [dispatch],
  );

  useEffect(() => {
    initializePage();
  }, [initializePage]);

  const handleOnChangePage = useCallback(
    (page: number) => {
      const newSearchCondition = searchCondition.setIn(['pagination', 'page'], page);
      commitSearchCondition(newSearchCondition);
    },
    [searchCondition, commitSearchCondition],
  );

  const handleOnSelectTag = useCallback(
    (tag: string) => {
      const newSearchCondition = searchCondition
        .setIn(['filter', 'tags'], List([tag]))
        .setIn(['pagination', 'page'], 1);
      commitSearchCondition(newSearchCondition);
    },
    [commitSearchCondition, searchCondition],
  );

  const handleOnDelete = useCallback(
    (id: number) => {
      const competitor = competitors.findById(id);
      if (!competitor) {
        return;
      }
      if (!window.confirm(`競合店舗「${competitor.name}」を削除します。\nよろしいですか？`)) {
        return;
      }

      deleteCompetitor(id);
    },
    [competitors, deleteCompetitor],
  );

  const handleOnEdit = useCallback(
    (id: number) => {
      const competitor = competitors.findById(id);
      if (!competitor) {
        return;
      }
      setEditingCompetitor(competitor);
    },
    [competitors],
  );

  const handleOnChangeTags = useCallback(
    (id: number, tags: List<string>) => {
      if (!editingCompetitor) {
        return;
      }
      const newCompetitor = editingCompetitor.set('tags', tags);
      putCompetitor({ id: 1, competitor: newCompetitor });
      setEditingCompetitor(null);
    },
    [editingCompetitor, putCompetitor],
  );

  const canUseStoreConnectService = currentUser.organization
    ? currentUser.organization.canUseStoreConnectService()
    : false;

  return (
    <>
      <MainWrapper>
        <Helmet title={getPageTitle('競合店舗一覧')} />
        <StickyHeader>
          <Title>競合店舗一覧</Title>
          {canUseStoreConnectService && (
            <ServiceIconWrapper>
              <ServiceIcon.Google />
            </ServiceIconWrapper>
          )}
          <Link to={Path.competitors.register}>
            <StyledButton>まとめて登録</StyledButton>
          </Link>
        </StickyHeader>
        <WideBody>
          <CompetitorSearchCondition
            isCommitDisabled={isLoading}
            committedSearchCondition={searchCondition}
            commitSearchCondition={commitSearchCondition}
          />
          <CompetitorTable
            isLoading={isLoading}
            competitors={competitors}
            stores={stores}
            searchCondition={searchCondition}
            onChangePage={handleOnChangePage}
            onSelectTag={handleOnSelectTag}
            onDelete={handleOnDelete}
            onEdit={handleOnEdit}
          />
          {editingCompetitor && (
            <TagEditModal
              isOpen={true}
              competitor={editingCompetitor}
              onClose={() => setEditingCompetitor(null)}
              onSave={handleOnChangeTags}
            />
          )}
        </WideBody>
      </MainWrapper>
    </>
  );
});

const StyledButton = styled(Button)`
  &&& {
    width: auto;
    padding: 11px 13px;
    font-size: 14px;
  }
`;

const ServiceIconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto 0 0;
  gap: 6px;
`;
