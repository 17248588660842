import React from 'react';

import styled from 'styled-components';

import { Card } from 'components/atoms/Card';
import { Icon } from 'components/atoms/Icon';
import { COLOR } from 'style/color';

type Props = {
  labelName: string;
  isConnected: boolean;
  showEditButton?: boolean;
  onClick?: () => void;
};

/** 店舗詳細画面 連携サービスのステータスの表示・変更 */
export const ConnectServiceCard: React.FC<Props> = ({ labelName, isConnected, showEditButton = false, onClick }) => {
  return (
    <CustomCard
      isActive={showEditButton}
      onClick={() => {
        if (showEditButton && onClick) {
          onClick();
        }
      }}
    >
      <LeftContent>
        <ServiceNameLabel>{labelName}</ServiceNameLabel>
        <StatusLabel isConnected={isConnected}>{isConnected ? '連携済み' : '未連携'}</StatusLabel>
      </LeftContent>
      <RightContent>{showEditButton && <EditButton type='pencil' />}</RightContent>
    </CustomCard>
  );
};

const CustomCard = styled(Card)<{ isActive: boolean }>`
  display: flex;
  justify-content: space-between;
  margin-top: 2px;
  cursor: ${(props) => (props.isActive ? 'pointer' : 'auto')};
  word-wrap: break-word;
  white-space: pre-wrap;
`;

const LeftContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 24px);
  margin-right: 16px;
`;

const ServiceNameLabel = styled.div`
  color: ${COLOR.BLACK};
  font-size: 16px;
  font-weight: bold;
`;

const StatusLabel = styled.div<{ isConnected: boolean }>`
  font-size: 14px;

  // 連携中の場合、緑色かつ太字で表示
  color: ${(props) => (props.isConnected ? COLOR.GREEN : COLOR.GRAY)};
  font-weight: ${(props) => (props.isConnected ? 'bold' : 'normal')};
`;

const RightContent = styled.div`
  flex: none;
  display: flex;
  align-items: flex-start;
`;

const EditButton = styled(Icon)`
  &&& {
    width: 20px;
    height: 20px;
    padding: 0;
  }
`;
