import { parse } from 'querystring';

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-semantic-toasts';
import { bindActionCreators } from 'redux';
import { Divider } from 'semantic-ui-react';
import styled from 'styled-components';

import { GmbAccountApi, GmbAuthApi } from 'ApiClient/GmbApi';
import { YahooPlaceAuthApi } from 'ApiClient/YahooPlaceApi';
import { Button } from 'components/atoms/Button';
import { Input } from 'components/atoms/Input';
import { StickyHeader } from 'components/atoms/StickyHeader';
import { YahooPlaceAccountModal } from 'components/organisms/YahooPlaceAccountModal';
import AccountList from 'components/pageComponents/StoreAccountList/AccountListContent';
import { Body, MainWrapper } from 'components/templates/MainWrapper';
import { replaceWithOrganizationId } from 'helpers/router';
import { getPageTitle } from 'helpers/utils';
import { useStorage } from 'hooks/useStorage';
import YahooPlaceAuth from 'models/Domain/YahooPlace/Auth';
import { AbcActions } from 'modules/abc/actions';
import { GmbActions } from 'modules/gmb/actions';
import { OrganizationActions } from 'modules/organization/actions';
import { YahooPlaceActions } from 'modules/yahooPlace/actions';
import { COLOR } from 'style/color';

/**
 * NOTE スコープ追加時の注意
 *
 * - 新しいスコープを追加する際、それが制限されたスコープまたは機密性の高いスコープの場合、
 *   GBPのOAuth認証画面の設定から利用するスコープを追加して審査を通過してから使うこと
 * - 審査を受けず未認証のまま制限または機密スコープを要求すると警告画面が挟まってしまう
 */
const scopes = [
  'https://www.googleapis.com/auth/userinfo.email',
  'https://www.googleapis.com/auth/userinfo.profile',
  'https://www.googleapis.com/auth/plus.business.manage',
  'https://www.googleapis.com/auth/analytics.readonly',
  'https://www.googleapis.com/auth/content',
];

const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

// 文字列の長さを渡して、ランダムな文字列を生成する
const generateRandomString = (length: number) => {
  return Array.from(crypto.getRandomValues(new Uint8Array(length)))
    .map((n) => characters[n % characters.length])
    .join('');
};

const DuplicateGmbPanel = ({ onClick }: { onClick: (email: string) => void }) => {
  const [email, setEmail] = useState('@pathee.com');
  const onChangeEmail = useCallback((value: string) => {
    setEmail(value);
  }, []);
  const canRegister = useMemo(() => email.match(/.+@pathee\.com/), [email]);
  const onClickButton = useCallback(() => {
    onClick(email);
  }, [email, onClick]);

  return (
    <Wrapper>
      <Title>Googleアカウントのコピー</Title>
      <PanelDescription>
        同一メールアドレスを多く新規登録すると古いものから認証情報が無効になってしまうため、メールアドレスを指定して認証情報をコピーを利用してください。特定のアカウントのみコピー可能です。
      </PanelDescription>
      <InputWrapper>
        <Input value={email} onChange={onChangeEmail} placeholder='xxxxx@pathee.com' />
        <Button priority='high' disabled={!canRegister} onClick={onClickButton}>
          追加
        </Button>
      </InputWrapper>
    </Wrapper>
  );
};

interface YahooLoginButtonProps {
  onClick: () => void;
}

const YahooLoginButton: React.FC<YahooLoginButtonProps> = ({ onClick }) => {
  return (
    <YahooButtonWrapper onClick={onClick}>
      <LogoWrapper>
        <Logo src='/images/yahoo_japan_icon_256.png' alt='Yahoo Logo' />
      </LogoWrapper>
      <ButtonWrapper>ログイン</ButtonWrapper>
    </YahooButtonWrapper>
  );
};

export const StoreAccounts: React.FC = () => {
  const dispatch = useDispatch();
  const [selectedAccountId, setSelectedAccountId] = useStorage<number | null>('EDITING_ACCOUNT_ID', null, {
    changeDetection: false,
    storageType: 'session',
  });
  const [selectedYahooAccountId, setSelectedYahooAccountId] = useStorage<number | null>(
    'EDITING_YAHOO_ACCOUNT_ID',
    null,
    {
      changeDetection: false,
      storageType: 'session',
    },
  );
  const changeSelectedAccountId = useCallback(
    (accountId: number | null) => setSelectedAccountId(accountId),
    [setSelectedAccountId],
  );
  const changeSelectedYahooAccountId = useCallback(
    (accountId: number | null) => setSelectedYahooAccountId(accountId),
    [setSelectedYahooAccountId],
  );
  const [showYahooPlaceAccountModal, setShowYahooPlaceAccountModal] = useState(false);

  const { initializePage } = useMemo(() => bindActionCreators(OrganizationActions, dispatch), [dispatch]);
  const currentUser = useSelector((state) => state.app.currentUser);

  const getGoogleAuthorizeUrl = async () => {
    try {
      // リダイレクトURLにパラメータが含まれるとログイン画面が正確に表示されないのでクエリパラメータを含めない
      const response = await GmbAuthApi.post({
        redirect_uri: `${window.location.origin}${window.location.pathname}`,
        scopes,
      });
      return response.isSuccess && response.data.url;
    } catch (e) {
      toast({
        type: 'error',
        title: 'Googleアカウントの認証用URLの取得に失敗しました',
        description: String(e),
        time: 10000,
      });
      return null;
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get('code'); // URLから code パラメータを取得
    const receivedState = params.get('state'); // URLから state パラメータを取得

    if (code && receivedState && currentUser.canUseAccountForYahooPlace) {
      dispatch(
        YahooPlaceActions.setYahooPlaceAuth(
          new YahooPlaceAuth({
            accountId: selectedYahooAccountId,
            redirectUri: window.location.origin + window.location.pathname,
            state: receivedState,
            code: code,
          }),
        ),
      );
      setShowYahooPlaceAccountModal(true);
    }
    // dispatchを含めるとエラーになるので、eslint-disable-next-lineで無効化
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseYahooModal = () => {
    setShowYahooPlaceAccountModal(false); // モーダルを閉じる
  };

  const getYahooAuthorizeUrl = async () => {
    try {
      // stateとnonce値を生成する（文字列の長さは32）
      const createdState = generateRandomString(32);
      const nonce = generateRandomString(32);

      const response = await YahooPlaceAuthApi.post({
        state: createdState,
        nonce: nonce,
        redirect_uri: `${window.location.origin}${window.location.pathname}`,
      });
      return response.isSuccess && response.data.url;
    } catch (e) {
      toast({
        type: 'error',
        title: 'Yahoo! プレイス アカウントの認証用URLの取得に失敗しました',
        description: String(e),
        time: 10000,
      });
      return null;
    }
  };

  // TODO: 子ウィンドウを開いて認証処理を行うように修正する
  const signInGoogle = async (accountId?: number) => {
    changeSelectedAccountId(accountId ? accountId : null);
    const authorizeUrl = await getGoogleAuthorizeUrl();
    if (authorizeUrl) {
      window.location.href = authorizeUrl;
    }
  };

  // state, redirect_uri, codeを含めてリクエストを送信する
  const signInYahoo = async (accountId?: number) => {
    changeSelectedYahooAccountId(accountId ? accountId : null);
    const authorizeUrl = await getYahooAuthorizeUrl();
    if (authorizeUrl) window.location.href = authorizeUrl;
  };

  const canUseAbc = currentUser.organization ? currentUser.organization.canUseAppleBusinessConnect() : false;
  const canUseYahooPlace = currentUser.organization ? currentUser.organization.canUseYahooPlace() : false;

  useEffect(() => {
    // organization情報を取得
    initializePage();
    updateGbpList();
    setSelectedYahooAccountId(null);
    // 初回表示時のみ実行するため、depsを空で設定
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (canUseAbc) {
      dispatch(AbcActions.getAccounts());
    }
    if (canUseYahooPlace) {
      dispatch(YahooPlaceActions.getAccounts());
    }
  }, [canUseAbc, canUseYahooPlace, dispatch]);

  const gmbAccounts = useSelector((state) => state.gmb.gmbList);
  const abcAccounts = useSelector((state) => state.abc.accounts);
  const yahooAccounts = useSelector((state) => state.yahooPlace.accounts);

  const updateGbpList = async () => {
    const params = parse(window.location.search.slice(1));

    // TODO: エラーの場合に対応する
    if (params.state && params.code && params.scope) {
      // 認証パラメーターを送ることで連携が完了
      if (selectedAccountId) {
        // account_idをリクエストパラメータに含めることで、既存のレコードを更新する
        params.account_id = String(selectedAccountId);
      }
      params.redirect_uri = `${window.location.origin}${window.location.pathname}`;
      // Googleアカウントの認証情報を、DB（gmb.account）に追加/更新する
      await GmbAccountApi.post(params);
      // URLパラメーターを削除する
      dispatch(replaceWithOrganizationId(window.location.pathname));
    }

    dispatch(GmbActions.getGmbList());
  };

  const duplicateAccount = useCallback(
    async (email: string) => {
      const response = await GmbAccountApi.duplicate({ email });
      if (response.isSuccess) {
        dispatch(GmbActions.getGmbList());
      } else {
        toast({
          type: 'warning',
          title: 'Googleアカウントの複製ができませんでした',
          time: 10000,
        });
      }
    },
    [dispatch],
  );

  const onClickRemoveGBPAccount = useCallback(
    (id: number) => {
      const account = gmbAccounts.find((account) => account.id === id);
      if (!account) {
        return;
      }
      if (!window.confirm(`${account.name} のGoogleアカウント連携を解除しますか？`)) {
        return;
      }
      dispatch(GmbActions.removeGmbAccount(id));
    },
    [dispatch, gmbAccounts],
  );

  const onClickRemoveABCAccount = useCallback(
    (id: number) => {
      const account = abcAccounts.list.find((account) => account.id === id);
      if (!account) {
        return;
      }
      if (!window.confirm(`${account.name} のApple Business Connect連携を解除しますか？`)) {
        return;
      }
      dispatch(AbcActions.removeAccount(id));
    },
    [dispatch, abcAccounts],
  );

  const onClickRemoveYahooAccount = useCallback(
    (id: number) => {
      const account = yahooAccounts.find((account) => account.id === id);
      if (!account) {
        return;
      }
      if (!window.confirm(`${account.name} のYahoo! プレイス アカウント連携を解除しますか？`)) {
        return;
      }
      dispatch(YahooPlaceActions.deleteAccount(id));
    },
    [dispatch, yahooAccounts],
  );

  return (
    <MainWrapper>
      <Helmet title={getPageTitle('店舗連携アカウント一覧')} />
      <StickyHeader title='店舗連携アカウント一覧' />
      <Body>
        {/* Googleアカウント */}
        <Heading>Googleアカウント</Heading>
        <AccountList
          showImportButton
          accounts={gmbAccounts.map((gmb) => ({ id: gmb.id, name: gmb.name, email: gmb.email }))}
          onAuthorize={signInGoogle}
          onClickRemoveAccount={onClickRemoveGBPAccount}
        />
        <Wrapper>
          <Title>Googleアカウントの追加</Title>
          <PanelDescription>次のボタンを押してアプリを認証すると、Googleアカウントを追加できます。</PanelDescription>
          <figure onClick={() => signInGoogle()}>
            <img src={'/images/google_signin_button.png'} style={{ width: '280px' }} />
          </figure>
        </Wrapper>
        {currentUser.canUseDuplicateGbpAccount && <DuplicateGmbPanel onClick={duplicateAccount} />}
        {/* Apple Business Connect アカウント */}
        {canUseAbc && (
          <>
            <Divider />
            <Heading>Apple Business Connect アカウント</Heading>
            <AccountList
              accounts={abcAccounts.list.map((abc) => ({ id: abc.id, name: abc.name, email: '' }))}
              onAuthorize={() => console.log('TODO')}
              onClickRemoveAccount={(id) => onClickRemoveABCAccount(id)}
            />
            <Wrapper>
              <Title>Apple Business Connect アカウントの追加</Title>
              <PanelDescription>
                次のボタンを押してアプリを認証すると、Apple Business Connect アカウントを追加できます。
              </PanelDescription>
              <ABCConnectButton>アカウントを追加</ABCConnectButton>
            </Wrapper>
          </>
        )}
        {/* Yahoo!プレイス アカウント */}
        {canUseYahooPlace && (
          <>
            <Divider />
            <Heading>Yahoo!プレイス アカウント</Heading>
            <AccountList
              accounts={yahooAccounts.map((account) => ({
                id: account.id,
                name: account.name,
                email: account.email,
              }))}
              onAuthorize={signInYahoo}
              onClickRemoveAccount={(id) => onClickRemoveYahooAccount(id)}
            />
            <Wrapper>
              <Title>Yahoo!プレイス アカウントの追加</Title>
              <PanelDescription>
                次のボタンを押してアプリを認証すると、Yahoo!プレイス アカウントを追加できます。
              </PanelDescription>
              <YahooLoginButton onClick={signInYahoo} />
              {showYahooPlaceAccountModal && (
                <YahooPlaceAccountModal
                  isOpen={showYahooPlaceAccountModal}
                  onClose={handleCloseYahooModal}
                  setSelectedYahooAccountId={setSelectedYahooAccountId}
                />
              )}
            </Wrapper>
          </>
        )}
      </Body>
    </MainWrapper>
  );
};

const Wrapper = styled.div`
  padding-top: 30px;
  z-index: 1;
`;

const Heading = styled.h2`
  font-size: 18px;
  font-weight: bold;
`;

const Title = styled.h3`
  font-weight: bold;
  font-size: 16px;
  flex-shrink: 0;
  margin-bottom: 5px;
`;

const PanelDescription = styled.p`
  color: ${COLOR.DARK_GRAY};
  font-size: 13px;
  margin: 0 0 10px 4px;
`;

const ABCConnectButton = styled.div`
  font-weight: bold;
  background-color: black;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 48px;
  border-radius: 8px;
  cursor: pointer;
`;

const InputWrapper = styled.div`
  display: flex;
  *:not(:first-child) {
    margin-left: 16px;
  }
`;

const YahooButtonWrapper = styled.div`
  display: flex;
  cursor: pointer; /* クリック可能なカーソルに変更 */
  align-items: center;
  border-radius: 3px; /* 全体の角を丸める */
  overflow: hidden; /* 丸めた角に合わせて表示を切り取る */
  background-color: #ff0033; /* ボタンの背景色 */
  width: 190px; /* ボタンの幅を固定 */
`;

const LogoWrapper = styled.div`
  background-color: white; /* ロゴ部分の背景色を白に */
  border: 2px solid #ff0033;
  border-radius: 3px;
  padding: 5px; /* ロゴ周りのパディング */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px; /* ロゴ部分の幅を固定 */
  height: 48px; /* ボタンの高さと合わせる */
`;

const Logo = styled.img`
  height: 24px; /* ロゴの高さ */
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 16px;
  font-weight: bold;
  height: 48px; /* ボタン全体の高さを固定 */
  padding: 0 20px; /* テキスト周りに余白を設定 */
  width: 142px; /* ボタンの幅を固定 */
`;
