import { Record } from 'immutable';

import { JSObject } from 'types/Common';

export interface YahooPlaceAuthRecord {
  accountId: number | null;
  redirectUri: string;
  state: string;
  code: string;
}

export default class YahooPlaceAuth extends Record<YahooPlaceAuthRecord>({
  accountId: null,
  redirectUri: '',
  state: '',
  code: '',
}) {
  static fromJson(data: JSObject = {}): YahooPlaceAuth {
    return new YahooPlaceAuth({
      accountId: data.account_id,
      redirectUri: data.redirect_uri,
      state: data.state,
      code: data.code,
    });
  }
}
