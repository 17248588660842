import React, { useCallback, useEffect, useMemo } from 'react';

import { Helmet } from 'react-helmet-async';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Link } from 'components/atoms/Link';
import { ServiceIcon } from 'components/atoms/ServiceIcon';
import { StickyHeader, Title } from 'components/atoms/StickyHeader';
import { SwitchContent } from 'components/molecules/SwitchContent';
import { InventorySummaryTable } from 'components/pageComponents/InventoryStoreList/InventSummaryTable';
import { StoreFilter } from 'components/pageComponents/InventoryStoreList/StoreFilter';
import { StoreSearchConditionSummary } from 'components/pageComponents/InventoryStoreList/StoreSearchConditionSummary';
import { MainWrapper, WideBody } from 'components/templates/MainWrapper';
import { getPageTitle } from 'helpers/utils';
import { InventorySummary } from 'models/Domain/Omo/InventorySummary';
import { StoreSearchCondition } from 'models/Domain/Omo/StoreSearchCondition';
import { SortType } from 'models/Domain/Store';
import { InventoryActions } from 'modules/inventory/actions';
import { State } from 'modules/reducers';
import { Path } from 'routes';
import { COLOR } from 'style/color';

export const InventoryStoreList = React.memo(() => {
  const dispatch = useDispatch();
  const { initializeInventoryStoreList, setStoreSearchCondition } = useMemo(
    () => bindActionCreators(InventoryActions, dispatch),
    [dispatch],
  );

  // 必要なデータをstateから取得
  const { isLoading, searchCondition, committedSearchCondition, inventorySummaryList } = useSelector(
    (state: State) => ({
      isLoading: state.inventory.isLoadingInventorySummary,
      searchCondition: state.inventory.storeSearchCondition,
      committedSearchCondition: state.inventory.committedStoreSearchCondition,
      inventorySummaryList: state.inventory.inventorySummaryList,
    }),
    shallowEqual,
  );
  const { storeLists, stores, currentUser } = useSelector(
    (state: State) => ({
      storeLists: state.storeList.storeLists,
      stores: state.store.stores,
      currentUser: state.app.currentUser,
    }),
    shallowEqual,
  );

  // 検索条件に一致する店舗の絞り込み・並び替え
  const { key: sortKey, order: sortOrder } = committedSearchCondition.sort;
  const filter = committedSearchCondition.filter;
  const { searchValue, storeListId } = filter;
  const selectedSortType = `${sortKey}_${sortOrder}` as SortType;
  // 閉店店舗とGMB未連携の店舗は表示なし
  let filteredStores = stores.filterByUnclosed().filterByIsConnectedGmb();
  // 店舗スタッフは所属店舗のみに絞り込み
  if (currentUser.isMemberUser) {
    filteredStores = filteredStores.filterStoresById(currentUser.stores.toArray());
  }
  // SVは管理店舗のみに絞り込み
  if (currentUser.isSvUser) {
    filteredStores = filteredStores.filterStoresById(currentUser.managing_stores.toArray());
  }
  // グループが指定されていたら、グループに属する店舗のみに絞り込み
  if (storeListId !== 'all' && storeListId !== 'my_store') {
    filteredStores = filteredStores.filterStoresByStoreListId(storeLists, storeListId);
  }
  // 店舗コードをもつ店舗のみ表示する
  filteredStores = filteredStores.filterStoresHasStoreCode();
  // 検索ワードが指定されていたら、検索ワードで絞り込み
  filteredStores = filteredStores.filterStores(searchValue).sortBy(selectedSortType);

  // 絞り込み・並び替えされた店舗データをもとに、在庫サマリーを絞り込み・並び替え
  // 在庫サマリーが取得中は仮データを作成する
  const filteredInventorySummaryList = filteredStores.list.map(
    (store) =>
      inventorySummaryList.find((item) => item.storeId === store.id) ||
      new InventorySummary({ store, storeId: store.id }),
  );

  /**
   * 検索条件を変更する（検索するまで検索結果には反映されない）
   */
  const onChangeSearchCondition = useCallback(
    (searchCondition: StoreSearchCondition) => {
      setStoreSearchCondition({ searchCondition, updateLocation: false });
    },
    [setStoreSearchCondition],
  );

  /**
   * 検索条件を確定する（検索結果に反映する）
   */
  const onCommitSearchCondition = useCallback(() => {
    setStoreSearchCondition({ searchCondition, updateLocation: true });
  }, [searchCondition, setStoreSearchCondition]);

  const canUseStoreConnectService = currentUser.organization
    ? currentUser.organization.canUseStoreConnectService()
    : false;

  useEffect(() => {
    initializeInventoryStoreList();
  }, [initializeInventoryStoreList]);

  return (
    <MainWrapper>
      <Helmet title={getPageTitle('店頭在庫')} />
      <StickyHeader>
        <Title>店頭在庫</Title>
        {canUseStoreConnectService && (
          <ServiceIconWrapper>
            <ServiceIcon.Google />
          </ServiceIconWrapper>
        )}
        <Link to={Path.omo.inventoryImport}>
          <StyledButton>CSVでまとめて更新</StyledButton>
        </Link>
      </StickyHeader>
      <WideBody>
        <StoreFilterWrapper>
          <SwitchContent
            openLabel={'絞り込み条件を閉じる'}
            closedLabel={'絞り込み条件を変更する'}
            closedContents={
              <StoreSearchConditionSummary searchCondition={committedSearchCondition} storeLists={storeLists} />
            }
          >
            <StoreFilter
              searchCondition={searchCondition}
              onChangeSearchCondition={onChangeSearchCondition}
              onCommitSearchCondition={onCommitSearchCondition}
            />
          </SwitchContent>
        </StoreFilterWrapper>
        <Content>
          <Count>{filteredInventorySummaryList.size}件の検索結果</Count>
          <InventorySummaryTable inventorySummaryList={filteredInventorySummaryList} isLoading={isLoading} />
        </Content>
      </WideBody>
    </MainWrapper>
  );
});

const StyledButton = styled(Button)`
  &&& {
    width: auto;
    padding: 11px 13px;
    font-size: 14px;
  }
`;

const StoreFilterWrapper = styled.div``;

const Count = styled.div`
  font-weight: bold;
  font-size: 16px;
  margin-top: 16px;
  color: ${COLOR.CHARCOAL_GRAY};
`;

const Content = styled.div`
  position: relative;
  background: transparent;
`;

const ServiceIconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto 0 0;
  gap: 6px;
`;
