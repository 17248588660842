import { toast } from 'react-semantic-toasts';
import { put, select, takeLatest } from 'redux-saga/effects';

import { CsvDownloadApi, CsvImportApi, CsvImportApiStatusCheckApi, CsvValidateApi } from 'ApiClient/StoreApi';
import { Stores } from 'models/Domain/Store';
import { JSObject } from 'types/Common';

import { AppActions } from '../app/actions';
import { State } from '../reducers';

import { StoresImportActions } from './actions';

export default function* saga() {
  yield takeLatest(StoresImportActions.storesImportValidate, validate);
  yield takeLatest(StoresImportActions.importStores, importStores);
  yield takeLatest(StoresImportActions.checkCsvImportStatus, checkCsvImportStatus);
  yield takeLatest(StoresImportActions.downloadStoresCsv, downloadStoresCsv);
}

/**
 * csvファイルのバリデーションをAPI経由で実行する
 */
function* validate(action: ReturnType<typeof StoresImportActions.storesImportValidate>) {
  yield put(StoresImportActions.setIsProcessingValidate(true));
  const response: JSObject = yield CsvValidateApi.post({ csv: action.payload });
  if (response.isSuccess) {
    yield put(
      StoresImportActions.setValidateResult({
        isValidateError: response.data.validation_status !== 'success',
        isValidateWarning: response.data.total_warning > 0,
        validateWarnings: response.data.validation_warnings,
        validateErrors: response.data.validation_errors,
        totalSucceed: response.data.total_succeed,
        totalWarning: response.data.total_warning,
        totalFailed: response.data.total_failed,
      }),
    );
  } else {
    yield put(AppActions.setLoading(false));
    toast({
      type: 'error',
      title: 'ファイルのバリデーションに失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
  yield put(StoresImportActions.setIsProcessingValidate(false));
}

/**
 * csvファイルインポートを実行する
 */
function* importStores(action: ReturnType<typeof StoresImportActions.importStores>) {
  yield put(StoresImportActions.setIsProcessingImport(true));
  yield put(StoresImportActions.clearValidateResult());
  const response: JSObject = yield CsvImportApi.post({ csv: action.payload });
  if (response.isSuccess) {
    yield put(StoresImportActions.setExectionArn(response.data.execution_arn));
  } else {
    toast({
      type: 'error',
      title: '店舗情報の更新に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
    yield put(StoresImportActions.setIsProcessingImport(false));
  }
}

/**
 * csvファイルのインポートの状態をAPI経由で確認し、完了指定たら確認を終了し、完了していない場合は引き続き確認を実行する
 */
function* checkCsvImportStatus(action: ReturnType<typeof StoresImportActions.checkCsvImportStatus>) {
  const executionArn: string = yield select((state: State) => state.storesImport.executionArn);
  if (executionArn) {
    const response: JSObject = yield CsvImportApiStatusCheckApi.post({ executionArn });
    if (response.isSuccess) {
      if (response.data.status === 'RUNNING') {
        // do nothing
      } else if (response.data.status === 'SUCCEEDED') {
        toast({
          type: 'success',
          title: '店舗情報を更新しました',
        });
        yield put(StoresImportActions.setExectionArn(''));
        yield put(
          StoresImportActions.setImportStatus({
            importStatus: response.data.status,
            importResults: response.data.results,
          }),
        );
        yield put(StoresImportActions.setIsProcessingImport(false));
      } else {
        toast({
          type: 'error',
          title: String(response.data.message),
          description: '',
          time: 10000,
        });
        yield put(StoresImportActions.setExectionArn(''));
        yield put(
          StoresImportActions.setImportStatus({
            importStatus: response.data.status,
            importResults: response.data.results,
          }),
        );
        yield put(StoresImportActions.setIsProcessingImport(false));
      }
    } else {
      yield put(StoresImportActions.setIsProcessingImport(false));
      toast({
        type: 'error',
        title: '店舗情報の更新ステータス取得に失敗しました',
        description: String(response.error.message),
        time: 10000,
      });
    }
  }
}

function* downloadStoresCsv(action: ReturnType<typeof StoresImportActions.downloadStoresCsv>) {
  const stores: Stores = yield select((state: State) => state.store.stores);

  const response: YieldReturn<typeof CsvDownloadApi.get> = yield CsvDownloadApi.get({
    store_ids: stores.list.map((t) => t.id).toArray(),
  });

  if (response.isSuccess) {
    window.location.assign(response.data.download_url);
    toast({
      type: 'success',
      title: 'CSVファイルをダウンロードしました',
    });
  } else {
    toast({
      type: 'error',
      title: 'CSVファイルのダウンロードに失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
}
