import { toast } from 'react-semantic-toasts';
import { put, takeLatest } from 'redux-saga/effects';

import { ABCAccountsAPI } from 'ApiClient/AbcApi';
import { StoreConnectAbcApi, StoreDisconnectAbcApi } from 'ApiClient/StoreApi';
import { AbcActions } from 'modules/abc/actions';
import { AppActions } from 'modules/app/actions';
import { StoreActions } from 'modules/store/actions';
import { StoreDetailActions } from 'modules/storeDetail/actions';

export default function* saga() {
  yield takeLatest(AbcActions.getAccounts, getAccounts);
  yield takeLatest(AbcActions.removeAccount, removeAccount);
  yield takeLatest(AbcActions.getLocations, getLocations);
  yield takeLatest(AbcActions.connect, connect);
  yield takeLatest(AbcActions.disconnect, disconnect);
}

/** アカウントの一覧取得 */
function* getAccounts() {
  const response: YieldReturn<typeof ABCAccountsAPI.get> = yield ABCAccountsAPI.get();

  if (response.isSuccess) {
    const data = response.data.items;
    yield put(AbcActions.setAccounts(data));
  } else {
    toast({
      type: 'error',
      title: 'Apple Business Connectアカウントの取得に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }
}

/** アカウントの連携を解除 */
function* removeAccount(action: ReturnType<typeof AbcActions.removeAccount>) {
  yield put(AppActions.setLoading(true));

  const id = action.payload;
  const response: YieldReturn<typeof ABCAccountsAPI.delete> = yield ABCAccountsAPI.delete(id);

  if (response.isSuccess) {
    // アカウント一覧を取得し直す
    yield put(AbcActions.getAccounts());
  } else {
    toast({
      type: 'error',
      title: 'Apple Business Connectアカウントの連携解除に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }

  yield put(AppActions.setLoading(false));
}

/** 組織に紐づくアカウント, ビジネス, ロケーション一覧の取得 */
function* getLocations() {
  yield put(AppActions.setLoading(true));

  // FIXME APIにリクエストする
  // const response: YieldReturn<typeof AbcLocationsApi.get> = yield AbcLocationsApi.get();
  const response = {
    isSuccess: true,
    data: {
      data: [
        {
          id: 1,
          name: 'Pathee',
          businesses: [
            {
              id: 'b1',
              name: 'ビジネス1',
              locations: [
                {
                  id: 'l1',
                  displayName: '株式会社Pathee1',
                },
                {
                  id: 'l2',
                  displayName: '株式会社Pathee2',
                },
              ],
            },
            {
              id: 'b2',
              name: 'ビジネス2',
              locations: [
                {
                  id: 'l3',
                  displayName: '株式会社Pathee3',
                },
                {
                  id: 'l4',
                  displayName: '株式会社Pathee4',
                },
              ],
            },
          ],
        },
        // ビジネスに紐づかないロケーションは、id, name: nullで返す
        {
          id: 2,
          name: 'STORECAST',
          businesses: [
            {
              id: null,
              name: null,
              locations: [
                {
                  id: 'l5',
                  displayName: '株式会社Pathee5',
                },
                {
                  id: 'l6',
                  displayName: '株式会社Pathee6',
                },
              ],
            },
            {
              id: 'b3',
              name: 'ビジネス3',
              locations: [
                {
                  id: 'l7',
                  displayName: '株式会社Pathee7',
                },
                {
                  id: 'l8',
                  displayName: '株式会社Pathee8',
                },
              ],
            },
          ],
        },
      ],
    },
    error: { message: null },
  };

  if (response.isSuccess) {
    const { data } = response.data;
    yield put(AbcActions.setAccounts(data));
  } else {
    toast({
      type: 'error',
      title: 'Apple Business Connectロケーション一覧の取得に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }

  yield put(AppActions.setLoading(false));
}

/** 店舗をApple Business Connectロケーションと連携 */
function* connect(action: ReturnType<typeof AbcActions.connect>) {
  yield put(AppActions.setLoading(true));

  const { storeId, accountId, locationId } = action.payload;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const params = { account_id: accountId, location_id: locationId };
  const response: YieldReturn<typeof StoreConnectAbcApi.post> = yield StoreConnectAbcApi.post(storeId, params);

  if (response.isSuccess) {
    yield put(StoreActions.getStores());
    toast({
      type: 'success',
      title: '店舗のApple Business Connect連携が完了しました',
    });
    // FIXME 店舗個別ページ向けのデータ取得がここで呼ばれているのはおかしい
    yield put(StoreDetailActions.getStore(storeId));
  } else {
    toast({
      type: 'error',
      title: '店舗のApple Business Connectとの連携に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }

  yield put(AppActions.setLoading(false));
}

/** 店舗のApple Business Connectロケーションとの連携を解除 */
function* disconnect(action: ReturnType<typeof AbcActions.disconnect>) {
  yield put(AppActions.setLoading(true));

  const storeId = action.payload;
  const response: YieldReturn<typeof StoreDisconnectAbcApi.post> = yield StoreDisconnectAbcApi.post(storeId);

  if (response.isSuccess) {
    yield put(StoreActions.getStores());
    toast({
      type: 'success',
      title: '店舗のApple Business Connect連携を解除しました',
    });
    // FIXME 店舗個別ページ向けのデータ取得がここで呼ばれているのはおかしい
    yield put(StoreDetailActions.getStore(storeId));
  } else {
    toast({
      type: 'error',
      title: '店舗のApple Business Connectとの連携解除に失敗しました',
      description: String(response.error.message),
      time: 10000,
    });
  }

  yield put(AppActions.setLoading(false));
}
