import { List, Record } from 'immutable';

import Location from 'models/Domain/Abc/Location';
import { JSObject } from 'types/Common';

export interface BusinessRecord {
  id: string;
  accountId: number;
  name: string | null; // nullの場合、ビジネスに紐づいていないロケーション
  locations: List<Location>;
}

export default class Business extends Record<BusinessRecord>({
  id: '',
  accountId: 0,
  name: '',
  locations: List<Location>(),
}) {
  static fromJson(data: JSObject = {}): Business {
    const locations = data.locations
      ? (data.locations = List(
          data.locations.map((location: JSObject) =>
            Location.fromJson({ ...location, businessId: data.id, accountId: data.accountId }),
          ),
        ))
      : List();
    return new Business({ id: data.id, accountId: data.accountId, name: data.name, locations });
  }

  get displayName() {
    return this.name || 'ビジネスなし';
  }
}
