import { rgba } from 'polished';
import { Checkbox, Table } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Icon } from 'components/atoms/Icon';
import { COLOR } from 'style/color';

// Semantic UIのCellの`disabled`をtrueにすると表示が薄くなるが、
// 文字のみで種別のラベルや、属性のアイコンは半透明にならないため個別にprops定義している
export const StyledCell = styled(Table.Cell)<{
  updated: 'true' | 'false';
  first?: 'true' | 'false';
  isselected?: 'true' | 'false';
}>`
  &&& {
    padding: 16px;
    vertical-align: top;
    opacity: ${({ updated }) => (updated === 'true' ? 0.5 : 1)};
    background: ${({ isselected }) => (isselected === 'true' ? rgba(COLOR.GREEN, 0.1) : 'inherit')};
    ${({ first }) =>
      first === 'true' &&
      css`
        box-shadow: 0 -3px 0px #e3e4e5;
      `}
  }
`;

export const StyledFirstCell = styled(StyledCell)`
  &&& {
    padding: 16px;
    position: sticky;
    left: 0;
    z-index: 1;
    background: ${COLOR.WHITE};
  }
`;

export const CheckLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const StyledCheckbox = styled(Checkbox)`
  margin-right: 25px;
`;

export const TypeLabel = styled.div<{ isGoogleUpdated: boolean }>`
  display: inline-block;
  color: ${(props) => (props.isGoogleUpdated ? `${COLOR.WHITE}` : `${COLOR.BLACK}`)};
  background: ${(props) => (props.isGoogleUpdated ? `${COLOR.GRAY}` : '#F2F2F2')};
  border-radius: 50px;
  width: 127px;
  padding: 0 4px;
  text-align: center;
  font-size: 13px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  :not(:first-child) {
    margin-top: 12px;
  }
`;

export const StyledButton = styled(Button)`
  &&& {
    width: auto;
    min-width: 130px;
    margin-right: 8px;
    margin-left: 8px;
    padding: 8px;
    border-radius: 0;
    color: ${COLOR.GOOGLE};
    border-color: ${COLOR.GOOGLE};

    :disabled {
      padding: 8px;
    }
  }
`;

export const StyledCancelButton = styled(StyledButton)`
  &&& {
    color: ${COLOR.GREEN};
    border-color: ${COLOR.GREEN};
  }
`;

export const CustomButton = styled(StyledButton)`
  &&& {
    width: 100%;
    color: ${COLOR.BLACK};
    border-color: ${COLOR.BLACK};
    display: flex;
    align-items: center;
    justify-content: center;

    :disabled {
      border-color: ${COLOR.GRAY};
    }
  }
`;

export const StatusLabel = styled.span``;
export const ResetButton = styled(Button).attrs(() => ({ priority: 'low' }))`
  &&& {
    font-size: 16px;
    font-weight: normal;
    margin-right: 18px;
    margin-left: 18px;
    min-height: 38px;
  }
`;

export const StoreLink = styled.span`
  color: ${COLOR.GREEN};
  text-decoration: underline;
  font-size: 12px;
  font-weight: bold;
  vertical-align: baseline;
`;

export const WarningMessage = styled.div`
  margin-top: 8px;
  font-size: 12px;
`;

export const ValueWrapper = styled.div`
  display: flex;
`;

export const ValueLabelsWrapper = styled.div`
  display: block;
`;

export const CautionIconWrapper = styled.div`
  margin: auto 0 auto auto;
  padding-left: 20px;
`;

export const CautionIcon = styled(Icon)`
  padding: 0;
  width: 18px;
  height: 18px;
`;
