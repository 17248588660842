import React, { useCallback, useMemo, useState } from 'react';

import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Popup } from 'semantic-ui-react';
import styled from 'styled-components';

import { Icon as _Icon } from 'components/atoms/Icon';
import { Link, NavLink } from 'components/atoms/Link';
import Logo from 'components/atoms/Logo';
import { PullDownNarrow } from 'components/atoms/PullDownNarrow';
import { Wrapper } from 'components/atoms/StickyHeader';
import { UserThumbnail } from 'components/molecules/UserThumbnail';
import { hasDiffPath } from 'helpers/router';
import { User } from 'models/Domain/User';
import { AppActions } from 'modules/app/actions';
import { State } from 'modules/reducers';
import { Path, isMultipleAccountPage } from 'routes';
import { COLOR } from 'style/color';
import { SIZE } from 'style/size';
import { SelectOption } from 'types/Common';

import { ChannelServiceButton } from './ChannelServiceButton';
import { NotificationIcon } from './NotificationIcon';

type SidebarContentProps = {
  currentUser: User;
  setIsOpenSidebar: (v: boolean) => void;
  setIsOpenAccountModal: (v: boolean) => void;
  isOpenNotificationModal: boolean;
  setIsOpenNotificationModal: (v: boolean) => void;
};

const ALL_ACCOUNT_VALUE = -1;

export const SidebarContent = React.memo<SidebarContentProps>(
  ({ setIsOpenSidebar, setIsOpenAccountModal, isOpenNotificationModal, setIsOpenNotificationModal, currentUser }) => {
    const dispatch = useDispatch();
    const { accountList, currentAccount } = useSelector(
      (state: State) => ({
        accountList: state.app.accountList,
        currentAccount: state.app.currentAccount,
      }),
      shallowEqual,
    );

    const location = useLocation();

    const hasMultipleAccounts = accountList.items.size > 1;
    const [menuType, setMenuType] = useState<'single' | 'multi'>(() => {
      return isMultipleAccountPage(location.pathname) ? 'multi' : 'single';
    });

    const accountSelectOptions = useMemo((): SelectOption<number>[] => {
      const options = accountList.items
        .sortBy((item) => item.name) // 名前順にソート
        .map((item) => ({ text: item.name, value: item.organizationId }))
        .toArray();
      options.unshift({ text: 'すべてのアカウント', value: ALL_ACCOUNT_VALUE });
      return options;
    }, [accountList]);

    const handleOnChangeAccount = useCallback(
      (value: number) => {
        if (value === ALL_ACCOUNT_VALUE) {
          // 「すべてのアカウント」を選択した場合、メニューを切り替えて終了
          setMenuType('multi');
          return;
        }

        // 単一組織向けのメニューに切り替える
        setMenuType('single');

        // すでにログインしている組織と同じ組織を選んだ場合はメニューの切り替えのみ
        if (value === currentAccount.organizationId) {
          return;
        }

        const account = accountList.find(value);
        // 組織が切り替えられた場合に、「すべてのアカウント」向けのページにいる場合は、ページをリセットさせる
        const resetPage = isMultipleAccountPage(location.pathname);
        if (account) {
          dispatch(AppActions.changeCurrentAccount({ account, resetPage }));
        }
      },
      [accountList, currentAccount.organizationId, dispatch, location.pathname],
    );

    return (
      <SidebarWrapper onClick={() => setIsOpenSidebar(false)}>
        <SidebarHeader isShowMenu={true}>
          <SidebarLogoWrapper to={Path.top}>
            <Logo />
          </SidebarLogoWrapper>

          {currentUser.isExist && (
            <>
              <ThumbnailWrapper onClick={() => setIsOpenAccountModal(true)}>
                <UserThumbnail src={currentUser.profile_image_url} name={currentUser.last_name} />
              </ThumbnailWrapper>
              <Notification onClick={() => setIsOpenNotificationModal(true)} isSelected={isOpenNotificationModal} />
            </>
          )}
        </SidebarHeader>
        {/* アカウントが複数ある場合には、アカウント切り替えを表示する */}
        {hasMultipleAccounts && (
          <AccountSelectWrapper>
            <PullDown
              options={accountSelectOptions}
              value={menuType === 'multi' ? ALL_ACCOUNT_VALUE : currentAccount.organizationId}
              onChange={handleOnChangeAccount}
            />
          </AccountSelectWrapper>
        )}
        {menuType === 'single' ? (
          <MenuContent currentUser={currentUser} />
        ) : (
          <MultipleAccountMenuContent currentUser={currentUser} />
        )}
        <ChannelServiceButton />
      </SidebarWrapper>
    );
  },
);

const MenuContent = ({ currentUser }: { currentUser: User }) => {
  const {
    stores,
    gmbLocationDiffs,
    offerActivities,
    menuSummaryitemsNotSyncedToGbp,
    serviceSummaryitemsNotSyncedToGbp,
  } = useSelector(
    (state: State) => ({
      stores: state.store.stores,
      gmbLocationDiffs: state.app.gmbLocationDiffs,
      offerActivities: state.offer.offerActivities,
      menuSummaryitemsNotSyncedToGbp: state.menu.menuSummary.itemsNotSyncedToGbp,
      serviceSummaryitemsNotSyncedToGbp: state.service.serviceSummary.itemsNotSyncedToGbp,
    }),
    shallowEqual,
  );

  const location = useLocation();
  const currentUrl = location.pathname + location.search;

  const gmbErrorStores = useMemo(() => stores.filterByIsGmbError(), [stores]);

  return (
    <MenuListWrapper>
      <MenuList>
        <MenuItem exact to={Path.top}>
          <MenuText>ダッシュボード</MenuText>
        </MenuItem>
      </MenuList>
      <MenuList>
        <MenuCategory>
          <ManageIcon />
          <MenuText>管理</MenuText>
        </MenuCategory>
        <MenuItem strict to={Path.store.index}>
          <MenuText>店舗</MenuText>
          {!gmbErrorStores.isEmpty && <MenuItemNumber>{gmbErrorStores.list.size}</MenuItemNumber>}
        </MenuItem>
        <MenuItem exact to={Path.gmb.locationUpdates}>
          <MenuText>GBPとの差分</MenuText>
          {!gmbLocationDiffs.list.isEmpty() && <MenuItemNumber>{gmbLocationDiffs.list.size}</MenuItemNumber>}
        </MenuItem>
        <MenuItem
          to={Path.album.index}
          isActive={() => {
            return currentUrl.startsWith(Path.album.index);
          }}
        >
          <MenuText>写真</MenuText>
        </MenuItem>
        <MenuItem strict to={Path.localpost.index}>
          <MenuText>投稿</MenuText>
        </MenuItem>
        <MenuItem strict to={Path.gmb.review}>
          <MenuText>クチコミ</MenuText>
        </MenuItem>
        {currentUser.canUseService && (
          <MenuItem strict to={Path.service.index}>
            <MenuText>
              サービス
              {currentUser.organization?.canUseService() === false && <MenuItemContextHelp />}
            </MenuText>
            {serviceSummaryitemsNotSyncedToGbp.size > 0 && (
              <MenuItemNumber>{serviceSummaryitemsNotSyncedToGbp.size}</MenuItemNumber>
            )}
          </MenuItem>
        )}
        {currentUser.canUseMenu && (
          <MenuItem strict to={Path.menu.index}>
            <MenuText>
              飲食メニュー
              {currentUser.organization?.canUseMenu() === false && <MenuItemContextHelp />}
            </MenuText>
            {menuSummaryitemsNotSyncedToGbp.size > 0 && (
              <MenuItemNumber>{menuSummaryitemsNotSyncedToGbp.size}</MenuItemNumber>
            )}
          </MenuItem>
        )}
        {currentUser.canUseLocalInventoryPage && (
          <MenuItem
            strict
            to={Path.omo.inventoryStoreList}
            isActive={() => {
              return currentUrl.startsWith(Path.omo.inventory);
            }}
          >
            <MenuText>
              店頭在庫
              {currentUser.organization?.canUseLocalInventoryPage() === false && <MenuItemContextHelp />}
            </MenuText>
          </MenuItem>
        )}
        <MenuItem strict to={Path.memo.index}>
          <MenuText>施策メモ</MenuText>
        </MenuItem>
        {currentUser.canRegisterCompetitor && (
          <MenuItem strict to={Path.competitors.index}>
            <MenuText>
              競合店舗
              {currentUser.organization?.canUseCompetitor() === false && <MenuItemContextHelp />}
            </MenuText>
          </MenuItem>
        )}
      </MenuList>
      <MenuList>
        <MenuCategory>
          <OfferIcon />
          <MenuText>依頼 / 報告</MenuText>
        </MenuCategory>
        <MenuItem exact to={Path.offer.activity}>
          <MenuText>直近のやりとり</MenuText>
          {offerActivities.unread_count > 0 && <MenuItemNumber>{offerActivities.unread_count}</MenuItemNumber>}
        </MenuItem>
        <MenuItem
          to={Path.offerGroups.index}
          isActive={() => {
            return (
              hasDiffPath(currentUrl, Path.offer.activity) &&
              (currentUrl.startsWith(Path.offerGroups.index) || currentUrl.startsWith(Path.task.index))
            );
          }}
        >
          <MenuText>依頼 / 報告</MenuText>
        </MenuItem>
      </MenuList>

      <MenuList>
        <MenuCategory>
          <InsightIcon />
          <MenuText>分析</MenuText>
        </MenuCategory>
        {currentUser.canUseGbpPerformance && (
          <MenuItem
            strict
            to={Path.gbp.performance}
            isActive={() =>
              currentUrl.startsWith(Path.gbp.performance) && !currentUrl.startsWith(Path.gbp.performanceMA)
            }
          >
            <MenuText>
              GBPパフォーマンス
              {currentUser.organization?.canUseGbpPerformance() === false && <MenuItemContextHelp />}
            </MenuText>
          </MenuItem>
        )}
        {currentUser.canUseOmoInsight && (
          <MenuItem strict to={Path.omo.insight}>
            <MenuText>
              OMOインサイト
              {currentUser.organization?.canUseOmoInsight() === false && <MenuItemContextHelp />}
            </MenuText>
          </MenuItem>
        )}
        {currentUser.canUseSearchKeywords && (
          <MenuItem strict to={Path.searchKeywords.index}>
            <MenuText>
              検索キーワード
              {currentUser.organization?.canUseSearchKeywords() === false && <MenuItemContextHelp />}
            </MenuText>
          </MenuItem>
        )}
        {currentUser.canUseMapSearchRank && (
          <MenuItem strict to={Path.mapSearchRank.index}>
            <MenuText>
              検索順位監視
              {currentUser.organization?.canUseMapSearchRank() === false && <MenuItemContextHelp />}
            </MenuText>
          </MenuItem>
        )}
      </MenuList>
      <MenuList>
        <MenuCategory>
          <ToolIcon />
          <MenuText>ツール</MenuText>
        </MenuCategory>
        {currentUser.canUseMapCompetitorResearch && (
          <MenuItem strict to={Path.mapCompetitorResearch.index}>
            <MenuText>
              検索順位調査
              {currentUser.organization?.canUseMapCompetitorResearch() === false && <MenuItemContextHelp />}
            </MenuText>
          </MenuItem>
        )}
        <MenuItem strict to={Path.gbpChecklist.index}>
          <MenuText>GBP診断</MenuText>
        </MenuItem>
        {currentUser.canUseSearchVolume && (
          <MenuItem strict to={Path.searchVolume.index}>
            <MenuText>
              検索ボリューム
              <MenuItemContextHelp />
            </MenuText>
          </MenuItem>
        )}
      </MenuList>
      {currentUser.isCompanyUser && (
        <MenuList>
          <MenuCategory>
            <SettingsIcon />
            <MenuText>設定</MenuText>
          </MenuCategory>
          <MenuItem strict to={Path.user.index}>
            <MenuText>スタッフ</MenuText>
          </MenuItem>
          {currentUser.isCompanyUser && (
            <MenuItem strict to={Path.storeList.index}>
              <MenuText>グループ</MenuText>
            </MenuItem>
          )}
          <MenuItem
            to={Path.storeAccounts.index}
            isActive={() => {
              return currentUrl === Path.storeAccounts.index || currentUrl.startsWith(Path.gmb.importStoresIndex);
            }}
          >
            <MenuText>店舗連携アカウント</MenuText>
          </MenuItem>
          {currentUser.canUseInstagram && (
            <MenuItem
              strict
              to={Path.instagram.index}
              isActive={() => {
                return currentUrl.startsWith(Path.instagram.index);
              }}
            >
              <MenuText>
                Instagram
                {currentUser.organization?.canUseInstagram() === false && <MenuItemContextHelp />}
              </MenuText>
            </MenuItem>
          )}
          {currentUser.isAdminUser && (
            <MenuItem to={Path.organization.index}>
              <MenuText>
                組織
                <MenuItemContextHelp />
              </MenuText>
            </MenuItem>
          )}
        </MenuList>
      )}
    </MenuListWrapper>
  );
};

const MultipleAccountMenuContent = ({ currentUser }: { currentUser: User }) => {
  return (
    <MenuListWrapper>
      <MenuList>
        <MenuCategory>
          <AccountsIcon />
          <MenuText>アカウント横断分析</MenuText>
        </MenuCategory>
        {currentUser.canUseGbpPerformance && (
          <MenuItem strict to={Path.gbp.performanceMA}>
            <MenuText>
              GBPパフォーマンス
              {currentUser.organization?.canUseGbpPerformance() === false && <MenuItemContextHelp />}
            </MenuText>
          </MenuItem>
        )}
      </MenuList>
    </MenuListWrapper>
  );
};

const MenuItemContextHelp = () => (
  <PopupWrapper>
    <Popup content='管理者権限のユーザーのみ表示されているメニューです' trigger={<SubMenuText>*</SubMenuText>} />
  </PopupWrapper>
);

const SidebarWrapper = styled.div`
  position: fixed;
  background-color: ${COLOR.WHITE};
  top: 0;
  bottom: 0;
  overflow-y: scroll;
  overscroll-behavior-y: none;
  padding-bottom: 60px;
  width: ${SIZE.SIDEBAR};
`;

const SidebarHeader = styled(Wrapper)`
  &&& {
    background-color: ${COLOR.WHITE};
    border: solid ${COLOR.LIGHT_GRAY};
    border-width: 0 0 1px 0;
    padding: 0;
    @media (max-width: ${SIZE.MOBILE}) {
      display: none;
    }
  }
`;

const SidebarLogoWrapper = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  height: 60px;
`;

const MenuListWrapper = styled.div``;

const MenuList = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
`;

const MenuItem = styled(NavLink).attrs(() => ({
  activeStyle: { color: COLOR.WHITE, backgroundColor: COLOR.GREEN },
}))`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px 0 49px;
  line-height: 46px;

  &:hover {
    color: ${COLOR.BLACK};
    background-color: ${COLOR.LIGHT_GREEN};
  }
`;

const MenuCategory = styled.div`
  font-size: 16px;
  color: ${COLOR.CHARCOAL_GRAY};
  display: flex;
  align-items: center;
  line-height: 46px;
  margin: 14px 15px 7px 5px;
  i {
    color: ${COLOR.GREEN};
  }
`;

const MenuItemNumber = styled.div`
  display: flex;
  align-items: center;
  color: ${COLOR.WHITE};
  background-color: ${COLOR.GOLDEN_YELLOW};
  border-radius: 15px;
  height: 24px;
  width: 24px;
  justify-content: center;
  font-size: 12px;
`;

const MenuText = styled.div`
  font-weight: bold;
`;

const SubMenuText = styled.span`
  font-size: 16px;
  color: ${COLOR.RED};
  margin-left: 6px;
`;

const ThumbnailWrapper = styled.div`
  position: absolute;
  right: 14px;
  top: 10px;
  display: flex;
  cursor: pointer;
  @media (max-width: ${SIZE.MOBILE}) {
    display: none;
  }
`;

const Notification = styled(NotificationIcon)`
  position: absolute;
  right: 59px;
  top: 9px;
  border-radius: 5px;
  @media (max-width: ${SIZE.MOBILE}) {
    display: none;
  }
`;

const PopupWrapper = styled.span`
  width: 16px;
  height: 16px;
  margin-left: 8px;
`;

const MenuIcon = styled(_Icon)``;
const ManageIcon = styled(MenuIcon).attrs(() => ({ type: 'photo_library' }))``;
const OfferIcon = styled(MenuIcon).attrs(() => ({ type: 'check_circle_outline' }))``;
const InsightIcon = styled(MenuIcon).attrs(() => ({ type: 'assessment' }))``;
const AccountsIcon = styled(MenuIcon).attrs(() => ({ type: 'accounts' }))``;
const ToolIcon = styled(MenuIcon).attrs(() => ({ type: 'tool' }))``;
const SettingsIcon = styled(MenuIcon).attrs(() => ({ type: 'settings' }))``;

const AccountSelectWrapper = styled.div`
  padding: 16px;
  background: white;
  position: sticky;
  top: 72px;
  border-bottom: 1px solid ${COLOR.LIGHT_GRAY};

  @media (max-width: ${SIZE.MOBILE}) {
    top: 0;
  }
`;

const PullDown = styled(PullDownNarrow).attrs(() => ({ search: false }))`
  &&& {
    width: 100%;
    & .ui.search.selection.dropdown {
      padding: 4px 6px !important;
      min-height: 33px;
      display: flex;
      align-items: center;
    }
    & .ui.search.selection.dropdown > .search {
      min-height: 33px;
      padding: 4px 6px !important;
    }

    @media (max-width: ${SIZE.MOBILE_WITH_SIDEBAR}) {
      width: 100%;
    }
  }
`;
