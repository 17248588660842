import { Record } from 'immutable';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { StoresImportActions } from './actions';
import { ImportResult, ImportStatus, ValidateError, ValidateWarning } from './type';

type StoresImportStateType = {
  // バリデーション関連の状態
  isValidateError: boolean | undefined;
  isValidateWarning: boolean | undefined;
  validateWarnings?: ValidateWarning;
  validateErrors?: ValidateError;
  totalSucceed: number;
  totalWarning: number;
  totalFailed: number;

  // インポート関連の状態
  executionArn: string;
  importStatus?: ImportStatus;
  importResults: ImportResult[];

  isProcessingValidate: boolean;
  isProcessingImport: boolean;
};

const initialState: StoresImportStateType = {
  isValidateError: undefined,
  isValidateWarning: undefined,
  validateWarnings: undefined,
  validateErrors: undefined,
  totalSucceed: 0,
  totalWarning: 0,
  totalFailed: 0,

  executionArn: '',
  importStatus: undefined,
  importResults: [],

  isProcessingValidate: false,
  isProcessingImport: false,
};

export class StoresImportState extends Record<StoresImportStateType>(initialState) {
  get isImportError() {
    return this.importStatus === 'FAILED';
  }

  get hasImportFailedStore() {
    return this.importResults.find((r) => r.status === 'failed') !== undefined;
  }

  get importFailedStoreIds() {
    return this.importResults.filter((r) => r.status === 'failed').map((r) => r.store_id);
  }

  get isAcceptedImport() {
    return !!this.executionArn;
  }
}

export const storesImportReducer = reducerWithInitialState(new StoresImportState())
  .case(StoresImportActions.setValidateResult, (state, payload) => {
    const {
      isValidateError,
      isValidateWarning,
      validateWarnings,
      validateErrors,
      totalSucceed,
      totalWarning,
      totalFailed,
    } = payload;
    return state.merge({
      isValidateError,
      isValidateWarning,
      validateWarnings,
      validateErrors,
      totalSucceed,
      totalWarning,
      totalFailed,
    });
  })
  .case(StoresImportActions.setExectionArn, (state, payload) => {
    return state.set('executionArn', payload);
  })
  .case(StoresImportActions.setImportStatus, (state, payload) => {
    const { importStatus, importResults } = payload;
    return state.merge({ importStatus, importResults });
  })
  .case(StoresImportActions.clearValidateResult, (state, _) => {
    return state.merge({
      isValidateError: undefined,
      validateErrors: undefined,
      importStatus: undefined,
      importResults: [],
      totalFailed: 0,
      totalSucceed: 0,
    });
  })
  .case(StoresImportActions.clearStatus, (state, _) => {
    return new StoresImportState();
  })
  .case(StoresImportActions.setIsProcessingValidate, (state, payload) => {
    return state.set('isProcessingValidate', payload);
  })
  .case(StoresImportActions.setIsProcessingImport, (state, payload) => {
    return state.set('isProcessingImport', payload);
  });
