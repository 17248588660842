import React from 'react';

import styled from 'styled-components';

import { GoogleMap } from 'components/atoms/GoogleMap';
import { ContextHelp } from 'components/molecules/ContextHelp';
import { addParamsToUrl } from 'helpers/utils';
import { GmbCategories } from 'models/Domain/GmbLocation/GmbCategories';
import { GmbLocation } from 'models/Domain/GmbLocation/GmbLocation';
import { LocationDiffKey } from 'models/Domain/GmbLocationDiffs';

import { CautionIconWrapper, ValueLabelsWrapper, ValueWrapper } from './TableRow';

// 緯度経度を表示する際のGoogleマップのズームレベル
// Googleビジネスプロフィールの住所編集画面より2回寄った状態を指定
const LATLNG_MAP_ZOOM_LEVEL = 17;

export type Props = {
  gmbLocation: GmbLocation;
  locationKey: Exclude<LocationDiffKey, 'attributes' | 'moreHours' | 'serviceItems'>;
  gmbCategoryList: GmbCategories;
  isConnectedYahooPlace?: boolean;
  isGoogleUpdated?: boolean;
};
export const GmbLocationValue: React.FC<Props> = ({
  gmbLocation,
  locationKey,
  gmbCategoryList,
  isConnectedYahooPlace = false,
  isGoogleUpdated = false,
}) => {
  switch (locationKey) {
    case 'locationName': {
      const hasLocationNameError = gmbLocation.locationName.length > 60;
      const showLocationNameError = isConnectedYahooPlace && isGoogleUpdated && hasLocationNameError;
      return (
        <ValueWrapper>
          {gmbLocation.locationName}
          {showLocationNameError && (
            <CautionIconWrapper>
              <StyledContextHelp
                content={'この店舗名は60文字を超えているため、Yahoo!プレイスへの反映ができません。'}
                iconType={'status_error'}
              />
            </CautionIconWrapper>
          )}
        </ValueWrapper>
      );
    }
    case 'storeCode':
      return <>{gmbLocation.storeCode}</>;
    case 'websiteUrl': {
      const { websiteUrl, connectedServices } = gmbLocation;
      // サービス別の流入パラメータが設定されている場合は、URLにパラメータを付与する
      const websiteUrlParam = connectedServices.gbp.websiteUrlParam;
      const url = websiteUrlParam ? addParamsToUrl(websiteUrl, websiteUrlParam) : websiteUrl;
      return <>{url}</>;
    }
    case 'phoneNumbers': {
      const hasPhoneNumbersWarning = gmbLocation.phones.length > 1;
      const showPhoneNumbersWarning = isConnectedYahooPlace && isGoogleUpdated && hasPhoneNumbersWarning;
      return (
        <ValueWrapper>
          <ValueLabelsWrapper>
            <SubLabel>電話番号</SubLabel>
            {gmbLocation.primaryPhone}
            <br />
            <br />
            <SubLabel>追加の電話番号</SubLabel>
            {gmbLocation.additionalPhones.map((additionalPhone, index) => (
              <div key={index}>{additionalPhone}</div>
            ))}
          </ValueLabelsWrapper>
          {showPhoneNumbersWarning && (
            <CautionIconWrapper>
              <StyledContextHelp
                content={
                  '2件以上の電話番号が登録されています。「Googleを取り込む」を選択した場合、Yahoo!プレイスには先頭の1件のみを反映します。'
                }
                iconType={'status_warning'}
              />
            </CautionIconWrapper>
          )}
        </ValueWrapper>
      );
    }
    case 'address':
      return (
        <div>
          <div>{gmbLocation.address?.postalCode}</div>
          <div>
            {gmbLocation.address.administrativeArea}
            {gmbLocation.address.address1}
          </div>
          <div>{gmbLocation.address?.address2}</div>
        </div>
      );
    case 'latlng':
      return (
        <div>
          <StyledGoogleMap
            latitude={gmbLocation.latlng.latitude}
            longitude={gmbLocation.latlng.longitude}
            zoom={LATLNG_MAP_ZOOM_LEVEL}
          />
        </div>
      );
    case 'regularHours': {
      const hasRegularHoursWarning = gmbLocation.regularHours.maxPeriodsCount > 3;
      const showRegularHoursWarning = isConnectedYahooPlace && isGoogleUpdated && hasRegularHoursWarning;
      return (
        <ValueWrapper>
          <ValueLabelsWrapper>
            {gmbLocation.regularHoursForDisplay.map((businessHour, index) => (
              <div key={index}>{businessHour}</div>
            ))}
          </ValueLabelsWrapper>
          {showRegularHoursWarning && (
            <CautionIconWrapper>
              <StyledContextHelp
                content={
                  '1つの曜日に4件以上の営業時間が登録されています。「Googleを取り込む」を選択した場合、Yahoo!プレイスには各曜日先頭の3件のみを反映します。'
                }
                iconType={'status_warning'}
              />
            </CautionIconWrapper>
          )}
        </ValueWrapper>
      );
    }
    case 'specialHours': {
      const hasSpecialHoursWarning =
        gmbLocation.specialHours.getExceptPastDate().openDays.size > 10 ||
        gmbLocation.specialHours.getExceptPastDate().closeDays.size > 10;
      const showSpecialHoursWarning = isConnectedYahooPlace && isGoogleUpdated && hasSpecialHoursWarning;
      return (
        <ValueWrapper>
          <ValueLabelsWrapper>
            {gmbLocation.specialHoursForDisplay.map((specialHour, index) => (
              <div key={index}>{specialHour}</div>
            ))}
          </ValueLabelsWrapper>
          {showSpecialHoursWarning && (
            <CautionIconWrapper>
              <StyledContextHelp
                content={
                  '10件を超える営業日または休業日が登録されています。「Googleを取り込む」を選択した場合、Yahoo!プレイスには営業日、休業日それぞれ日付が本日に近い10件のみを反映します。'
                }
                iconType={'status_warning'}
              />
            </CautionIconWrapper>
          )}
        </ValueWrapper>
      );
    }
    case 'categories':
      return (
        <>
          <SubLabel>メインカテゴリー</SubLabel>
          {gmbCategoryList.generateDisplayName(gmbLocation.primaryCategory.categoryId)}
          <br />
          <br />
          <SubLabel>サブカテゴリー</SubLabel>
          {gmbLocation.additionalCategories.list.map((additionalCategory, idx) => {
            return <div key={idx}>{gmbCategoryList.generateDisplayName(additionalCategory.categoryId)}</div>;
          })}
        </>
      );
    case 'openInfo':
      return <>{gmbLocation.openInfo.statusName}</>;
    case 'openingDate':
      return <>{gmbLocation.openingDate?.displayDate ?? '未設定'}</>;
    case 'profile': {
      const hasProfileWarning = gmbLocation.profile.hasDescriptionWarningForYahooPlace();
      const showProfileWarning = isConnectedYahooPlace && isGoogleUpdated && hasProfileWarning;
      return (
        <ValueWrapper>
          <Profile>{gmbLocation.profile.description}</Profile>
          {showProfileWarning && (
            <CautionIconWrapper>
              <StyledContextHelp
                content={
                  'Yahoo!プレイスで利用できない記号が含まれています。「Googleを取り込む」を選択した場合、利用できる記号に変換して取り込み・反映します。'
                }
                iconType={'status_warning'}
              />
            </CautionIconWrapper>
          )}
        </ValueWrapper>
      );
    }
  }
};

const Profile = styled.div`
  white-space: normal;
`;

const SubLabel = styled.div`
  font-weight: bold;
`;

const StyledGoogleMap = styled(GoogleMap)`
  width: 300px;

  /* 地図のズーム・ズームアウトボタンを表示して、必要に応じて細かく確認できるようにしたい。
   * ズームボタンが表示されるのが縦200px以上のため、200pxとしている */
  height: 200px;
`;

const StyledContextHelp = styled(ContextHelp)`
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0;
`;
