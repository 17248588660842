import { List as ImmutableList, Record as ImmutableRecord } from 'immutable';

import { PatchOrganizationFeaturesParams, PatchOrganizationParams } from 'ApiClient/OrganizationApi';
import ErrorType from 'helpers/errorType';
import { JSObject } from 'types/Common';

export type OrganizationPlan = 'basic' | 'analysis' | 'competitor' | 'omo';

const ORGANIZATION_FEATURES = [
  'performance',
  'search_keywords',
  'map_search_rank',
  'map_competitor_research',
  'competitor',
  'local_inventory',
  'local_inventory:on_display_to_order',
  'local_inventory:page',
  'omo',
  'instagram',
  'localpost_ai',
  'localpost_translation',
  'review_ai',
  'menu',
  'service',
  'apple_business_connect',
  'yahoo_place',
] as const;

export type OrganizationFeature = (typeof ORGANIZATION_FEATURES)[number];

const BASIC_PLAN_FEATURES: OrganizationFeature[] = [];
const ANALYSIS_PLAN_FEATURES: OrganizationFeature[] = ['performance', 'search_keywords', 'map_search_rank'];
const COMPETITOR_PLAN_FEATURES: OrganizationFeature[] = [
  ...ANALYSIS_PLAN_FEATURES,
  'map_competitor_research',
  'competitor',
];
const OMO_PLAN_FEATURES: OrganizationFeature[] = [
  ...COMPETITOR_PLAN_FEATURES,
  'local_inventory',
  'local_inventory:page',
  'omo',
];

const PLAN_FEATURES_MAP = {
  basic: BASIC_PLAN_FEATURES,
  analysis: ANALYSIS_PLAN_FEATURES,
  competitor: COMPETITOR_PLAN_FEATURES,
  omo: OMO_PLAN_FEATURES,
};

// 翻訳対応言語
export const TRANSLATION_LANGUAGES = ['en', 'zh-CN', 'ko'] as const;
export type TranslationLanguage = (typeof TRANSLATION_LANGUAGES)[number];

// 翻訳対応言語の表示名
export const TRANSLATION_LANGUAGE_LABELS = {
  en: '英語',
  'zh-CN': '中国語（簡体字）',
  ko: '韓国語',
} satisfies Record<TranslationLanguage, string>;

// GBP診断における運用実績の集計期間の日数のデフォルト値
export const DEFAULT_GBP_CHECK_DAYS_OF_DURATION = 30;
// クチコミに対応したと判断する期限の日数のデフォルト値
export const DEFAULT_GBP_CHECK_DAYS_OF_REVIEW_REPLY_DEADLINE = 7;

export class OrganizationFeatures extends ImmutableRecord<{
  plan: OrganizationPlan;
  includedFeatures: ImmutableList<OrganizationFeature>;
  excludedFeatures: ImmutableList<OrganizationFeature>;
}>({
  plan: 'basic',
  includedFeatures: ImmutableList(),
  excludedFeatures: ImmutableList(),
}) {
  static fromJSON(data: JSObject) {
    return new OrganizationFeatures({
      plan: data.plan,
      includedFeatures: ImmutableList(
        data.included_features?.filter((feature: any) => ORGANIZATION_FEATURES.includes(feature)) || [],
      ),
      excludedFeatures: ImmutableList(data.excluded_features || []),
    });
  }

  /**
   * 契約プランに紐付いて利用可能な機能
   */
  get planFeatures() {
    return ImmutableList(PLAN_FEATURES_MAP[this.plan]);
  }

  /**
   * 利用可能な機能
   */
  get enabledFeatures() {
    return ImmutableList(
      [...this.planFeatures.toArray(), ...this.includedFeatures.toArray()].filter(
        (feature) => !this.excludedFeatures.includes(feature),
      ),
    );
  }

  isPlanFeature(feature: OrganizationFeature) {
    return this.planFeatures.includes(feature);
  }

  isEnabledFeature(feature: OrganizationFeature) {
    return this.enabledFeatures.includes(feature);
  }

  changePlan(plan: OrganizationPlan) {
    return this.set('plan', plan);
  }

  changeIncludedFeatures(features: ImmutableList<OrganizationFeature>) {
    return this.set('includedFeatures', features);
  }

  changeExcludedFeatures(features: ImmutableList<OrganizationFeature>) {
    return this.set('excludedFeatures', features);
  }

  clearIncludedFeatures() {
    return this.changeIncludedFeatures(ImmutableList());
  }

  clearExcludedFeatures() {
    return this.changeExcludedFeatures(ImmutableList());
  }

  clearOptionalFeatures() {
    return this.clearIncludedFeatures().clearExcludedFeatures();
  }

  /**
   * 更新API用のパラメータを生成する
   */
  toUpdateParams(): PatchOrganizationFeaturesParams {
    return {
      plan: this.plan,
      included_features: this.includedFeatures.toArray(),
      excluded_features: this.excludedFeatures.toArray(),
    };
  }
}

type OrganizationErrorType = 'name' | 'gbpCheck';

const MAP_SEARCH_RANK_CONFIG_LIMIT_PER_CONTRACTED_STORE = 5;

export type OrganizationParams = {
  map_search_rank_config_limit?: number | null;
  can_use_gbp_media_product_category?: boolean;
  gbp_check_days_of_duration?: number | null;
  gbp_check_days_of_review_reply_deadline?: number | null;
  localpost_translation_languages?: TranslationLanguage[] | null;
};
interface OrganizationRecord {
  id: number | null;
  root_pb_id: string | null;
  name: string;
  features: OrganizationFeatures;
  params: OrganizationParams;
  contractedStores: number;
}

export class Organization extends ImmutableRecord<OrganizationRecord>({
  id: null,
  root_pb_id: null,
  name: '',
  features: new OrganizationFeatures(),
  params: {},
  contractedStores: 0,
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    if (params.contracted_stores) {
      params.contractedStores = params.contracted_stores;
    }
    super(params);
  }

  static fromJSON(data: JSObject) {
    return new Organization({
      ...data,
      features: OrganizationFeatures.fromJSON(data.features),
      params: data.params || {},
    });
  }

  /**
   * 順位監視設定数のデフォルト値
   */
  get defaultConfigLimit() {
    return this.contractedStores * 5;
  }

  /**
   * 組織に設定可能な順位監視設定数
   */
  get mapSearchRankConfigLimit() {
    if (this.params.map_search_rank_config_limit != null) {
      return this.params.map_search_rank_config_limit;
    }
    return this.contractedStores * MAP_SEARCH_RANK_CONFIG_LIMIT_PER_CONTRACTED_STORE;
  }

  /**
   * GBP診断における運用実績の集計期間の日数
   */
  get gbpCheckDaysOfDuration() {
    return this.params.gbp_check_days_of_duration || DEFAULT_GBP_CHECK_DAYS_OF_DURATION;
  }

  /**
   * クチコミに対応したと判断する期限の日数
   */
  get gbpCheckDaysOfReviewReplyDeadline() {
    return this.params.gbp_check_days_of_review_reply_deadline || DEFAULT_GBP_CHECK_DAYS_OF_REVIEW_REPLY_DEADLINE;
  }

  /** Apple Business Connectを利用可能か */
  canUseAppleBusinessConnect() {
    return this.features.enabledFeatures.includes('apple_business_connect');
  }

  /** Yahoo! プレイス連携を利用可能か */
  canUseYahooPlace() {
    return this.features.enabledFeatures.includes('yahoo_place');
  }

  /** 1つ以上の店舗連携サービスを利用可能か */
  canUseStoreConnectService() {
    return this.canUseAppleBusinessConnect() || this.canUseYahooPlace();
  }

  /**
   * OMOインサイトを利用可能か
   */
  canUseOmoInsight() {
    return this.features.enabledFeatures.includes('omo');
  }

  /**
   * 店頭在庫機能を利用可能か
   */
  canUseLocalInventory() {
    return this.features.enabledFeatures.includes('local_inventory');
  }

  /**
   * 在庫状況の「見本展示のみ」を利用可能か
   */
  canUseOnDisplayToOrder() {
    return (
      this.features.enabledFeatures.includes('local_inventory') &&
      this.features.enabledFeatures.includes('local_inventory:on_display_to_order')
    );
  }

  /**
   * 店頭在庫画面を利用可能か
   */
  canUseLocalInventoryPage() {
    return (
      this.features.enabledFeatures.includes('local_inventory') &&
      this.features.enabledFeatures.includes('local_inventory:page')
    );
  }

  /**
   * 検索キーワード分析画面を利用可能か
   */
  canUseSearchKeywords() {
    return this.features.enabledFeatures.includes('search_keywords');
  }

  /**
   * 検索順位監視画面を利用可能か
   */
  canUseMapSearchRank() {
    return this.features.enabledFeatures.includes('map_search_rank');
  }

  /**
   * 検索順位調査画面を利用可能か
   */
  canUseMapCompetitorResearch() {
    return this.features.enabledFeatures.includes('map_competitor_research');
  }

  /**
   * GBPパフォーマンス画面を利用可能か
   */
  canUseGbpPerformance() {
    return this.features.enabledFeatures.includes('performance');
  }

  /**
   * 競合店舗画面を利用可能か
   */
  canUseCompetitor() {
    return this.features.enabledFeatures.includes('competitor');
  }

  /**
   * メニュー画面を利用可能か
   */
  canUseMenu() {
    return this.features.enabledFeatures.includes('menu');
  }

  /**
   * サービス画面を利用可能か
   */
  canUseService() {
    return this.features.enabledFeatures.includes('service');
  }

  /**
   * Instagram連携機能を利用可能か
   */
  canUseInstagram() {
    return this.features.enabledFeatures.includes('instagram');
  }

  /**
   * 投稿文の自動生成を利用可能か
   */
  canUseLocalPostAi() {
    return this.features.enabledFeatures.includes('localpost_ai');
  }

  /**
   * 投稿の翻訳機能が有効か
   */
  canUseLocalPostTranslation() {
    // 機能が有効かつ言語が設定されている場合のみ利用可能
    return this.features.enabledFeatures.includes('localpost_translation');
  }

  canUseLocalPostTranslationLanguage(language: TranslationLanguage) {
    return this.params.localpost_translation_languages?.includes(language) ?? false;
  }

  // 投稿の翻訳対象の言語が設定されているか
  hasLocalPostTranslationLanguages() {
    // 機能が有効かつ言語が設定されている場合のみ利用可能
    const translationLanguages = this.params.localpost_translation_languages ?? [];
    return translationLanguages.length > 0;
  }

  /**
   * クチコミ返信の自動生成を利用可能か
   */
  canUseReviewAi() {
    return this.features.enabledFeatures.includes('review_ai');
  }

  changeName(name: string) {
    return this.set('name', name);
  }

  changeFeatures(features: OrganizationFeatures) {
    return this.set('features', features);
  }

  changeCanUseGbpMediaProductCategory(value: boolean) {
    return this.setIn(['params', 'can_use_gbp_media_product_category'], value ? true : undefined);
  }

  changeContractedStores(value: number) {
    return this.set('contractedStores', value);
  }

  changeMapSearchRankConfigLimit(value: number | null) {
    return this.setIn(['params', 'map_search_rank_config_limit'], value);
  }

  changeGbpCheckDaysOfDuration(value: number | null) {
    return this.setIn(['params', 'gbp_check_days_of_duration'], value);
  }

  changeGbpCheckDaysOfReviewReplyDeadline(value: number | null) {
    return this.setIn(['params', 'gbp_check_days_of_review_reply_deadline'], value);
  }

  changeTranslationLanguages(value: TranslationLanguage[]) {
    return this.setIn(['params', 'localpost_translation_languages'], value.length > 0 ? value : undefined);
  }

  validate() {
    const errors: { [key in OrganizationErrorType]: string | undefined } = {
      name: undefined,
      gbpCheck: undefined,
    };

    if (!this.name || !this.name.trim()) {
      errors.name = ErrorType.REQUIRED_ERROR;
    }

    if (this.gbpCheckDaysOfDuration <= this.gbpCheckDaysOfReviewReplyDeadline) {
      errors.gbpCheck = '「クチコミの対応期限の日数」は「運用情報の評価を行う期間の日数」より小さい必要があります';
    }

    return errors;
  }

  isValid(): boolean {
    const errors = this.validate();
    return Object.values(errors).filter((message) => message).length === 0;
  }

  /**
   * 更新API用のパラメータを生成する
   */
  toUpdateParams(): PatchOrganizationParams {
    return {
      name: this.name,
      features: this.features.toUpdateParams(),
      params: {
        map_search_rank_config_limit: this.params.map_search_rank_config_limit ?? null,
        can_use_gbp_media_product_category: this.params.can_use_gbp_media_product_category ?? false,
        gbp_check_days_of_duration: this.params.gbp_check_days_of_duration ?? null,
        gbp_check_days_of_review_reply_deadline: this.params.gbp_check_days_of_review_reply_deadline ?? null,
        localpost_translation_languages: this.params.localpost_translation_languages ?? null,
      },
      contracted_stores: this.contractedStores,
    };
  }
}
