import React from 'react';

import styled, { css } from 'styled-components';

const Icons = {
  angle_up: '/images/icon_angle_up.svg',
  arrow: '/images/icon_arrow.svg',
  arrow_forward: '/images/icon_arrow_forward.svg',
  bell: '/images/icon_bell.svg',
  bell_frame: '/images/icon_bell_frame.svg',
  comment: '/images/icon_comment.svg',
  dot_menu: '/images/icon_dot_menu.svg',
  dot_menu_white: '/images/icon_dot_menu_white.svg',
  edit_for_template: '/images/icon_edit_for_template.svg', // テンプレート画面の編集アイコン
  smile: '/images/icon_smile.svg',
  download: '/images/icon_download.svg',
  upload: '/images/icon_upload.svg',
  back: '/images/icon_back.svg',
  ellipsis: '/images/icon_ellipsis.svg',
  calendar: '/images/icon_calender.svg',
  image: '/images/icon_image.svg',
  send: '/images/icon_send.svg',
  send_white: '/images/icon_send_white.svg',
  store: '/images/icon_store.svg',
  store_white: '/images/icon_store_white.svg',
  return: '/images/icon_return.svg',
  pencil: '/images/icon_pencil.svg', // 店舗詳細画面の編集アイコン
  pencil_for_store_business_info: '/images/icon_pencil_for_store_business_info.svg', // 店舗情報変更画面の編集アイコン
  pin: '/images/icon_pin.svg',
  pin_gray: '/images/icon_pin_gray.svg',
  template: '/images/icon_template.svg',
  trash: '/images/icon_trash.svg',
  trash_for_template: '/images/icon_trash_for_template.svg', // テンプレート画面の削除アイコン
  copy: '/images/icon_copy.svg',
  copy_for_store_business_info: '/images/icon_copy_for_store_business_info.svg', // 店舗情報変更画面のコピーアイコン
  paste_for_store_business_info: '/images/icon_paste_for_store_business_info.svg',
  question: '/images/icon_question.svg',
  cancel: '/images/icon_cancel.svg',
  cancel_red: '/images/icon_cancel_red.svg',
  keyboard_arrow_down: '/images/icon_keyboard_arrow_down.svg',
  compare_arrows: '/images/icon_compare_arrows.svg',
  visibility: '/images/icon_visibility.svg',
  touch_up: '/images/icon_touch_up.svg',
  share: '/images/icon_share.svg',
  lightbulb: '/images/icon_lightbulb.svg',
  shop: '/images/icon_shop.svg',
  shop_green: '/images/icon_shop_green.svg',
  shop_yellow: '/images/icon_shop_yellow.svg',
  percent: '/images/icon_percent.svg',
  plus: '/images/icon_plus.svg',
  check: '/images/icon_check.svg',
  dash: '/images/icon_dash.svg',
  photo_library: '/images/photo_library.svg',
  check_circle_outline: '/images/check_circle_outline.svg',
  assessment: '/images/assessment.svg',
  settings: '/images/settings.svg',
  person_outline: '/images/person_outline.svg',
  person_outline_white: '/images/person_outline_white.svg',
  status_active: '/images/icon_status_active.svg',
  status_warning: '/images/icon_status_warning.svg',
  status_error: '/images/icon_status_error.svg',
  tool: '/images/icon_tool.svg',
  instagram: '/images/icon_instagram.svg',
  facebook: '/images/icon_facebook_logo.svg',
  profile: '/images/icon_profile.svg',
  accounts: '/images/icon_accounts.svg',
  post_add: '/images/icon_post_add.svg',
  robot: '/images/icon_robot.svg',
  add: '/images/icon_add.svg',
  active: '/images/icon_active.svg',
  disabled: '/images/icon_disabled.svg',
  globe: '/images/icon_globe.svg',
  apple: '/images/icon_apple.svg',
} as const;

export type IconType = keyof typeof Icons;

export interface Props {
  className?: string;
  type: IconType;
  isSvg?: boolean;
}

export const Icon: React.FC<Props & (React.HTMLAttributes<HTMLImageElement> | React.SVGAttributes<SVGSVGElement>)> = ({
  className,
  type,
  isSvg,
  ...props
}) => {
  const icon = Icons[type];

  if (!isSvg) {
    return <Img className={className} src={icon} {...(props as React.HTMLAttributes<HTMLImageElement>)} />;
  }
  return (
    <Svg className={className} {...(props as React.SVGAttributes<SVGSVGElement>)}>
      <use xlinkHref={`${icon}#icon`} />
    </Svg>
  );
};

const Common = css`
  width: 44px;
  height: 44px;
  object-fit: contain;
  padding: 10px;
`;

const Img = styled.img`
  ${Common}
`;

const Svg = styled.svg`
  ${Common}
`;
