import React from 'react';

import styled from 'styled-components';

import { StoreCard } from 'components/pageComponents/StoreIndex/StoreCard';
import { Store, Stores } from 'models/Domain/Store';

export type Props = {
  className?: string;
  canUseAbc: boolean; // 組織でApple Business Connect連携が利用可能かどうか
  stores: Stores;
  selectedStore?: Store;
  storeDetailLink: string;
  onClick: (id: number) => void;
};

/**
 * 店舗一覧のSP版と店舗詳細に表示する店舗情報のリスト
 */
export const StoreCardList = React.memo<Props>(
  ({ className, canUseAbc, stores, selectedStore, storeDetailLink, onClick }) => {
    return (
      <Wrapper className={className}>
        {stores.list.map((store, _) => (
          <StoreCard
            key={store.id}
            canUseAbc={canUseAbc}
            store={store}
            selected={selectedStore?.id === store.id}
            storeDetailLink={storeDetailLink}
            onClick={() => onClick(store.id)}
          />
        ))}
        {stores.list.isEmpty() && <Message>該当する店舗がありません</Message>}
      </Wrapper>
    );
  },
);

const Wrapper = styled.div``;

const Message = styled.div`
  width: 100%;
  margin-top: 88px;
  font-size: 16px;
  text-align: center;
`;
