import React from 'react';

import styled from 'styled-components';

import { ServiceIcon } from 'components/atoms/ServiceIcon';
import { STConnectedServices } from 'models/Domain/GmbLocation/STConnectedServices';

type Props = {
  websiteUrl: string;
  connectedServices: STConnectedServices;
  isConnectedGBP: boolean;
  isConnectedYahooPlace: boolean;
  isConnectedABC: boolean;
};

type ParamProps = {
  serviceName: string;
  serviceIcon: React.ReactNode;
  websiteUrlParam: string;
};

const WebSiteUrlParamItem = React.memo<ParamProps>(({ serviceName, serviceIcon, websiteUrlParam }) => {
  return (
    <WebSiteUrlParamWrapper>
      <LabelWrapper>
        <ServiceIconWrapper>{serviceIcon}</ServiceIconWrapper>
        <WebSiteUrlParamLabel>流入パラメータ（{serviceName}）</WebSiteUrlParamLabel>
      </LabelWrapper>
      <WebsiteUrlParam>{websiteUrlParam}</WebsiteUrlParam>
    </WebSiteUrlParamWrapper>
  );
});

export const WebsiteUrl = React.memo<Props>(
  ({ websiteUrl, connectedServices, isConnectedGBP, isConnectedABC, isConnectedYahooPlace }) => {
    return (
      <>
        <Url>{websiteUrl}</Url>
        {isConnectedGBP && connectedServices.gbp.websiteUrlParam && (
          <WebSiteUrlParamItem
            serviceName={'Googleビジネスプロフィール'}
            serviceIcon={<ServiceIcon.Google />}
            websiteUrlParam={connectedServices.gbp.websiteUrlParam}
          />
        )}
        {isConnectedYahooPlace && connectedServices.yahooPlace.websiteUrlParam && (
          <WebSiteUrlParamItem
            serviceName={'Yahoo! プレイス'}
            serviceIcon={<ServiceIcon.Yahoo />}
            websiteUrlParam={connectedServices.yahooPlace.websiteUrlParam}
          />
        )}
        {isConnectedABC && connectedServices.abc.websiteUrlParam && (
          <WebSiteUrlParamItem
            serviceName={'Apple Business Connect'}
            serviceIcon={<ServiceIcon.Apple />}
            websiteUrlParam={connectedServices.abc.websiteUrlParam}
          />
        )}
      </>
    );
  },
);

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-right: 20px;
`;

const ServiceIconWrapper = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
`;

const Url = styled.div`
  word-break: break-word;
  :not(:last-child) {
    margin-bottom: 16px;
  }
`;

const WebSiteUrlParamWrapper = styled.div`
  :not(:last-child) {
    margin-bottom: 16px;
  }
`;

const WebSiteUrlParamLabel = styled.div`
  font-weight: bold;
`;

const WebsiteUrlParam = styled.div`
  word-break: break-word;
`;
