import React, { useState } from 'react';

import { Checkbox, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { DatePicker } from 'components/atoms/DatePicker';
import { Icon as STORECASTIcon } from 'components/atoms/Icon';
import WarningType from 'helpers/warningType';
import { GmbRegularHours } from 'models/Domain/GmbLocation/GmbRegularHours';
import { GmbTimePeriod } from 'models/Domain/GmbLocation/GmbTimePeriod';
import { GmbTimePeriodString } from 'models/Domain/GmbLocation/GmbTimePeriodString';
import { Store } from 'models/Domain/Store';
import { COLOR } from 'style/color';

const YAHOO_PLACE_REGULAR_HOURS_MAX_PERIODS = 3;

export const EditRegularHours: React.FC<{
  regularHours: GmbRegularHours;
  editType: string;
  store: Store;
  onCancel: () => void;
  onApply: (regularHours: GmbRegularHours) => void;
}> = ({ regularHours, editType, store, onCancel, onApply }) => {
  const [editingRegularHours, changeRegularHours] = useState(regularHours);
  const { isValid, error } = editingRegularHours.validate;
  const editTarget = editingRegularHours.regularHoursForEdit.find((target) => target.type === editType);

  if (!editTarget) return <></>;

  // 編集中の営業時間の警告メッセージ
  const warnings =
    store.isConnectedYahooPlace && editTarget.gmbTimePeriods.list.size > YAHOO_PLACE_REGULAR_HOURS_MAX_PERIODS
      ? WarningType.BUSINESS_HOUR_PERIODS_COUNT_WARNING_SINGLE_DAY
      : '';
  // Yahoo!プレイス連携店舗の編集中の営業時間のエラーメッセージ
  const yplaceError = store.isConnectedYahooPlace && editingRegularHours.errorForYahooPlace;
  const disabledForYplace = store.isConnectedYahooPlace && yplaceError !== '';

  return (
    <>
      {!isValid && (
        <Error>
          <StyledIcon type='status_error' />
          {error}
        </Error>
      )}
      {yplaceError && (
        <Error>
          <StyledIcon type='status_error' />
          {yplaceError}
        </Error>
      )}
      {warnings && (
        <Warning>
          <StyledIcon type='status_warning' />
          {warnings}
        </Warning>
      )}
      <ContentWrapper>
        <DayWrapper>
          <div>
            <ListWrapper>
              <CheckboxHoliday
                toggle
                checked={editTarget.gmbTimePeriods.isOpen}
                onChange={() =>
                  changeRegularHours(
                    editingRegularHours.toggleOpenDay(editTarget.type, !editTarget.gmbTimePeriods.isOpen),
                  )
                }
              />
              {!editTarget.gmbTimePeriods.isOpen && <LabelHoliday>定休日</LabelHoliday>}
            </ListWrapper>
          </div>
          <>
            <TimeWrapper>
              {editTarget.gmbTimePeriods.isOpen &&
                editTarget.gmbTimePeriods.list.map((hour: GmbTimePeriod, idx) => (
                  <TimeListWrapper key={idx}>
                    <StyledDatePicker
                      dateFormat={'HH:mm'}
                      timeFormat={'HH:mm'}
                      selected={hour.openTime.toDayjs?.toDate()}
                      onChange={(value: Date) =>
                        changeRegularHours(
                          editingRegularHours.changePeriod(
                            editTarget.type,
                            idx,
                            GmbTimePeriodString.fromDate(value),
                            hour.closeTime,
                          ),
                        )
                      }
                      showTimeSelect
                      showTimeSelectOnly
                      placeholderText={'開始時間'}
                    />
                    <Period>ー</Period>
                    <StyledDatePicker
                      dateFormat={'HH:mm'}
                      timeFormat={'HH:mm'}
                      selected={hour.closeTime.toDayjs?.toDate()}
                      onChange={(value: Date) =>
                        changeRegularHours(
                          editingRegularHours.changePeriod(
                            editTarget.type,
                            idx,
                            hour.openTime,
                            GmbTimePeriodString.fromDate(value),
                          ),
                        )
                      }
                      showTimeSelect
                      showTimeSelectOnly
                      placeholderText={'終了時間'}
                    />
                    {!editTarget.gmbTimePeriods.list.isEmpty() && (
                      <XIcon
                        onClick={() => {
                          changeRegularHours(editingRegularHours.removePeriod(editTarget.type, idx));
                        }}
                        name='x'
                      />
                    )}
                  </TimeListWrapper>
                ))}
              {editTarget.gmbTimePeriods.isOpen && (
                <AddWrapper>
                  <AddTime onClick={() => changeRegularHours(editingRegularHours.addOpenDay(editTarget.type))}>
                    ＋営業時間を追加
                  </AddTime>
                </AddWrapper>
              )}
            </TimeWrapper>
          </>
        </DayWrapper>
        <ButtonWrapper>
          <CancelButton priority='low' onClick={onCancel}>
            キャンセル
          </CancelButton>
          <ApplyButton
            priority='high'
            onClick={() => onApply(editingRegularHours)}
            disabled={!editingRegularHours.validate.isValid || disabledForYplace}
          >
            入力
          </ApplyButton>
        </ButtonWrapper>
      </ContentWrapper>
    </>
  );
};

const Error = styled.div`
  margin: 0 0 8px;
  color: ${COLOR.ERROR};
  font-size: 12px;
`;

const Warning = styled.div`
  margin: 0 0 8px;
  color: ${COLOR.WARNING};
  font-size: 12px;
  text-wrap: wrap;
`;

const StyledIcon = styled(STORECASTIcon)`
  padding: 0;
  margin: 0 4px -2px 0;
  width: 14px;
  height: 14px;
`;

const XIcon = styled(Icon)`
  &&& {
    height: 28px;
    cursor: pointer;
  }
`;

const Period = styled.div`
  margin-left: 4px;
  margin-right: 4px;
  font-size: 10px;
`;

const ContentWrapper = styled.div``;
const DayWrapper = styled.div`
  display: flex;
  min-height: 30px;
  @media (max-width: 600px) {
    display: block;
  }
`;

const TimeWrapper = styled.div``;

const TimeListWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
  &:first-of-type {
    margin-top: 0;
  }
`;

const ListWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const AddWrapper = styled.div``;

const StyledDatePicker = styled(DatePicker)`
  .react-datepicker-popper {
    z-index: 10; /* デフォルトのz-index: 1では他のコンポーネントが上に表示されてしまう */
  }

  .react-datepicker__input-container > input[type='text'] {
    font-size: 16px;
    text-align: left;
    width: 100px;
    padding: 0 8px;
    font-family: monospace !important;
  }
`;

const CheckboxHoliday = styled(Checkbox)`
  margin-top: 6px;
  &&& {
    transform: scale(0.5);
  }
`;

const LabelHoliday = styled.label`
  display: block;
  margin: 8px 30px 0;
`;

const AddTime = styled.div`
  color: #4183c4;
  cursor: pointer;
  font-size: 12px;
  margin-top: 4px;
`;

const ApplyButton = styled(Button)`
  &&& {
    font-weight: bold;
    cursor: pointer;
    width: auto;
    margin-left: 11px;
    box-shadow: none;
    padding: 5px 10px;
    border-radius: 2px;
    &:last-of-type {
      margin-right: 0px;
    }
    @media (max-width: 1024px) {
      font-size: 11px;
      padding: 9.5px 20px;
      &:last-of-type {
        margin-top: 4px;
      }
      &:first-of-type {
        margin-bottom: 4px;
      }
    }
  }
  &:before {
    white-space: pre;
  }
`;

const CancelButton = styled(Button)`
  &&& {
    color: ${COLOR.RED};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 14px;
  font-size: 12px;
`;
