export default {
  MULTIPLE_PHONES_WARNING: '2件以上の電話番号が登録されています。Yahoo!プレイスには先頭の1件のみを反映します。',
  BUSINESS_HOUR_PERIODS_COUNT_WARNING:
    '4件以上の営業時間が設定されている曜日があります。Yahoo!プレイスには各曜日に対して先頭の3件までを反映します。',
  // 店舗情報一括更新画面用
  BUSINESS_HOUR_PERIODS_COUNT_WARNING_SINGLE_DAY:
    '4件以上の営業時間が設定されています。Yahoo!プレイスには先頭の3件までを反映します。',
  SPECIAL_BUSINESS_HOUR_COUNT_WARNING:
    '10件を超える営業日または休業日が登録されています。Yahoo!プレイスには営業日、休業日それぞれ日付が本日に近い10件のみを反映します。',
  PROFILE_DESCRIPTION_CONTAINS_WARNING:
    'Yahoo!プレイスで利用できない記号（<, >, ’, ", &, ¥, 《, 》）が含まれています。Yahoo!プレイスには利用可能な記号に変換または削除した上で反映します。',
};
