import { Record as ImmutableRecord } from 'immutable';

export class STConnectedServiceValue extends ImmutableRecord<{
  websiteUrlParam: string;
}>({
  websiteUrlParam: '',
}) {}

export class STConnectedServices extends ImmutableRecord<{
  gbp: STConnectedServiceValue;
  abc: STConnectedServiceValue;
  yahooPlace: STConnectedServiceValue;
}>({
  gbp: new STConnectedServiceValue({ websiteUrlParam: '' }),
  abc: new STConnectedServiceValue({ websiteUrlParam: '' }),
  yahooPlace: new STConnectedServiceValue({ websiteUrlParam: '' }),
}) {}
