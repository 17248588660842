import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { List } from 'immutable';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Header, Message, Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { DetailLink } from 'components/atoms/DetailLink';
import { Icon } from 'components/atoms/Icon';
import { Link } from 'components/atoms/Link';
import { PullDown } from 'components/atoms/PullDown';
import { Store } from 'models/Domain/Store';
import YahooPlaceAccount from 'models/Domain/YahooPlace/Account';
import YahooPlaceSummary, { YahooPlaceRecord } from 'models/Domain/YahooPlace/Place';
import { StoreDetailActions } from 'modules/storeDetail/actions';
import { YahooPlaceActions } from 'modules/yahooPlace/actions';
import { Path } from 'routes';
import { COLOR } from 'style/color';

type Props = { isOpen: boolean; onClose: () => void; targetStore: Store };

interface PlacePulldownOption {
  text: string;
  value: number;
}

/** 店舗のYahoo! プレイス連携設定モーダル */
export const YahooPlaceConnectModal: React.FC<Props> = ({ isOpen, onClose, targetStore }) => {
  // アカウントが一つも追加されてない時に表示するメッセージ
  const MESSAGE_NODE_FOR_ACCOUNT_NOT_EXISTS = (
    <div>
      アカウントが追加されていないため、Yahoo! プレイスの店舗一覧を取得できません。
      <Link to={Path.storeAccounts.index}>
        <DetailLink>店舗連携アカウント画面</DetailLink>
      </Link>
      から追加してください。
    </div>
  );
  // 契約店舗数を超えて連携できない時に表示するメッセージ
  const REACHED_CONTRACTED_STORE_LIMIT_MESSAGE =
    '契約店舗数の上限に達しているため、新たに店舗をYahoo! プレイスと連携できません。上限の変更につきましては、メニューの「サポート」よりお問い合わせください。';
  // Yahoo! プレイスの店舗名の制限に反している時に表示するメッセージ
  const INVALID_YAHOO_PLACE_STORE_NAME_MESSAGE =
    '以下の問題のため、Yahoo! プレイスと連携できません。店舗情報を修正の上、再度お試しください。\n・店舗名が60文字を超えているため、Yahoo! プレイスに店舗名を反映できません。';

  const dispatch = useDispatch();
  const { yahooPlaceState, appState, storeState } = useSelector((state) => ({
    yahooPlaceState: state.yahooPlace,
    appState: state.app,
    storeState: state.store,
  }));

  const { accounts, placeSummaries } = yahooPlaceState;

  /** 選択中のアカウント、ビジネス、店舗 */
  const [selectingAccountId, setSelectingAccountId] = useState<number | 'All'>('All');
  const [selectingYahooPlaceId, setSelectingYahooPlaceId] = useState<number | null>(null);

  useEffect(() => {
    if (isOpen) {
      dispatch(YahooPlaceActions.getAccounts());
      dispatch(YahooPlaceActions.getPlaces());
    }
  }, [dispatch, isOpen]);

  const onClickDisconnectButton = useCallback(() => {
    if (!targetStore) {
      return;
    }
    if (!confirm(`${targetStore.fullName}のYahoo! プレイス連携を解除しますか？`)) {
      return;
    }
    dispatch(YahooPlaceActions.disconnect(targetStore.id));
    setSelectingAccountId('All');
    setSelectingYahooPlaceId(null);
    onClose();
  }, [dispatch, targetStore, onClose]);

  const onClickCancelButton = useCallback(() => {
    setSelectingAccountId('All');
    setSelectingYahooPlaceId(null);
    onClose();
  }, [setSelectingAccountId, setSelectingYahooPlaceId, onClose]);

  // 連携ボタンが押された
  const onClickConnectButton = useCallback(() => {
    // 対象の店舗が選択されていない場合処理終了
    if (!targetStore || selectingAccountId === null || selectingYahooPlaceId === null || selectingAccountId === 'All') {
      return;
    }
    const selectingYahooPlaces = placeSummaries.find((summary) => summary.accountId === selectingAccountId)?.places;
    const selectingYahooPlace = selectingYahooPlaces?.find((place) => place.placeSeq === selectingYahooPlaceId);
    if (!selectingYahooPlace) {
      return;
    }

    // Yahoo! プレイスの店舗名（カナ）をSTORECASTに取り込む
    const newStore = targetStore.changeNameKana(selectingYahooPlace.businessNameKana);
    dispatch(StoreDetailActions.setStoreForEdit(newStore));
    dispatch(StoreDetailActions.updateStoreNameKana());

    // ST店舗をYahoo! プレイスと連携
    const params = {
      storeId: targetStore.id,
      accountId: selectingAccountId as number,
      placeSeq: selectingYahooPlaceId,
    };
    dispatch(YahooPlaceActions.connect(params));

    setSelectingAccountId('All');
    setSelectingYahooPlaceId(null);
    onClose();
  }, [
    dispatch,
    targetStore,
    selectingAccountId,
    selectingYahooPlaceId,
    placeSummaries,
    setSelectingYahooPlaceId,
    onClose,
  ]);

  // アカウントが選択された
  const onChangeAccountId = useCallback(
    (value: number | 'All') => {
      setSelectingAccountId(value);
      if (selectingAccountId !== value) {
        setSelectingYahooPlaceId(null);
      }
    },
    [selectingAccountId, setSelectingYahooPlaceId],
  );

  // 選択中のYahoo! プレイス店舗の店舗名（カナ）
  const selectingYPlaceBusinessNameKana = useMemo(() => {
    if (selectingYahooPlaceId === null) {
      return null;
    }
    const selectingYahooPlaces = placeSummaries.find((summary) => summary.accountId === selectingAccountId)?.places;
    const selectingYahooPlace = selectingYahooPlaces?.find((place) => place.placeSeq === selectingYahooPlaceId);
    return selectingYahooPlace ? selectingYahooPlace.businessNameKana : '（未設定）';
  }, [selectingAccountId, selectingYahooPlaceId, placeSummaries]);

  // 店舗が選択された際にアカウントIDを設定する関数（Allの場合のみアカウントIDを逆引きで設定）
  const onChangeYahooPlaceId = useCallback(
    (placeSeq: number) => {
      // selectingAccountIdが'All'の場合にのみ、アカウントIDを設定
      if (selectingAccountId === 'All') {
        // 選択された店舗のplaceSeqから、その店舗が属するアカウントを検索
        const foundAccount = placeSummaries.find((summary) =>
          summary.places.some((place: YahooPlaceRecord) => place.placeSeq === placeSeq),
        );

        if (foundAccount) {
          // 店舗が属するアカウントIDを設定
          setSelectingAccountId(foundAccount.accountId);
        }
      }

      // 選択された店舗のIDを設定
      setSelectingYahooPlaceId(placeSeq);
    },
    [placeSummaries, selectingAccountId, setSelectingAccountId, setSelectingYahooPlaceId],
  );

  const currentUser = appState.currentUser;
  const organization = currentUser.organization;
  if (organization === null) {
    return <></>;
  }

  // マップサービス連携中の店舗数
  const connectedStoresCount = storeState.stores.filterByIsConnectedToMapService().list.size;

  // 連携対象の選択肢
  const accountPulldownOptions = [
    { text: 'すべて', value: 'All' },
    ...accounts.map((account: YahooPlaceAccount) => ({ text: account.name, value: account.id })).toArray(),
  ];

  // アカウントが選択された際に店舗のプルダウンを更新する
  const getPlacePulldownOptions = (
    accountSummaries: List<YahooPlaceSummary>,
    accountId: number | 'All',
  ): PlacePulldownOption[] => {
    const filteredAccounts =
      accountId === 'All'
        ? accountSummaries
        : accountSummaries.filter((accountSummary) => accountSummary.accountId === accountId);

    return filteredAccounts
      .flatMap((accountSummary) => accountSummary.places)
      .map((place: YahooPlaceRecord) => ({
        text: place.businessName,
        value: place.placeSeq,
      }))
      .toArray();
  };

  // アカウントが選択された際に店舗のプルダウンを更新
  const placePulldownOptions = getPlacePulldownOptions(placeSummaries, selectingAccountId);

  // アカウントが選択されて連携できる店舗がない場合は警告表示
  const showWarning = !accounts.isEmpty() && !!selectingAccountId && !placePulldownOptions.length;

  // 連携ボタンの活性/非活性
  // - 店舗名がYahoo! プレイスの文字数制限を超えていない
  // - 連携したい店舗が選択されている
  // - マップサービス連携中の店舗数が契約店舗数上限を超えていない
  const reachedContractedStoreLimit =
    !targetStore?.isConnectedToMapService && organization.contractedStores <= connectedStoresCount;
  const isValidStoreName = targetStore ? targetStore.fullName.length <= 60 : true;
  const canConnect = !!selectingYahooPlaceId && !reachedContractedStoreLimit && isValidStoreName;
  const showDisconnectButton = targetStore?.isConnectedYahooPlace && !currentUser.isMemberUser;

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Modal.Header>Yahoo! プレイス連携設定</Modal.Header>
      <Modal.Content>
        {/* アカウントが一つも追加されてない時に表示するメッセージ */}
        {accounts.isEmpty() && <Message warning content={MESSAGE_NODE_FOR_ACCOUNT_NOT_EXISTS} />}
        {/* 契約店舗数上限に達している場合メッセージを表示 */}
        {reachedContractedStoreLimit && <Message error content={REACHED_CONTRACTED_STORE_LIMIT_MESSAGE} />}
        {/* 店舗名がYahoo! プレイスの制限に反している場合メッセージを表示 */}
        {!isValidStoreName && <Message error content={INVALID_YAHOO_PLACE_STORE_NAME_MESSAGE} />}

        <Header>{targetStore.fullName}</Header>
        <Form warning={showWarning}>
          <Form.Field>
            <label>アカウント</label>
            <CustomPullDown
              value={selectingAccountId}
              options={accountPulldownOptions}
              placeholder={'アカウント選択'}
              multiple={false}
              onChange={onChangeAccountId}
              disabled={accounts.isEmpty()}
            />
          </Form.Field>
          <Form.Field>
            <label>店舗</label>
            <CustomPullDown
              value={selectingYahooPlaceId}
              options={placePulldownOptions}
              placeholder={'店舗選択'}
              multiple={false}
              onChange={(value: number) => onChangeYahooPlaceId(value)}
              disabled={!placePulldownOptions.length}
            />
          </Form.Field>
          <Message warning content='新規に取り込みができるYahoo! プレイスの店舗がありません。' />
          <Caution visible={canConnect}>
            <CautionIconWrapper>
              <CautionIcon type='status_error' />
            </CautionIconWrapper>
            <CautionMessage>
              <ul>
                <li>
                  Yahoo! プレイスの店舗名（フリガナ）「<StoreNameKana>{selectingYPlaceBusinessNameKana}</StoreNameKana>
                  」をSTORECASTに取り込みます。
                  <br />
                  店舗名（フリガナ）を変更したい場合は、連携後STORECASTの店舗画面から正しい読み方を設定してください。
                </li>
                <li>
                  Yahoo! プレイスの次の店舗情報は、STORECASTの店舗情報で上書きされます。
                  <br />
                  店舗名、所在地、ピンの位置（緯度経度）、電話番号、営業時間、特別営業時間、店舗の説明文
                </li>
              </ul>
            </CautionMessage>
          </Caution>
        </Form>
      </Modal.Content>
      <ModalActions>
        <LeftButton>
          {showDisconnectButton && (
            <StyledButton negative priority={'high'} onClick={onClickDisconnectButton}>
              連携解除
            </StyledButton>
          )}
        </LeftButton>
        <RightButtons>
          <CancelButton onClick={onClickCancelButton}>キャンセル</CancelButton>
          <StyledButton priority={'high'} disabled={!canConnect} onClick={onClickConnectButton}>
            連携
          </StyledButton>
        </RightButtons>
      </ModalActions>
    </Modal>
  );
};

const CustomPullDown = styled(PullDown)`
  margin-top: 16px;
`;

const ModalActions = styled(Modal.Actions)`
  display: flex;
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-direction: column;
    button {
      margin-bottom: 20px;
    }
  }
`;

const LeftButton = styled.div``;
const RightButtons = styled.div``;

const StyledButton = styled(Button)`
  &&& {
    width: 180px;
  }
`;

const CancelButton = styled(StyledButton)`
  margin-right: 12px;
`;

const Caution = styled.div<{ visible: boolean }>`
  font-size: 14px;
  color: ${COLOR.ERROR};
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  gap: 20px;
  ul {
    list-style: inside;
    list-style-position: outside;
  }
`;

const CautionIconWrapper = styled.div`
  margin-top: 1px;
`;
const CautionIcon = styled(Icon)`
  padding: 0;
  width: 16px;
  height: 16px;
`;
const CautionMessage = styled.div``;
const StoreNameKana = styled.span`
  font-weight: bold;
`;
