import actionCreatorFactory from 'typescript-fsa';

import { JSObject } from 'types/Common';

const actionCreator = actionCreatorFactory('Abc');

/** Apple Business Connect連携関係のアクション */
export const AbcActions = {
  // 店舗のABC連携 組織に紐づくカンパニーのロケーション一覧の取得
  getLocations: actionCreator('getLocations'),

  // アカウント
  getAccounts: actionCreator('getAccounts'),
  setAccounts: actionCreator<JSObject[]>('setAccounts'),
  removeAccount: actionCreator<number>('removeAccount'),

  // 店舗のABC連携 連携・連携解除
  connect: actionCreator<{ storeId: number; accountId: number; locationId: string }>('connect'),
  disconnect: actionCreator<number>('disconnect'),
};
