import dayjs from 'dayjs';
import { List, Record } from 'immutable';

import ErrorType from 'helpers/errorType';
import WarningType from 'helpers/warningType';
import { JSObject } from 'types/Common';

import { GmbTimePeriodDate } from './GmbTimePeriodDate';
import { GmbTimePeriodString } from './GmbTimePeriodString';

export type DayOfWeekType = 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY';

// 時間同士だけでは長さが計れないため見掛けの日付を用意
const TODAY = dayjs().format('YYYY-MM-DD');
const TOMORROW = dayjs().add(1, 'day').format('YYYY-MM-DD');

const MINUTES_IN_DAY = 1440;

const getDateFromGmbDate = (gmbDate: { year: string; month: string; day: string }) => {
  return `${gmbDate.year}-${gmbDate.month}-${gmbDate.day}`;
};

export class GmbTimePeriod extends Record<{
  openTime: GmbTimePeriodString;
  closeTime: GmbTimePeriodString;
  isClosed: boolean;
}>({
  openTime: new GmbTimePeriodString(),
  closeTime: new GmbTimePeriodString(),
  isClosed: false,
}) {
  constructor(data: JSObject = {}) {
    const params = { ...data };
    params.openTime = params.openTime && new GmbTimePeriodString(params.openTime);
    params.closeTime = params.openTime && new GmbTimePeriodString(params.closeTime);
    super(params);
  }

  get periodForDisplay() {
    return this.isClosed ? '定休日' : `${this.openTime} - ${this.closeTime}`;
  }

  /** openTime ~ closeTime の長さを分単位で返す */
  get range() {
    const openTime = dayjs(`${TODAY} ${this.openTime.value}`);
    const closeTime = dayjs(`${this.isEndingTomorrow ? TOMORROW : TODAY} ${this.closeTime.value}`);
    return closeTime.diff(openTime, 'minute');
  }

  get validate() {
    if (!this.openTime.value) {
      return { isValid: false, error: ErrorType.OPEN_TIME_EMPTY_ERROR };
    }
    if (!this.openTime.isValid) {
      return { isValid: false, error: ErrorType.OPEN_TIME_INVALID_ERROR };
    }
    if (!this.closeTime.value) {
      return { isValid: false, error: ErrorType.CLOSE_TIME_EMPTY_ERROR };
    }
    if (!this.isClosed && this.openTime.value === this.closeTime.value) {
      return { isValid: false, error: ErrorType.CLOSE_TIME_BEFORE_OPEN_TIME_ERROR };
    }
    if (!this.closeTime.isValid) {
      return { isValid: false, error: ErrorType.CLOSE_TIME_INVALID_ERROR };
    }
    if (!this.isClosed && dayjs(this.closeTime.value).isBefore(dayjs(this.openTime.value))) {
      return { isValid: false, error: ErrorType.CLOSE_TIME_BEFORE_OPEN_TIME_ERROR };
    }
    return { isValid: true, error: '' };
  }

  /** period が2日にまたがっているかどうか */
  get isEndingTomorrow() {
    return Number(this.closeTime.hourString) - Number(this.openTime.hourString) < 0;
  }

  setTime(openTime: GmbTimePeriodString, closeTime: GmbTimePeriodString) {
    return this.merge({ openTime, closeTime });
  }
}

export class GmbTimePeriods extends Record<{
  date: GmbTimePeriodDate;
  list: List<GmbTimePeriod>;
}>({
  date: new GmbTimePeriodDate(),
  list: List(),
}) {
  constructor(data: JSObject = []) {
    const params = { ...data };
    if (data) {
      params.date = params.date && new GmbTimePeriodDate(params.date);
      params.list = List(data.list && data.list.map((period: JSObject) => new GmbTimePeriod(period)));
    }
    super(params);
  }

  get periodsForDisplay() {
    return this.list.map((period) => `${period.periodForDisplay}`).toArray();
  }

  get isOpen() {
    return this.list.filter((period) => period.isClosed).isEmpty();
  }

  get isPastDate() {
    return dayjs(this.date.value).isBefore(dayjs(), 'day');
  }

  get validate() {
    const errors = this.list.map((period) => period.validate).filter((error) => !error.isValid);
    if (!errors.isEmpty()) {
      return { isValid: false, error: errors.toArray()[0].error };
    }
    if (!this.date.value) {
      return { isValid: false, error: ErrorType.DATE_EMPTY_ERROR };
    }
    if (this.list.isEmpty()) {
      return { isValid: false, error: ErrorType.SPECIAL_HOUR_PERIODS_EMPTY_ERROR };
    }
    // 合計時間が24時間を超えていないか
    const totalMinutes = this.list.reduce((sum, period) => {
      return sum + period.range;
    }, 0);
    if (totalMinutes > MINUTES_IN_DAY) {
      return { isValid: false, error: ErrorType.PERIODS_EXCEEDS_24HOURS };
    }
    return { isValid: true, error: '' };
  }

  get updateParams() {
    return this.list
      .map((period) => {
        // isClosed の場合、GBP側との差分をなくすため、 startDate, isClosed のみ格納
        if (period.isClosed) {
          return {
            startDate: this.date.gmbDateObject,
            isClosed: period.isClosed,
          };
        }

        const endDate = period.isEndingTomorrow ? this.date.gmbTomorrowDateObject : this.date.gmbDateObject;
        return {
          startDate: this.date.gmbDateObject,
          openTime: period.openTime.value,
          endDate,
          closeTime: period.closeTime.value,
          isClosed: period.isClosed,
        };
      })
      .toArray();
  }

  /** 指定された曜日に開店時間オブジェクトを作る */
  addOpenDay(index: number, openTime = new GmbTimePeriodString('09:00'), closeTime = new GmbTimePeriodString('17:00')) {
    const period = new GmbTimePeriod().setTime(openTime, closeTime);
    return this.set('list', this.list.push(period));
  }

  /** 営業日にする（営業時間を追加する） */
  openDay() {
    const openPeriod = new GmbTimePeriod({ isClosed: false });
    return this.set('list', List([openPeriod]));
  }

  /** 定休日にする（営業時間を削除する） */
  closeDay() {
    const closePeriod = new GmbTimePeriod({ isClosed: true });
    return this.set('list', List([closePeriod]));
  }

  /** 時間を変更する */
  changePeriod(index: number, openTime: string, closeTime: string) {
    return this.setIn(['list', index, 'openTime', 'value'], openTime).setIn(
      ['list', index, 'closeTime', 'value'],
      closeTime,
    );
  }

  changeDate(value: string) {
    return this.set('date', this.date.changeDate(value));
  }

  /** 時間を削除する */
  removePeriod(index: number) {
    return this.set(
      'list',
      this.list.filter((_, idx) => idx !== index),
    );
  }
}

export class GmbSpecialHours extends Record<{
  specialHourPeriods: List<GmbTimePeriods>;
}>({
  specialHourPeriods: List(),
}) {
  constructor(data: JSObject = { specialHourPeriods: [] }) {
    const params = { ...data };
    if (params.specialHourPeriods) {
      const specialHourPeriods = params.specialHourPeriods.reduce(
        (result: JSObject, period: JSObject) => ({
          ...result,
          [getDateFromGmbDate(period.startDate)]: [...(result[getDateFromGmbDate(period.startDate)] || []), period],
        }),
        [],
      );
      params.specialHourPeriods = List(
        Object.entries(specialHourPeriods).map(([date, list]) => new GmbTimePeriods({ date, list })),
      ).sortBy((periods) => periods.date.paddedDate);
    }
    super(params);
  }

  get specialHoursForDisplay() {
    return this.specialHourPeriods.map((periods) => `${periods.date.paddedDate} ${periods.periodsForDisplay}`);
  }

  get validate() {
    let error;
    this.specialHourPeriods.forEach((periods: GmbTimePeriods) => {
      if (!periods.validate.isValid) {
        error = { isValid: false, error: periods.validate.error };
      }
    });
    return error || { isValid: true, error: '' };
  }

  /** Yahoo! プレイスへ反映する場合の警告を返す */
  get warningForYahooPlace() {
    // 未来の営業日が10件を超えて登録されている場合
    if (this.removePastDate().openDays.size > 10) {
      return WarningType.SPECIAL_BUSINESS_HOUR_COUNT_WARNING;
    }
    // 未来の休業日が10件を超えて登録されている場合
    if (this.removePastDate().closeDays.size > 10) {
      return WarningType.SPECIAL_BUSINESS_HOUR_COUNT_WARNING;
    }
    return '';
  }

  /** Yahoo! プレイスへ反映する場合のエラーを返す */
  get errorForYahooPlace() {
    let errorMessage = '';
    this.specialHourPeriods.forEach((periods: GmbTimePeriods) => {
      periods.list.forEach((period: GmbTimePeriod) => {
        if (
          period.closeTime.value < period.openTime.value &&
          ((period.closeTime.hour + 24 == 36 && period.closeTime.minute > 0) || period.closeTime.hour + 24 > 36)
        ) {
          errorMessage = ErrorType.CLOSE_TIME_RANGE_EXCEEDS_ERROR;
        }
      });
    });
    return errorMessage;
  }

  get updateParams() {
    const periods = this.specialHourPeriods
      .sortBy((periods) => periods.date.paddedDate)
      .map((period) => period.updateParams);
    return { specialHourPeriods: periods.toArray().flat() };
  }

  /** 営業日のみを返す */
  get openDays() {
    return this.specialHourPeriods.filter((periods) => periods.isOpen);
  }

  /** 休業日のみを返す */
  get closeDays() {
    return this.specialHourPeriods.filter((periods) => !periods.isOpen);
  }

  hasPastDate() {
    return this.specialHourPeriods.some((specialHourPeriod) => specialHourPeriod.isPastDate);
  }

  /** 過去の営業日・休業日を除いた自身のオブジェクトを返す */
  getExceptPastDate() {
    return this.update('specialHourPeriods', (specialHourPeriods) => specialHourPeriods.filter((sh) => !sh.isPastDate));
  }

  toggleOpenDay(dayIndex: number, isOpen: boolean) {
    return isOpen ? this.changeOpenDay(dayIndex) : this.changeCloseDay(dayIndex);
  }

  changePeriod(dayIndex: number, timeIndex: number, openTime: string, closeTime: string) {
    const target = this.specialHourPeriods.get(dayIndex);
    if (!target) return this;
    return this.setIn(['specialHourPeriods', dayIndex], target.changePeriod(timeIndex, openTime, closeTime));
  }

  changeDate(dayIndex: number, value: string) {
    const target = this.specialHourPeriods.get(dayIndex);
    if (!target) return this;
    return this.setIn(['specialHourPeriods', dayIndex], target.changeDate(value));
  }

  /** 過去の特別営業時間を削除して返す */
  removePastDate() {
    return this.update('specialHourPeriods', (specialHourPeriods) => specialHourPeriods.filter((sh) => !sh.isPastDate));
  }

  removePeriod(dayIndex: number, timeIndex: number) {
    const target = this.specialHourPeriods.get(dayIndex);
    if (!target) return this;
    return this.setIn(['specialHourPeriods', dayIndex], target.removePeriod(timeIndex));
  }

  addOpenDay(dayIndex: number) {
    const target = this.specialHourPeriods.get(dayIndex);
    if (!target) return this;
    return this.setIn(['specialHourPeriods', dayIndex], target.addOpenDay(dayIndex));
  }

  addSpecialHours() {
    const periods = new GmbTimePeriods();
    return this.set('specialHourPeriods', this.specialHourPeriods.push(periods));
  }

  /** 日程を削除する */
  removeDay(index: number) {
    return this.set(
      'specialHourPeriods',
      this.specialHourPeriods.filter((_, idx) => idx !== index),
    );
  }

  mergeSpecialHours(targetSpecialHours: GmbSpecialHours) {
    // 既存のlistから上書きしない日付を切り出す
    const keepList = this.specialHourPeriods.filter((target) => {
      const result = targetSpecialHours.specialHourPeriods.filter((periods) => {
        return periods.date.paddedDate === target.date.paddedDate;
      });
      return result.size === 0;
    });

    // 上書きする日付を切り出す
    const updateList = targetSpecialHours.specialHourPeriods.filter((target) => {
      const result = this.specialHourPeriods.filter((periods) => {
        return periods.date.paddedDate === target.date.paddedDate;
      });
      return result.size > 0;
    });

    // 新規に追加する日付を切り出す
    const addList = targetSpecialHours.specialHourPeriods.filter((target) => {
      const result = this.specialHourPeriods.filter((periods) => {
        return periods.date.paddedDate === target.date.paddedDate;
      });
      return result.size === 0;
    });
    return this.set('specialHourPeriods', keepList.concat(updateList, addList));
  }

  /** 曜日を営業日にする */
  private changeOpenDay(dayIndex: number) {
    return this.updateIn(['specialHourPeriods', dayIndex], (periods) => periods.openDay());
  }

  /** 曜日を定休日にする */
  private changeCloseDay(dayIndex: number) {
    return this.updateIn(['specialHourPeriods', dayIndex], (periods) => periods.closeDay());
  }
}
