import React from 'react';

import styled from 'styled-components';

import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from 'components/molecules/Table';
import ImageDetail, { PublishGmbStatusKey } from 'models/Domain/Image/ImageDetail';
import { Stores } from 'models/Domain/Store';
import { COLOR } from 'style/color';

/**
 * 写真に紐づいている店舗一覧
 */
export const AlbumImageStoreList = React.memo<{ detailImage: ImageDetail; stores: Stores }>(
  ({ detailImage, stores }) => {
    return (
      <Wrapper>
        <Table>
          <TableHeader>
            <TableHeaderRow>
              <StyledTableHeaderCell>店舗名</StyledTableHeaderCell>
              <StyledTableHeaderCell>掲載ステータス</StyledTableHeaderCell>
            </TableHeaderRow>
          </TableHeader>
          <TableBody>
            {detailImage.image_info.map((image) => {
              const targetStore = stores.findStore(image.store_id);
              if (!targetStore) {
                return;
              }
              const status = targetStore.isConnectedGBP ? image.publish_gmb_status : 'UNCONNECTED';
              return (
                <TableRow key={image.album_image_id}>
                  <StyledTableCell>{targetStore.fullName}</StyledTableCell>
                  <StatusCell status={status}>
                    {detailImage.getGmbPublishStatusLabel(image.publish_gmb_status, targetStore)}
                  </StatusCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  width: 100%;
`;

const StyledTableHeaderCell = styled(TableHeaderCell)`
  &&& {
    border-right: 1px solid rgba(0.34, 0.36, 0.38, 0.15);
  }
`;

const StyledTableCell = styled(TableCell)`
  &&& {
    border-right: 1px solid rgba(0.34, 0.36, 0.38, 0.15);
    font-weight: bold;
  }
`;

type GbpStatus = PublishGmbStatusKey | 'UNCONNECTED';

const StatusCell = styled(StyledTableCell)<{ status: GbpStatus }>`
  &&& {
    width: 150px;
    text-align: center;
    color: ${(props) => {
      const status: GbpStatus = props.status;
      switch (status) {
        case 'INPROGRESS':
          return '#E6C200';
        case 'PUBLISHED':
          return '#4CB827';
        case 'FAILED':
          return '#FE5C5C';
        case 'UNPUBLISHED':
          return '#2185D0';
        default:
          return COLOR.BLACK;
      }
    }}
`;
