import React, { useCallback } from 'react';

import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { ServiceIcon } from 'components/atoms/ServiceIcon';
import { StickyHeader, Title } from 'components/atoms/StickyHeader';
import { ActionUploadButton } from 'components/molecules/FileUploader';
import { AppActions } from 'modules/app/actions';

type Props = {
  canUseStoreConnectService: boolean;
  onChangeImages: (files: File[], imageInfo: ImageFileMeta[]) => void;
};

export const AlbumListIndexHeader = React.memo<Props>(({ canUseStoreConnectService, onChangeImages }) => {
  const dispatch = useDispatch();

  /**
   * 「写真を追加」した時に呼ばれる
   * ファイルとバリデーション用のメタデータを親コンポーネントに渡す
   */
  const onDropAccepted = useCallback(
    (files: File[]) => {
      const metadata: ImageFileMeta[] = [];

      // ファイルのメタデータを取得
      files.forEach(async (file) => {
        const localImageUrl = URL.createObjectURL(file);

        const imageObject = new window.Image();
        imageObject.src = localImageUrl;
        await imageObject.decode();

        metadata.push({
          type: file.type,
          width: imageObject.naturalWidth,
          height: imageObject.naturalHeight,
          size: file.size,
        });

        URL.revokeObjectURL(localImageUrl);
      });

      onChangeImages(files, metadata);
    },
    [onChangeImages],
  );

  return (
    <>
      <StickyHeader>
        <Title>写真</Title>
        {canUseStoreConnectService && (
          <ServiceIconWrapper>
            <ServiceIcon.Google />
          </ServiceIconWrapper>
        )}
        <UploadImageButton
          uploadType={'image'}
          iconName='camera'
          buttonText='写真を追加'
          onDropAccepted={(files) => onDropAccepted(files)}
          onDropRejected={(files) => dispatch(AppActions.onDropRejected(files))}
        />
      </StickyHeader>
    </>
  );
});

const ServiceIconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto 0 0;
  gap: 6px;
`;

const UploadImageButton = styled(ActionUploadButton)`
  &&& {
    width: auto;
    height: 40px;
    font-size: 14px;
    padding: 11px 15px;
  }
`;
