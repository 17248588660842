import React, { useCallback, useEffect, useMemo } from 'react';

import { Set } from 'immutable';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Loader } from 'components/atoms/Loader';
import { Tab, TabContainer } from 'components/atoms/Tab';
import { Paging } from 'components/molecules/Paging';
import { SwitchContent } from 'components/molecules/SwitchContent';
import { CurrentSearchCondition } from 'components/pageComponents/PromotionIndex/CurrentSearchCondition';
import { PromotionFilter } from 'components/pageComponents/PromotionIndex/PromotionFilter';
import { PromotionInfoCard } from 'components/pageComponents/PromotionIndex/PromotionInfoCard';
import { PromotionInfoDownloadModal } from 'components/pageComponents/PromotionIndex/PromotionInfoDownloadModal';
import { PromotionInfoHeader } from 'components/pageComponents/PromotionIndex/PromotionInfoHeader';
import { Body, MainWrapper } from 'components/templates/MainWrapper';
import { getPageTitle } from 'helpers/utils';
import ListPromotion from 'models/Domain/Promotion/ListPromotion';
import { ParamsStatus } from 'models/Domain/Promotion/PromotionSearchCondition';
import { User } from 'models/Domain/User';
import { PromotionActions } from 'modules/promotion/actions';
import { COLOR } from 'style/color';

/** 投稿に対して、編集・削除メニューを表示するかどうか */
export const showEditMenu = (currentUser: User, listPromotion: ListPromotion) => {
  // 管理者・本社スタッフ以上は可
  if (currentUser.isAdminUser || currentUser.isVmdUser) {
    return true;
  }

  // 送信先店舗が空の場合、下書きでなければ不可
  if (listPromotion.store_ids.isEmpty()) {
    return listPromotion.isDraft;
  }

  const promotionStoreIds = Set(listPromotion.store_ids);
  // SV: 管理店舗
  // 店舗スタッフ: 所属店舗
  const userStoreIds = Set(currentUser.isSvUser ? currentUser.managing_stores : currentUser.stores);

  // 投稿の送信先店舗が全てユーザの店舗に含まれている場合、可
  // 投稿の送信先店舗にユーザの店舗外の店舗がある場合、不可
  return promotionStoreIds.isSubset(userStoreIds);
};

export const PromotionIndex: React.FC = () => {
  const dispatch = useDispatch();
  const { setSearchCondition, initializePromotionListPage, setIsOpenDownloadModal } = useMemo(
    () => bindActionCreators(PromotionActions, dispatch),
    [dispatch],
  );

  const {
    currentUser,
    searchCondition,
    committedSearchCondition,
    promotionList,
    isOpenDownloadModal,
    isLoadingPromotionList,
    stores,
  } = useSelector((state) => ({
    currentUser: state.app.currentUser,
    searchCondition: state.promotion.promotionSearchCondition,
    committedSearchCondition: state.promotion.committedPromotionSearchCondition,
    promotionList: state.promotion.promotionList,
    isOpenDownloadModal: state.promotion.isOpenDownloadModal,
    isLoadingPromotionList: state.promotion.isLoadingPromotionList,
    stores: state.store.stores,
  }));

  useEffect(() => {
    initializePromotionListPage();
  }, [initializePromotionListPage]);

  const commitFilter = useCallback(() => {
    // URLを変更するため
    setSearchCondition({ searchCondition, updateLocation: true });
  }, [searchCondition, setSearchCondition]);

  const changeFilter = useCallback(
    (filter: ParamsStatus) => {
      // URLを変更するため
      setSearchCondition({ searchCondition: searchCondition.set('params', filter).setPage(1), updateLocation: false });
    },
    [searchCondition, setSearchCondition],
  );

  /**
   * ページ条件を変更する
   */
  const changePage = useCallback(
    (page: number) => {
      // ページ条件の変更は確定済み検索条件から変更する
      const updatedCondition = committedSearchCondition.setPage(page);
      // 検索条件の変更と投稿の取得
      setSearchCondition({ searchCondition: updatedCondition, updateLocation: true });
      // 表示位置をページの一番上に
      window.scrollTo({ top: 0 });
    },
    [committedSearchCondition, setSearchCondition],
  );

  const downloadFilter = useMemo(() => searchCondition.params.toDownloadFilter(), [searchCondition.params]);

  const handleOnChangeTab = useCallback(
    (value: '投稿' | '予約投稿' | '下書き') => {
      let newSearchCondition = committedSearchCondition;
      switch (value) {
        case '投稿':
          newSearchCondition = newSearchCondition.mergeIn(['params'], { isDraft: false, isScheduled: false });
          break;
        case '予約投稿':
          newSearchCondition = newSearchCondition.mergeIn(['params'], { isDraft: false, isScheduled: true });
          break;
        case '下書き':
          newSearchCondition = newSearchCondition.mergeIn(['params'], { isDraft: true, isScheduled: null });
          break;
      }
      setSearchCondition({ searchCondition: newSearchCondition.setPage(1), updateLocation: true });
    },
    [committedSearchCondition, setSearchCondition],
  );

  if (!promotionList) {
    return null;
  }

  const { isDraft, isScheduled } = searchCondition.params;

  const canUseStoreConnectService = currentUser.organization
    ? currentUser.organization.canUseStoreConnectService()
    : false;

  return (
    <StyledMainWrapper>
      <Helmet title={getPageTitle('投稿')} />
      <PromotionInfoHeader canUseStoreConnectService={canUseStoreConnectService} />
      <Body>
        <PromotionFilterWrapper>
          <SwitchContent
            openLabel='絞り込み条件を閉じる'
            closedLabel='絞り込み条件を変更する'
            closedContents={
              <SearchWrapper>
                <CurrentSearchCondition filter={searchCondition.params} stores={stores} />
              </SearchWrapper>
            }
          >
            <PromotionFilter
              filter={searchCondition.params}
              onChangeFilter={changeFilter}
              onCommitFilter={commitFilter}
            />
          </SwitchContent>
        </PromotionFilterWrapper>
        <StyledTabContainer>
          <Tab active={!isScheduled && !isDraft} onClick={() => handleOnChangeTab('投稿')}>
            投稿
          </Tab>
          <Tab active={isScheduled} onClick={() => handleOnChangeTab('予約投稿')}>
            予約投稿
          </Tab>
          <Tab active={isDraft} onClick={() => handleOnChangeTab('下書き')}>
            下書き
          </Tab>
        </StyledTabContainer>
        {currentUser.isCompanyUser && !isDraft && !isScheduled && (
          <CSVDownloadButtonWrapper>
            <Button priority={'low'} onClick={() => setIsOpenDownloadModal(true)}>
              CSVダウンロード
            </Button>
          </CSVDownloadButtonWrapper>
        )}
        <FlexWrapper>
          <CardWrapper>
            {!isLoadingPromotionList && promotionList.items.isEmpty() && <NoResults>投稿がありません</NoResults>}
            {promotionList.items.map((listPromotion, index) => (
              <PromotionInfoCard
                key={index}
                listPromotion={listPromotion}
                showEditMenu={showEditMenu(currentUser, listPromotion)}
                stores={stores}
              />
            ))}
            {isLoadingPromotionList && (
              <LoadingWrapper>
                <LoadingWrapperBase />
                <Loader active={true} size={'big'} inline={true} />
              </LoadingWrapper>
            )}
          </CardWrapper>
        </FlexWrapper>
        {!promotionList.items.isEmpty() && (
          <PagingWrapper>
            <Paging
              currentPage={committedSearchCondition.pagination.page}
              viewContentSize={committedSearchCondition.pagination.per_page}
              totalContentSize={promotionList.pagination.total_count}
              viewMaxPage={5}
              fixedPage={1}
              onChangeNo={changePage}
            />
          </PagingWrapper>
        )}
      </Body>
      <PromotionInfoDownloadModal
        downloadFilter={downloadFilter}
        open={isOpenDownloadModal}
        onClose={() => setIsOpenDownloadModal(false)}
      />
    </StyledMainWrapper>
  );
};

const StyledMainWrapper = styled(MainWrapper)`
  padding-bottom: 112px;
`;

const PromotionFilterWrapper = styled.div`
  margin: 20px 0;
`;

const SearchWrapper = styled.div`
  max-width: 770px;

  @media (max-width: 860px) {
    /* 600 ~ 860px の間だけ右側の余白がないのを調整 */
    margin-right: 16px;
  }

  @media (max-width: 600px) {
    margin-right: 0;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;

  @media (max-width: 860px) {
    /* 600 ~ 860px の間だけ右側の余白がないのを調整 */
    margin-right: 16px;
  }

  @media (max-width: 600px) {
    margin-right: 0;
  }
`;

const CardWrapper = styled.div`
  width: 770px;
  position: relative;
`;

const NoResults = styled.div`
  text-align: center;
  width: 100%;
  font-size: 16px;
`;

const PagingWrapper = styled.div`
  margin-top: 30px;
  max-width: 770px;
`;

const CSVDownloadButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 200px;
  padding-bottom: 200px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const LoadingWrapperBase = styled(LoadingWrapper)`
  background-color: ${COLOR.BACKGROUND};
  mix-blend-mode: hard-light;
  z-index: 1; /** カルーセルの矢印より上に表示されるようにする */
`;

const StyledTabContainer = styled(TabContainer)`
  z-index: 2; /** カルーセルより上、日付選択より下に表示されるようにする */
`;
