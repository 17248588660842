import { List, Record } from 'immutable';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { Accounts } from 'models/Domain/Abc/Account';
import { JSObject } from 'types/Common';

import { AbcActions } from './actions';

export interface StateRecord {
  accounts: Accounts;
  importedLocationIds: List<string>; // 取り込み済みのロケーションIDのList
}

export class AbcState extends Record<StateRecord>({
  accounts: new Accounts(),
  importedLocationIds: List<string>(),
}) {
  /** STORECASTに取り込んでいないロケーション */
  get notImportedLocations() {
    return this.accounts.locations.filter((location) => !this.importedLocationIds.includes(location.id));
  }
}

export const abcReducer = reducerWithInitialState(new AbcState()).case(
  AbcActions.setAccounts,
  (state, data: JSObject[]) => {
    const accounts = Accounts.fromJson(data);
    return state.set('accounts', accounts);
  },
);
