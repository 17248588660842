import React from 'react';

import { Route, Switch } from 'react-router-dom';

import { Redirect } from 'components/atoms/Link';
import { AccountEdit } from 'components/pages/AccountEdit';
import { AlbumImage } from 'components/pages/AlbumImage';
import { AlbumImageCreate } from 'components/pages/AlbumImageCreate';
import { AlbumListIndex } from 'components/pages/AlbumListIndex';
import { BulkEditStores } from 'components/pages/BulkEditStores';
import { CompetitorDetail } from 'components/pages/CompetitorDetail';
import { CompetitorIndex } from 'components/pages/CompetitorIndex';
import { CompetitorRegister } from 'components/pages/CompetitorRegister';
import { GbpChecklist } from 'components/pages/GbpChecklist';
import { GbpPerformance } from 'components/pages/GbpPerformance';
import { GbpPerformanceMA } from 'components/pages/GbpPerformanceMA';
import { GmbImportStores } from 'components/pages/GmbImportStores';
import { GmbInsight } from 'components/pages/GmbInsight';
import { GmbInsightStore } from 'components/pages/GmbInsightStore';
import { GmbLocationUpdates } from 'components/pages/GmbLocationUpdates';
import { GmbReview } from 'components/pages/GmbReview';
import { GmbReviewDetail } from 'components/pages/GmbReviewDetail';
import { InstagramAccountEdit } from 'components/pages/InstagramAccountEdit';
import { InstagramAccountRegister } from 'components/pages/InstagramAccountRegister';
import { InstagramIndex } from 'components/pages/InstagramIndex';
import { InventoryImport } from 'components/pages/InventoryImport';
import { InventoryList } from 'components/pages/InventoryList';
import { InventoryStoreList } from 'components/pages/InventoryStoreList';
import { MapCompetitorResearchIndex } from 'components/pages/MapCompetitorResearchIndex';
import { MapCompetitorResearchResult } from 'components/pages/MapCompetitorResearchResult';
import { MapSearchRank } from 'components/pages/MapSearchRank';
import { MapSearchRankConfig } from 'components/pages/MapSearchRankConfig';
import { MapSearchRankConfigImport } from 'components/pages/MapSearchRankConfigImport';
import { Memo } from 'components/pages/Memo';
import { MenuCreate } from 'components/pages/MenuCreate';
import { MenuEdit } from 'components/pages/MenuEdit';
import { MenuIndex } from 'components/pages/MenuIndex';
import { OfferActivity } from 'components/pages/OfferActivity';
import { OfferCreate } from 'components/pages/OfferCreate';
import { OfferDetail } from 'components/pages/OfferDetail';
import { OfferEdit } from 'components/pages/OfferEdit';
import { OfferGroups } from 'components/pages/OfferGroups';
import { OmoInsight } from 'components/pages/OmoInsight';
import { Organization } from 'components/pages/Organization';
import { PromotionCreate } from 'components/pages/PromotionCreate';
import { PromotionDetail } from 'components/pages/PromotionDetail';
import { PromotionEdit } from 'components/pages/PromotionEdit';
import { PromotionIndex } from 'components/pages/PromotionIndex';
import { SearchKeywords } from 'components/pages/SearchKeywords';
import { SearchVolumeIndex } from 'components/pages/SearchVolumeIndex';
import { SearchVolumeResult } from 'components/pages/SearchVolumeResult';
import { ServiceCreate } from 'components/pages/ServiceCreate';
import { ServiceEdit } from 'components/pages/ServiceEdit';
import { ServiceIndex } from 'components/pages/ServiceIndex';
import { Store } from 'components/pages/Store';
import { StoreAccounts } from 'components/pages/StoreAccounts';
import { StoreImport } from 'components/pages/StoreImport';
import { StoreListCreate } from 'components/pages/StoreListCreate';
import { StoreListEdit } from 'components/pages/StoreListEdit';
import { StoreListIndex } from 'components/pages/StoreListIndex';
import { StoreRegister } from 'components/pages/StoreRegister';
import { TaskDetail } from 'components/pages/TaskDetail';
import { Top } from 'components/pages/Top';
import { UserEdit } from 'components/pages/UserEdit';
import { UserIndex } from 'components/pages/UserIndex';
import { UserRegister } from 'components/pages/UserRegister';
import { App, UnLoginWrapper } from 'components/templates/App';
import { LoginOnly } from 'components/templates/LoginOnly';
import { AuthRoute } from 'components/templates/LoginOnly/AuthRoute';
import { OrganizationFeature } from 'models/Domain/Organization';
import { UserRole } from 'models/Domain/User';

const albumPath = '/album';
const storePath = '/store';
const groupsPath = '/groups';
const userPath = '/user';
const gmbPath = '/gmb';
const gbpPath = '/gbp';
const accountPath = '/account';
const taskPath = '/task';
const offerPath = '/offer';
const offerGroupsPath = '/offergroups';
const promotionInfoPath = '/promotion_info';
const localpostPath = '/localpost';
const omoPath = '/omo';
const searchKeywordsPath = '/search_keywords';
const mapSearchRankPath = '/map_search_rank';
const mapCompetitorResearchPath = '/tools/serps';
const competitorsPath = '/competitors';
const gbpChecklistPath = '/tools/gbp/checklist';
const organizationPath = '/organization';
const searchVolumePath = '/tools/search_volume';
const instagramPath = '/instagram';
const memoPath = '/memo';
const menuPath = '/menu';
const servicePath = '/service';

export const Path = {
  top: '/',
  album: {
    index: albumPath,
    create: `${albumPath}/image/create`,
    image: `${albumPath}/image/:imageId`,
  },
  store: {
    index: storePath,
    register: `${storePath}/register`,
    detail: `${storePath}/:storeId`,
    bulkEdit: `${storePath}/business_info/edit`,
    import: `${storePath}/import`,
  },
  storeList: {
    index: groupsPath,
    create: `${groupsPath}/create`,
    edit: `${groupsPath}/:storeListId`,
  },
  storeAccounts: {
    index: '/store_accounts',
  },
  user: {
    index: userPath,
    register: `${userPath}/register`,
    edit: `${userPath}/:userId`,
  },
  gmb: {
    review: `${gmbPath}/review`,
    reviewDetail: `${gmbPath}/review/:reviewId`,
    insight: `${gmbPath}/insight`,
    insightStore: `${gmbPath}/insight/store`,
    importStoresIndex: `${gmbPath}/import/stores`,
    importStores: `${gmbPath}/import/stores/:accountId`,
    locationUpdates: `${gmbPath}/location/updates`,
  },
  gbp: {
    index: gbpPath,
    performance: `${gbpPath}/performance`,
    performanceMA: `${gbpPath}/performance/ma`,
  },
  account: {
    index: accountPath,
    edit: `${accountPath}/edit`,
  },
  task: {
    index: taskPath,
    detail: `${taskPath}/:taskId(\\d+)`,
  },
  offer: {
    activity: `${offerPath}/activity`,
    create: `${offerPath}/create/`,
    detail: `${offerPath}/:offerId`,
    edit: `${offerPath}/:offerId/edit`,
    comment: `${offerPath}/:offerId/comment`,
  },
  offerGroups: {
    index: offerGroupsPath,
  },
  DEPRECATED_promotionInfo: {
    index: promotionInfoPath,
    detail: `${promotionInfoPath}/:id`,
    create: {
      new: `${promotionInfoPath}/create/new`,
      covid19: `${promotionInfoPath}/create/covid19`,
    },
    edit: {
      new: `${promotionInfoPath}/edit/new/:id`,
      covid19: `${promotionInfoPath}/edit/covid19/:id`,
    },
  },
  localpost: {
    index: localpostPath,
    create: `${localpostPath}/create`,
    detail: `${localpostPath}/:id`,
    edit: `${localpostPath}/:id/edit`,
  },
  omo: {
    index: omoPath,
    insight: `${omoPath}/insight`,
    inventory: `${omoPath}/inventories`,
    inventoryStoreList: `${omoPath}/inventories/stores`,
    inventoryList: `${omoPath}/inventories/stores/:storeId`,
    inventoryImport: `${omoPath}/inventories/import/`,
    inventoryStoreImport: `${omoPath}/inventories/import/:storeId`,
  },
  searchKeywords: {
    index: searchKeywordsPath,
  },
  mapSearchRank: {
    index: mapSearchRankPath,
    config: `${mapSearchRankPath}/config`,
    import: `${mapSearchRankPath}/import`,
  },
  mapCompetitorResearch: {
    index: mapCompetitorResearchPath,
    result: `${mapCompetitorResearchPath}/:id`,
  },
  gbpChecklist: {
    index: gbpChecklistPath,
  },
  competitors: {
    index: competitorsPath,
    detail: `${competitorsPath}/:id`,
    register: `${competitorsPath}/register`,
  },
  organization: {
    index: organizationPath,
  },
  searchVolume: {
    index: searchVolumePath,
    result: `${searchVolumePath}/:id`,
  },
  instagram: {
    index: `${instagramPath}`,
    edit: `${instagramPath}/:id/edit`,
    register: `${instagramPath}/register`,
  },
  memo: {
    index: `${memoPath}`,
  },
  menu: {
    index: `${menuPath}`,
    create: `${menuPath}/create`,
    edit: `${menuPath}/:id`,
  },
  service: {
    index: `${servicePath}`,
    create: `${servicePath}/create`,
    edit: `${servicePath}/:id`,
  },
};

// 店舗をCSVでまとめて更新画面を表示できるロール
const storeImportRoles = [UserRole.Admin, UserRole.Vmd, UserRole.Sv];
// グループ画面を表示できるロール
const groupRoles = [UserRole.Admin, UserRole.Vmd, UserRole.Sv];
// スタッフ画面を表示できるロール
const staffRoles = [UserRole.Admin, UserRole.Vmd, UserRole.Sv];
// Googleアカウント画面を表示できるロール
const gmbIndexRoles = [UserRole.Admin, UserRole.Vmd, UserRole.Sv];
// 組織画面を表示できるロール
const organizationRoles = [UserRole.Admin];
// 検索ボリューム画面を表示できるロール
const searchVolumeRoles = [UserRole.Admin];
// OMOインサイト画面を表示できるロール
const omoInsightRoles = [UserRole.Admin, UserRole.Vmd];
// Instagram連携画面を表示できるロール
const instagramRoles = [UserRole.Admin, UserRole.Vmd];
// OMOインサイトを利用可能な組織の権限
const omoFeatures: OrganizationFeature[] = ['omo'];
// 店頭在庫機能が利用可能な組織の権限
const localInventoriesFeatures: OrganizationFeature[] = ['local_inventory', 'local_inventory:page'];
// 検索キーワード分析画面を利用可能な組織の権限
const searchKeywordsFeatures: OrganizationFeature[] = ['search_keywords'];
// 検索順位監視画面を利用可能な組織の権限
const mapSearchRankFeatures: OrganizationFeature[] = ['map_search_rank'];
// 検索順位調査画面を利用可能な組織の権限
const mapCompetitorResearchFeatures: OrganizationFeature[] = ['map_competitor_research'];
// Instagram連携機能を利用可能な組織の権限
const instagramFeatures: OrganizationFeature[] = ['instagram'];
// メニュー機能を利用可能な組織の権限
const menuFeatures: OrganizationFeature[] = ['menu'];
// サービス機能を利用可能な組織の権限
const serviceFeatures: OrganizationFeature[] = ['service'];

const multipleAccountPathList = [Path.gbp.performanceMA];
/**
 * アカウント横断のページかを判定する
 */
export const isMultipleAccountPage = (path: string) => {
  return multipleAccountPathList.find((multipleAccountPath) => path.startsWith(multipleAccountPath)) !== undefined;
};

const routes = (
  <App>
    <LoginOnly container={UnLoginWrapper}>
      <Switch>
        <Route exact path={Path.top} component={Top} />
        <Route exact path={Path.account.edit} component={AccountEdit} />
        <Route exact path={Path.album.index} component={AlbumListIndex} />
        <Route exact path={Path.album.create} component={AlbumImageCreate} />
        <Route exact path={Path.album.image} component={AlbumImage} />
        <AuthRoute exact path={Path.store.import} component={StoreImport} roles={storeImportRoles} />
        <Route exact path={Path.store.index} component={Store} />
        <Route exact path={Path.store.register} component={StoreRegister} />
        <Route exact path={Path.store.detail} component={Store} />
        <Route exact path={Path.store.bulkEdit} component={BulkEditStores} />
        <AuthRoute exact path={Path.storeList.index} component={StoreListIndex} roles={groupRoles} />
        <AuthRoute exact path={Path.storeList.create} component={StoreListCreate} roles={groupRoles} />
        <AuthRoute exact path={Path.storeList.edit} component={StoreListEdit} roles={groupRoles} />
        <AuthRoute exact path={Path.user.index} component={UserIndex} roles={staffRoles} />
        <AuthRoute exact path={Path.user.register} component={UserRegister} roles={staffRoles} />
        <AuthRoute exact path={Path.user.edit} component={UserEdit} roles={staffRoles} />
        <AuthRoute exact path={Path.storeAccounts.index} component={StoreAccounts} roles={gmbIndexRoles} />
        <Route exact path={Path.gmb.review} component={GmbReview} />
        <Route exact path={Path.gmb.reviewDetail} component={GmbReviewDetail} />
        <Route exact path={Path.gmb.insight} component={GmbInsight} />
        <Route exact path={Path.gbp.performance} component={GbpPerformance} />
        <Route exact path={Path.gbp.performanceMA} component={GbpPerformanceMA} />
        <Route exact path={Path.gmb.insightStore} component={GmbInsightStore} />
        <Route exact path={Path.gmb.importStores} component={GmbImportStores} />
        <Route exact path={Path.gmb.locationUpdates} component={GmbLocationUpdates} />
        <Route exact path={Path.offer.activity} component={OfferActivity} />
        <Route exact path={Path.offer.create} component={OfferCreate} />
        <Route exact path={Path.task.detail} component={TaskDetail} />
        <AuthRoute
          exact
          path={Path.omo.insight}
          component={OmoInsight}
          roles={omoInsightRoles}
          features={omoFeatures}
        />
        <AuthRoute
          exact
          path={Path.omo.inventoryStoreList}
          component={InventoryStoreList}
          features={localInventoriesFeatures}
        />
        <AuthRoute exact path={Path.omo.inventoryList} component={InventoryList} features={localInventoriesFeatures} />
        <AuthRoute
          exact
          path={Path.omo.inventoryImport}
          component={InventoryImport}
          features={localInventoriesFeatures}
        />
        <AuthRoute
          exact
          path={Path.omo.inventoryStoreImport}
          component={InventoryImport}
          features={localInventoriesFeatures}
        />
        <AuthRoute
          exact
          path={Path.searchKeywords.index}
          component={SearchKeywords}
          features={searchKeywordsFeatures}
        />
        <AuthRoute exact path={Path.mapSearchRank.index} component={MapSearchRank} features={mapSearchRankFeatures} />
        <AuthRoute
          exact
          path={Path.mapSearchRank.config}
          component={MapSearchRankConfig}
          features={mapSearchRankFeatures}
          roles={[UserRole.Admin, UserRole.Vmd]}
        />
        <AuthRoute
          exact
          path={Path.mapSearchRank.import}
          component={MapSearchRankConfigImport}
          features={mapSearchRankFeatures}
          roles={[UserRole.Admin, UserRole.Vmd]}
        />
        <AuthRoute
          exact
          path={Path.mapCompetitorResearch.index}
          component={MapCompetitorResearchIndex}
          features={mapCompetitorResearchFeatures}
        />
        <AuthRoute
          exact
          path={Path.mapCompetitorResearch.result}
          component={MapCompetitorResearchResult}
          features={mapCompetitorResearchFeatures}
        />
        <AuthRoute exact path={Path.searchVolume.index} component={SearchVolumeIndex} roles={searchVolumeRoles} />
        <AuthRoute exact path={Path.searchVolume.result} component={SearchVolumeResult} roles={searchVolumeRoles} />
        <AuthRoute exact path={Path.organization.index} component={Organization} roles={organizationRoles} />
        <Route path={Path.offer.edit} component={OfferEdit} />
        <Route path={Path.offer.detail} component={OfferDetail} />
        <Route path={Path.localpost.create} component={PromotionCreate} />
        <Route path={Path.localpost.edit} component={PromotionEdit} />
        <Route path={Path.localpost.detail} component={PromotionDetail} />
        <Route path={Path.localpost.index} component={PromotionIndex} />
        <Route path={Path.offerGroups.index} component={OfferGroups} />
        <Route exact path={Path.competitors.index} component={CompetitorIndex} />
        <Route exact path={Path.competitors.register} component={CompetitorRegister} />
        <Route exact path={Path.competitors.detail} component={CompetitorDetail} />
        <Route exact path={Path.gbpChecklist.index} component={GbpChecklist} />
        <AuthRoute
          exact
          path={Path.instagram.index}
          component={InstagramIndex}
          features={instagramFeatures}
          roles={instagramRoles}
        />
        <AuthRoute
          exact
          path={Path.instagram.edit}
          component={InstagramAccountEdit}
          features={instagramFeatures}
          roles={instagramRoles}
        />
        <AuthRoute
          exact
          path={Path.instagram.register}
          component={InstagramAccountRegister}
          features={instagramFeatures}
          roles={instagramRoles}
        />
        <AuthRoute exact path={Path.menu.index} component={MenuIndex} features={menuFeatures} />
        <AuthRoute exact path={Path.menu.create} component={MenuCreate} features={menuFeatures} />
        <AuthRoute exact path={Path.menu.edit} component={MenuEdit} features={menuFeatures} />
        <AuthRoute exact path={Path.service.index} component={ServiceIndex} features={serviceFeatures} />
        <AuthRoute exact path={Path.service.create} component={ServiceCreate} features={serviceFeatures} />
        <AuthRoute exact path={Path.service.edit} component={ServiceEdit} features={serviceFeatures} />
        <Route exact path={Path.memo.index} component={Memo} />
        <Redirect from={Path.DEPRECATED_promotionInfo.edit.new} to={Path.localpost.edit} />
        <Redirect from={Path.DEPRECATED_promotionInfo.edit.covid19} to={Path.localpost.edit} />
        <Redirect from={Path.DEPRECATED_promotionInfo.detail} to={Path.localpost.detail} />
        <Redirect from={Path.DEPRECATED_promotionInfo.create.new} to={Path.localpost.create} />
        <Redirect from={Path.DEPRECATED_promotionInfo.create.covid19} to={Path.localpost.create} />
        <Redirect from={Path.DEPRECATED_promotionInfo.index} to={Path.localpost.index} />
        <Redirect to={Path.top} />
      </Switch>
    </LoginOnly>
  </App>
);

export default routes;
