import { Record } from 'immutable';

import { JSObject } from 'types/Common';

export interface YahooPlaceAccountRecord {
  id: number;
  name: string;
  email: string;
}

export default class YahooPlaceAccount extends Record<YahooPlaceAccountRecord>({
  id: 0,
  name: '',
  email: '',
}) {
  static fromJson(data: JSObject = {}): YahooPlaceAccount {
    return new YahooPlaceAccount({ id: data.id, name: data.name, email: data.email });
  }
}
