import { Record } from 'immutable';

import { JSObject } from 'types/Common';

interface YahooPlaceStateRecord {
  yahoo_place_is_suspended: boolean;
}

/**
 * Yahooプレイスの状態を保持
 * フロントから値を変更することはない
 */
export class YahooPlaceState extends Record<YahooPlaceStateRecord>({
  yahoo_place_is_suspended: false,
}) {
  static fromJSON(data: JSObject = {}) {
    const params = { ...data };
    return new YahooPlaceState(params);
  }

  get isError() {
    return this.yahoo_place_is_suspended;
  }

  get errorLabels() {
    const result = [];
    if (this.yahoo_place_is_suspended) {
      result.push('停止中である');
    }
    return result;
  }
}
