import React from 'react';

import { List } from 'immutable';
import { useDispatch } from 'react-redux';
import { Dropdown, Table } from 'semantic-ui-react';
import styled from 'styled-components';

import { AppActions } from 'modules/app/actions';
import { Path } from 'routes';
import { COLOR } from 'style/color';

/** 単一の店舗連携アカウント */
type Account = {
  id: number | null;
  name: string;
  email: string;
};

export interface Props {
  showImportButton?: boolean;
  accounts: List<Account>;
  onAuthorize: (id: number) => void;
  onClickRemoveAccount: (id: number) => void;
}

const AccountListContent: React.FC<Props> = ({
  showImportButton,
  accounts: accountList,
  onAuthorize,
  onClickRemoveAccount,
}) => {
  const dispatch = useDispatch();

  if (accountList.size === 0) {
    return null;
  }

  return (
    <Wrapper>
      <GmbTable verticalAlign='middle'>
        <Table.Body>
          {accountList.map((account, index) => {
            const { id, name, email } = account;
            if (id === null) {
              return;
            }

            return (
              <Table.Row key={index}>
                <Table.Cell>
                  <CellContent>
                    <Name>{name}</Name>
                    <div>{email}</div>
                    <div>
                      <Dropdown trigger={<DropDownTriggerContent>•••</DropDownTriggerContent>} icon={null}>
                        <Dropdown.Menu direction='left'>
                          {showImportButton && (
                            <ActionMenuItem
                              onClick={() =>
                                dispatch(
                                  AppActions.moveTo(Path.gmb.importStores.replace(':accountId', String(account.id))),
                                )
                              }
                            >
                              <ActionItemLabel warning={false}>店舗を取り込む</ActionItemLabel>
                            </ActionMenuItem>
                          )}
                          <ActionMenuItem onClick={() => onAuthorize(id)}>
                            <ActionItemLabel warning={false}>再認証する</ActionItemLabel>
                          </ActionMenuItem>
                          <ActionMenuItem onClick={() => onClickRemoveAccount(id)}>
                            <ActionItemLabel warning={true}>連携を解除する</ActionItemLabel>
                          </ActionMenuItem>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </CellContent>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </GmbTable>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const GmbTable = styled(Table)`
  &&& {
    margin-top: 20px;
  }
`;

const CellContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Name = styled.div`
  margin-left: 10px;
`;

const DropDownTriggerContent = styled.div`
  color: ${COLOR.GRAY};
  padding: 0 8px;
  font-size: 16px;
  font-weight: bold;
`;

const ActionMenuItem = styled(Dropdown.Item)`
  &&& {
    border-top: 1px solid ${COLOR.LIGHT_GRAY} !important;
    &:first-of-type {
      border-top: none !important;
    }
  }
`;

const ActionItemLabel = styled.div<{ warning: boolean }>`
  padding: 10px;
  cursor: pointer;
  color: ${(props) => (props.warning ? COLOR.RED : COLOR.BLACK)};
`;

export default AccountListContent;
