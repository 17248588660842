import React from 'react';

import { List } from 'immutable';
import { useSelector } from 'react-redux';

import { Alert } from 'components/atoms/Alert';
import { DetailLink } from 'components/atoms/DetailLink';
import { Link } from 'components/atoms/Link';
import { GmbLocationDiff } from 'models/Domain/GmbLocationDiffs';
import { Path } from 'routes';

const DIFF_DISPLAY_MAX_COUNT = 2;

/** サービスに属する店舗にGoogleビジネスプロフィールとの差分があることを示し、ユーザに確認を促す */
export const GbpServiceDiffsAlert: React.FC<{ serviceDiffs: List<GmbLocationDiff> }> = ({ serviceDiffs }) => {
  const { stores } = useSelector((state) => state.store);

  // 最大2件まで差分の店舗名を表示
  const diffStoreIds = serviceDiffs.take(DIFF_DISPLAY_MAX_COUNT).map((diff) => diff.store_id);
  const diffStoreNames = diffStoreIds.map((id) => stores.findStore(id)?.name).filter((v) => v !== undefined);
  const diffMessageSuffix =
    serviceDiffs.size > DIFF_DISPLAY_MAX_COUNT ? ` 他 ${serviceDiffs.size - DIFF_DISPLAY_MAX_COUNT}店舗` : '';

  return (
    <Alert type='caution'>
      <Alert.Title>GBPとサービス情報の差分が発生している店舗があります</Alert.Title>
      <Alert.Section>
        <Alert.Header>
          対象店舗: {serviceDiffs.size}店舗 ({diffStoreNames.join('、')}
          {diffMessageSuffix})
        </Alert.Header>
        <Alert.Header>
          <Link to={Path.gmb.locationUpdates}>
            <DetailLink>GBPとの差分画面</DetailLink>
          </Link>
          から変更の要否を確認してください。
        </Alert.Header>
      </Alert.Section>
    </Alert>
  );
};
