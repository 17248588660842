import ApiClient from 'ApiClient';
import { JSObject } from 'types/Common';
import { GetPlacesResponse } from 'types/responses/YahooPlace';
import { GetAccountsResponse, YahooPlaceAccountPostResponse } from 'types/responses/YahooPlaceAccount';
import { YahooPlaceAuthPostResponse } from 'types/responses/YahooPlaceAuth';

const apiClient = new ApiClient({});

const YAHOO_PATH = '/yahoo';

const YAHOO_AUTH_URL = `${YAHOO_PATH}/auth`;
const YAHOO_ACCOUNT_URL = `${YAHOO_PATH}/accounts`;
const YAHOO_ACCOUNT_DETAIL_PATH = (account_id: number) => `${YAHOO_ACCOUNT_URL}/${String(account_id)}`;

const YAHOO_PLACES_URL = `${YAHOO_PATH}/places`;

export class YahooPlaceAuthApi {
  static post(params: JSObject) {
    return apiClient.post<YahooPlaceAuthPostResponse>(YAHOO_AUTH_URL, params);
  }
}

export class YahooPlaceAccountApi {
  static get() {
    return apiClient.get<GetAccountsResponse>(YAHOO_ACCOUNT_URL);
  }
  static post(params: JSObject) {
    return apiClient.post<YahooPlaceAccountPostResponse>(YAHOO_ACCOUNT_URL, params);
  }
  static delete(accountId: number) {
    return apiClient.delete(YAHOO_ACCOUNT_DETAIL_PATH(accountId));
  }
}

export class YahooPlaceApi {
  static get() {
    return apiClient.get<GetPlacesResponse>(YAHOO_PLACES_URL);
  }
}
