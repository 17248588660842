import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Helmet } from 'react-helmet-async';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { ExternalLink } from 'components/atoms/ExternalLink';
import { Icon } from 'components/atoms/Icon';
import { Link } from 'components/atoms/Link';
import { Loader } from 'components/atoms/Loader';
import { StickyHeader } from 'components/atoms/StickyHeader';
import { SegmentUpload } from 'components/molecules/FileUploader';
import {
  StepManual,
  StepManualGuideLink,
  StepManualHeader,
  StepManualMain,
  StepManualTitle,
} from 'components/organisms/StepManual';
import { Body, MainWrapper } from 'components/templates/MainWrapper';
import { getPageTitle } from 'helpers/utils';
import { State } from 'modules/reducers';
import { StoresImportActions } from 'modules/storesImport/actions';
import { Path } from 'routes';
import { COLOR } from 'style/color';

const GUIDE_URL = 'https://www.notion.so/pathee/CSV-14e98edd5f8f4f8aa81b4d0b20450ac6';

export const StoreImport: React.FC = () => {
  const dispatch = useDispatch();

  const { storesImportValidate, importStores, checkCsvImportStatus, clearStatus, downloadStoresCsv } = useMemo(
    () => bindActionCreators(StoresImportActions, dispatch),
    [dispatch],
  );

  const {
    isValidateError,
    isValidateWarning,
    validateWarnings,
    validateErrors,
    isImportError,
    hasImportFailedStore,
    importFailedStoreIds,
    isProcessingValidate,
    isProcessingImport,
    isAcceptedImport,
    totalSucceed,
    totalWarning,
    totalFailed,
  } = useSelector((state: State) => state.storesImport, shallowEqual);

  useEffect(() => {
    if (isAcceptedImport) {
      const intervalId = setInterval(() => {
        checkCsvImportStatus();
      }, 3000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [checkCsvImportStatus, isAcceptedImport]);

  useEffect(() => {
    // ページを離れる時にステータスをクリアする
    return () => {
      clearStatus();
    };
  }, [clearStatus]);

  const [file, setFile] = useState<File>();

  const onValidate = useCallback(
    (targetFile: File) => {
      setFile(targetFile);
      storesImportValidate(targetFile);
    },
    [storesImportValidate],
  );

  const onCsvDownload = useCallback(() => {
    downloadStoresCsv();
  }, [downloadStoresCsv]);

  const onDelete = useCallback(() => {
    setFile(undefined);
    clearStatus();
  }, [clearStatus]);

  const onImportStores = useCallback(() => {
    if (!file) {
      return;
    }
    importStores(file);
  }, [file, importStores]);

  const isProcessing = isProcessingValidate || isProcessingImport;

  // 検証結果のエラー数および警告数
  const validateErrorsCount = validateErrors?.errors?.length || 0;
  const validateWarningsCount = validateWarnings?.warnings?.length || 0;

  return (
    <>
      <Wrapper>
        <Helmet title={getPageTitle('CSVでまとめて更新')} />
        <StickyHeader title='CSVでまとめて更新' />
        <Body>
          {/* ステップマニュアル */}
          <StyledStepManual>
            <StepManualTitle>店舗情報をまとめて更新するには</StepManualTitle>
            <StepManualHeader step={1} title='テンプレートのダウンロード' />
            <StepManualMain>
              <DownloadLink onClick={onCsvDownload}>こちら</DownloadLink>
              をクリックしてCSVをダウンロードまたは、
              <Link to={Path.store.index}>
                <StoreLink>店舗管理ページ</StoreLink>
              </Link>
              で対象店舗を絞り込んでCSVをダウンロード
            </StepManualMain>
            <StepManualHeader step={2} title='CSVを編集' />
            <StepManualMain>CSVに記載されている対象の店舗情報を編集</StepManualMain>
            <StepManualHeader step={3} title='店舗情報を更新' />
            <StepManualMain>
              現在のページで編集したCSVをアップロード。もしエラー情報が表示された場合、CSVを再度編集してアップロード
            </StepManualMain>
            <StepManualGuideLink>
              詳しくは <ExternalLink href={GUIDE_URL}>STORECAST利用ガイド</ExternalLink> をご覧ください
            </StepManualGuideLink>
          </StyledStepManual>

          {/* ファイル読み込み */}
          {file ? (
            <>
              <MessageTitle>対象ファイル</MessageTitle>
              <FileText>
                <FileName>{file.name}</FileName>
                {!isProcessing && <CancelIcon onClick={onDelete} />}
              </FileText>
            </>
          ) : (
            <StyledSegmentUpload
              multiple={false}
              uploadType={'file'}
              accept={['text/csv', 'application/octet-stream', 'application/vnd.ms-excel']}
              iconName='paperclip'
              buttonText='CSVを追加'
              onDropAccepted={(files) => onValidate(files[0])}
              onDropRejected={(files) => {
                console.error(files);
              }}
            />
          )}

          {isProcessingImport && (
            <MessageWrapper>
              <ProcessingTitle>
                <Loader active={true} size='small' inline={true} />
                <ProcessingTitleLabel>店舗情報の更新を実行中...</ProcessingTitleLabel>
              </ProcessingTitle>

              {isAcceptedImport && (
                <Message>
                  CSVファイルでの店舗情報更新は正常に受け付けられました。
                  <br />
                  対象店舗数が多い場合、処理完了まで時間がかかる場合があります。
                  <br />
                  更新の完了時にメールで通知されます。(ブラウザを閉じても大丈夫です)
                </Message>
              )}
            </MessageWrapper>
          )}

          {isProcessingValidate && (
            <MessageWrapper>
              <ProcessingTitle>
                <Loader active={true} size='small' inline={true} />
                <ProcessingTitleLabel>CSVファイルを検査中...</ProcessingTitleLabel>
              </ProcessingTitle>
            </MessageWrapper>
          )}

          {(isValidateError || isValidateError === false) && (
            <ValidationCountWrapper>
              <ValidationCountTitle>検証結果</ValidationCountTitle>
              <ValidationCount>成功した店舗数：{totalSucceed}</ValidationCount>
              <ValidationCount>警告のある店舗数：{totalWarning}</ValidationCount>
              <ValidationCount>失敗した店舗数：{totalFailed}</ValidationCount>
            </ValidationCountWrapper>
          )}

          {isValidateError && validateErrors && (
            <MessageWrapper>
              <ErrorTitle>{validateErrors.message}</ErrorTitle>
              <Message>
                CSVファイルの検査の結果、
                {validateErrorsCount > 0 && `${validateErrorsCount}店舗で`}
                内容の不備が見つかりました。
                <br />
                CSVファイルを修正後、再度アップロードしてください。
                <br />
                詳しくは <ExternalLink href={GUIDE_URL}>こちら</ExternalLink> の利用ガイドをご覧ください。
              </Message>
              {validateErrors && validateErrors.errors && validateErrorsCount > 0 && (
                <Message>
                  <MessageTitle>修正が必要な項目</MessageTitle>
                  {validateErrors.errors.map((ve, idx) =>
                    ve.messages.map((message, idx2) => (
                      <Error key={`${idx}_${idx2}`}>・{`${ve.store_name}: ${message}`}</Error>
                    )),
                  )}
                </Message>
              )}
              {validateWarnings && validateWarningsCount > 0 && (
                <>
                  <WarningTitle>
                    <StyledIcon type='status_warning' />
                    注意
                  </WarningTitle>
                  {validateWarnings.warnings.map((ve, idx) =>
                    ve.messages.map((message, idx2) => (
                      <Error key={`${idx}_${idx2}`}>・{`${ve.store_name}: ${message}`}</Error>
                    )),
                  )}
                </>
              )}
            </MessageWrapper>
          )}
          {isValidateError === false && (
            <MessageWrapper>
              <MessageTitle>CSVファイルを使って店舗情報を更新できます</MessageTitle>
              <Message>
                CSVファイルの検査の結果、特に問題は見つかりませんでした。
                <br />
                「店舗情報を更新」ボタンをクリックして、店舗情報の更新を開始してください。
              </Message>
              {isValidateWarning === true && (
                <>
                  <WarningTitle>
                    <StyledIcon type='status_warning' />
                    注意
                  </WarningTitle>
                  {validateWarnings?.warnings?.map((ve, idx) =>
                    ve.messages.map((message, idx2) => (
                      <Error key={`${idx}_${idx2}`}>・{`${ve.store_name}: ${message}`}</Error>
                    )),
                  )}
                </>
              )}
            </MessageWrapper>
          )}
          {isImportError && (
            <MessageWrapper>
              <ErrorTitle>店舗情報の更新に失敗しました</ErrorTitle>
              <Message>繰り返し試しても反映されない場合、画面左下の「サポート」からお問い合わせください。</Message>
            </MessageWrapper>
          )}
          {hasImportFailedStore && (
            <MessageWrapper>
              <ErrorTitle>STORECASTへの反映に失敗した店舗があります</ErrorTitle>
              <Message>
                失敗店舗: {importFailedStoreIds.length}店舗
                <br />
                時間を置いてもう一度お試しください。
                <br />
                <br />
                繰り返し試しても反映されない場合、画面左下の「サポート」からお問い合わせください。
              </Message>
            </MessageWrapper>
          )}
          <ButtonsWrapper>
            <StyledButton onClick={onImportStores} priority='high' disabled={!file || isValidateError !== false}>
              店舗情報を更新
            </StyledButton>
          </ButtonsWrapper>
        </Body>
      </Wrapper>
    </>
  );
};

const Wrapper = styled(MainWrapper)`
  /* フッター表示用の調整 */
  min-height: 100%;
`;

const StyledSegmentUpload = styled(SegmentUpload)`
  width: 100%;
`;

const MessageWrapper = styled.div`
  margin-top: 16px;
`;

const MessageTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
`;

const WarningTitle = styled(MessageTitle)`
  color: ${COLOR.WARNING};
  font-size: 14px;
`;

const StyledIcon = styled(Icon)`
  padding: 0;
  width: 14px;
  height: 14px;
  margin: 0 4px -2px 0;
`;

const ErrorTitle = styled(MessageTitle)`
  color: ${COLOR.ERROR};
`;

const Message = styled.div`
  margin-bottom: 8px;
  font-size: 14px;
`;

const Error = styled.div`
  font-size: 14px;
`;

const ButtonsWrapper = styled.div`
  margin-top: 22px;
  width: 100%;
  display: flex;
  align-items: center;
`;

const StyledButton = styled(Button)`
  height: 54px;
  &&& {
    width: 100%;
  }
`;

const FileText = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 8px;
  border: 2px solid ${COLOR.GRAY};
`;

const FileName = styled.span`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const CancelIcon = styled(Icon).attrs({ type: 'cancel' })`
  margin-left: auto;
  cursor: pointer;
`;

const ProcessingTitle = styled(MessageTitle)`
  display: flex;
  align-items: center;
`;

const ProcessingTitleLabel = styled.div`
  margin-left: 8px;
  font-weight: bold;
`;

const StyledStepManual = styled(StepManual)`
  margin-bottom: 16px;
`;

const DownloadLink = styled.span`
  color: ${COLOR.GREEN};
  cursor: pointer;
`;

const StoreLink = styled.span`
  color: ${COLOR.GREEN};
  cursor: pointer;
  text-decoration: underline;
`;

const ValidationCountWrapper = styled.div``;

const ValidationCount = styled.div``;

const ValidationCountTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
`;
