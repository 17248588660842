import React from 'react';

import styled from 'styled-components';

import { ProgressCircle } from 'components/molecules/ProgressCircle';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from 'components/molecules/Table';

type StoreCheckItemKey = '店舗名' | '住所' | '電話番号' | '営業時間' | 'ウェブサイト' | '店舗の説明';

type Props = {
  // 設定済みの推奨項目の割合
  doneItemRate: number;
  // 推奨項目の設定状況
  storeCheckedItems: { name: StoreCheckItemKey; isDone: boolean }[];
};

/** 店舗詳細画面 店舗の推奨項目の設定状況の表示 */
export const StoreItemsCheck = React.memo<Props>(({ doneItemRate, storeCheckedItems }) => {
  return (
    <Wrapper>
      <Graph>
        <GraphTitle>推奨項目の設定率</GraphTitle>
        <GraphContent>
          <ProgressCircle value={doneItemRate} size={'200'} />
        </GraphContent>
      </Graph>
      <TableWrapper>
        <Table unstackable={true}>
          <TableHeader>
            <TableHeaderRow>
              <TableHeaderCell>項目</TableHeaderCell>
              <TableHeaderCell />
            </TableHeaderRow>
          </TableHeader>
          <TableBody>
            {storeCheckedItems.map((item, index) => (
              <TableRow key={index}>
                <ItemNameCell>{item.name}</ItemNameCell>
                <ItemValueCell>{item.isDone ? '○' : 'ー'}</ItemValueCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableWrapper>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  margin-bottom: 40px;
  display: flex;
`;

const Graph = styled.div`
  width: 55%;
  display: block;
`;

const GraphTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 25px;
`;

const GraphContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TableWrapper = styled.div`
  width: 45%;
`;

const ItemNameCell = styled(TableCell)`
  &&& {
    font-weight: bold;
    max-width: 50px;
    word-break: break-word;
  }
`;

const ItemValueCell = styled(TableCell)``;
