import React, { useEffect, useMemo, useState } from 'react';

import { List } from 'immutable';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { Link } from 'components/atoms/Link';
import { ServiceIcon } from 'components/atoms/ServiceIcon';
import { StickyHeader, Title } from 'components/atoms/StickyHeader';
import { ProgressCircle } from 'components/molecules/ProgressCircle';
import { GbpChecklistModal } from 'components/pageComponents/GbpChecklist/GbpChecklistModal';
import { GbpChecklistSearchCondition } from 'components/pageComponents/GbpChecklist/GbpChecklistSearchCondition';
import { GbpChecklistTable } from 'components/pageComponents/GbpChecklist/GbpChecklistTable';
import { MainWrapper, WideBody } from 'components/templates/MainWrapper';
import { createGBPPerformancePath } from 'helpers/path';
import { getPageTitle } from 'helpers/utils';
import { GbpChecklistItem } from 'models/Domain/GbpChecklist';
import { Organization } from 'models/Domain/Organization';
import { GbpChecklistActions } from 'modules/gbpChecklist/actions';
import { COLOR } from 'style/color';

type GbpChecklistTableData = React.ComponentProps<typeof GbpChecklistTable>['tableData'];

export const GbpChecklist = React.memo(() => {
  const [selectedItem, setSelectedItem] = useState<GbpChecklistItem | null>(null);

  const dispatch = useDispatch();
  const { isLoading, searchCondition, checklist } = useSelector((state) => state.gbpChecklist);
  const currentUser = useSelector((state) => state.app.currentUser);
  const organization = useSelector((state) => state.app.currentUser.organization) || new Organization();
  const { initializePage, commitSearchCondition } = useMemo(
    () => bindActionCreators(GbpChecklistActions, dispatch),
    [dispatch],
  );

  const { basicInformation, photo, localPost, review } = checklist.items;

  const storeInfoTableData: GbpChecklistTableData = useMemo(
    () =>
      List([
        {
          category: '店舗',
          title: '店舗名が設定されている',
          status: basicInformation.name,
          url: 'store' as const,
        },
        {
          category: '店舗',
          title: '住所が設定されている',
          status: basicInformation.address,
          url: 'store' as const,
        },
        {
          category: '店舗',
          title: '電話番号が設定されている',
          status: basicInformation.phone,
          url: 'store' as const,
        },
        {
          category: '店舗',
          title: '営業時間が設定されている',
          status: basicInformation.businessHour,
          url: 'store' as const,
        },
        {
          category: '店舗',
          title: 'ウェブサイトが設定されている',
          status: basicInformation.url,
          url: 'store' as const,
        },
        {
          category: '店舗',
          title: '店舗の説明が設定されている',
          status: basicInformation.description,
          url: 'store' as const,
        },
        {
          category: '写真',
          title: '店内カテゴリの写真が3枚以上設定されている',
          status: photo.interior,
          url: 'photoInterior' as const,
        },
        {
          category: '写真',
          title: '外観カテゴリの写真が3枚以上設定されている',
          status: photo.exterior,
          url: 'photoExterior' as const,
        },
      ]),
    [basicInformation, photo],
  );

  const operationTableData: GbpChecklistTableData = useMemo(
    () =>
      List([
        {
          category: '店舗',
          title: 'Googleの更新に対応した店舗数',
          helpText: 'Googleが更新した情報との差分に対応した店舗数',
          status: basicInformation.diffGbp,
          url: 'diff' as const,
        },
        {
          category: '写真',
          title: '写真を追加した店舗数',
          helpText: '対象期間にGBPに写真を追加した店舗数',
          status: photo.newPhoto,
          url: 'photo' as const,
        },
        {
          category: '投稿',
          title: '投稿した店舗数',
          helpText: '対象期間に投稿をした店舗数',
          status: localPost.newPost,
          url: 'localPost' as const,
        },
        {
          category: 'クチコミ',
          title: 'クチコミに対応した店舗数',
          helpText: `対象期間のクチコミに${organization.gbpCheckDaysOfReviewReplyDeadline}日以内に対応している店舗数`,
          status: review.replied,
          url: 'review' as const,
        },
      ]),
    [
      basicInformation.diffGbp,
      photo.newPhoto,
      localPost.newPost,
      organization.gbpCheckDaysOfReviewReplyDeadline,
      review.replied,
    ],
  );

  const periodText = useMemo(
    () => `${checklist.period.startDate.format('YYYY/MM/DD')}〜${checklist.period.endDate.format('YYYY/MM/DD')}`,
    [checklist.period],
  );

  const gbpPerformenceLinkPath = useMemo(
    () =>
      createGBPPerformancePath({
        storeIds: searchCondition.filter.storeIds.toArray(),
        startDate: checklist.period.startDate,
        endDate: checklist.period.endDate,
        displayType: 'activities',
      }),
    [checklist.period.endDate, checklist.period.startDate, searchCondition.filter.storeIds],
  );

  const canUseStoreConnectService = currentUser.organization
    ? currentUser.organization.canUseStoreConnectService()
    : false;

  useEffect(() => {
    initializePage();
  }, [initializePage]);

  return (
    <MainWrapper>
      <Helmet title={getPageTitle('GBP診断')} />
      <StickyHeader>
        <Title>GBP診断</Title>
        {canUseStoreConnectService && (
          <ServiceIconWrapper>
            <ServiceIcon.Google />
          </ServiceIconWrapper>
        )}
      </StickyHeader>
      <WideBody>
        <Wrapper>
          <GbpChecklistSearchCondition
            isLoading={isLoading}
            commitSearchCondition={commitSearchCondition}
            committedSearchCondition={searchCondition}
          />
          <ProgressContainer>
            <ProgressWrapper>
              <ProgressTitle>店舗情報の充足度</ProgressTitle>
              <ProgressCircle value={checklist.getStoreInfoProgress()} responsive={true} />
            </ProgressWrapper>
            <ProgressWrapper>
              <ProgressTitle>GBPの運用状況</ProgressTitle>
              <ProgressCircle value={checklist.getOperationProgress()} responsive={true} />
            </ProgressWrapper>
          </ProgressContainer>
          <TableWrapper>
            <TableTitle>店舗情報</TableTitle>
            <GbpChecklistTable isLoading={isLoading} tableData={storeInfoTableData} onSelectItem={setSelectedItem} />
          </TableWrapper>
          <TableWrapper>
            <PeriodWrapper>
              <Period>{periodText}</Period>
              <PeriodLabel>の運用実績</PeriodLabel>
              {currentUser.canUseGbpPerformance && (
                <StyledLink to={gbpPerformenceLinkPath}>GBPパフォーマンスで確認する</StyledLink>
              )}
            </PeriodWrapper>
            <GbpChecklistTable isLoading={isLoading} tableData={operationTableData} onSelectItem={setSelectedItem} />
          </TableWrapper>
          {selectedItem && (
            <GbpChecklistModal isOpen={true} onClose={() => setSelectedItem(null)} item={selectedItem} />
          )}
        </Wrapper>
      </WideBody>
    </MainWrapper>
  );
});

const Wrapper = styled.div``;

const StyledLink = styled(Link)`
  color: ${COLOR.GREEN};
  font-weight: bold;
`;

const ProgressContainer = styled.div`
  background: white;
  display: flex;
  width: 100%;
  justify-content: space-between;
  max-height: 240px;
  margin-bottom: 32px;
  padding: 16px 16px 64px;
`;

const ProgressWrapper = styled.div`
  flex: 1;
`;

const ProgressTitle = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 16px;
  flex: 1;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 32px;
`;

const TableTitle = styled.div`
  font-weight: bold;
  font-size: 18px;
`;

const PeriodWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: baseline;
`;

const PeriodLabel = styled.span`
  font-weight: bold;
`;

const Period = styled.span`
  font-family: monospace;
  font-weight: bold;
`;

const ServiceIconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto 0 0;
  gap: 6px;
`;
