import { List, Record } from 'immutable';

import Business from 'models/Domain/Abc/Business';
import { JSObject } from 'types/Common';

export interface AccountsRecord {
  list: List<Account>;
}

export class Accounts extends Record<AccountsRecord>({
  list: List<Account>(),
}) {
  static fromJson(data: JSObject): Accounts {
    return new Accounts({
      list: List(data.map((account: JSObject) => Account.fromJson(account))),
    });
  }

  /** アカウントに紐づく全てのビジネス */
  get businesses() {
    return this.list.flatMap((account) => account.businesses);
  }

  /** アカウントに紐づく全てのロケーション */
  get locations() {
    return this.businesses.flatMap((business: Business) => business.locations);
  }

  get_location(location_id: string) {
    return this.locations.find((location) => location.id === location_id);
  }
}

export interface AccountRecord {
  id: number;
  name: string;
  businesses: List<Business>;
}

export default class Account extends Record<AccountRecord>({
  id: 0,
  name: '',
  businesses: List<Business>(),
}) {
  static fromJson(data: JSObject = {}): Account {
    const businesses = data.businesses
      ? (data.businesses = List(
          data.businesses.map((business: JSObject) => Business.fromJson({ ...business, accountId: data.id })),
        ))
      : List();
    return new Account({ id: data.id, name: data.name, businesses });
  }
}
