import React, { useCallback, useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';
import { Set as ImmutableSet } from 'immutable';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-semantic-toasts';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { mutate } from 'swr';
import useSWRImmutable from 'swr/immutable';

import { ExcludedKeywordsApi } from 'ApiClient/GmbApi/SearchKeywordApi';
import { Loader } from 'components/atoms/Loader';
import { ServiceIcon } from 'components/atoms/ServiceIcon';
import { StickyHeader, Title } from 'components/atoms/StickyHeader';
import { AnalysisMemoTable } from 'components/pageComponents/Analysis/AnalysisMemoTable';
import { ExcludedKeywordCreateModal } from 'components/pageComponents/SearchKeywords/ExcludedKeywordCreateModal';
import { ExcludedKeywordFooter } from 'components/pageComponents/SearchKeywords/ExcludedKeywordFooter';
import { ExcludedSearchKeywordsConfigModal } from 'components/pageComponents/SearchKeywords/ExcludedSearchKeywordsConfigModal';
import { SearchKeywordsDetailModal } from 'components/pageComponents/SearchKeywords/SearchKeywordsDetailModal';
import { SearchKeywordsGraph } from 'components/pageComponents/SearchKeywords/SearchKeywordsGraph';
import { SearchKeywordsSearchCondition } from 'components/pageComponents/SearchKeywords/SearchKeywordsSearchCondition';
import { SearchKeywordsTable } from 'components/pageComponents/SearchKeywords/SearchKeywordsTable';
import { MainWrapper, WideBody } from 'components/templates/MainWrapper';
import { getPageTitle } from 'helpers/utils';
import { AccountSingleton } from 'models/Domain/AccountList';
import { ExcludedKeyword, ExcludedKeywords } from 'models/Domain/SearchKeyword/ExcludedKeyword';
import {
  SearchKeywordGraphType,
  SearchKeywordSearchCondition,
  SortKey,
} from 'models/Domain/SearchKeyword/SearchKeywordSearchCondition';
import { SearchKeywordActions } from 'modules/SearchKeyword/action';

import { useAnalysisMemos, useMemoDisplaySettings } from '../../hooks/useAnalysisMemos';

const SEARCH_KEYWORDS_KEY = '/gmb/excluded_keywords';

/** APIから常に除外中のキーワードを取得する */
const fetchExcludedKeywords = async () => {
  const response = await ExcludedKeywordsApi.get();
  if (response.isSuccess) {
    return ExcludedKeywords.fromJSON(response.data);
  } else {
    toast({
      type: 'error',
      title: '除外キーワード設定が取得できませんでした',
      description: (response.error as any)?.detail?.error || '',
      time: 10000,
    });
    return new ExcludedKeywords();
  }
};

/**
 * 常に除外中のキーワードを取得・削除する用のuseSWRのラッパー
 * @returns ExcludedKeywords
 */
export const useExcludedKeywords = () => {
  // アカウントを切り替えたら再度APIが実行されるように、keyに現在のorganizationIdを含める
  const currentOrganizationId = AccountSingleton.getInstance().organizationId;
  return useSWRImmutable([SEARCH_KEYWORDS_KEY, currentOrganizationId], fetchExcludedKeywords);
};

/** 常に除外を適用するキーワードを追加する */
export const createExcludedKeyword = async (keyword: ExcludedKeyword) => {
  const response = await ExcludedKeywordsApi.post(keyword.keyword, keyword.exactMatch);
  const currentOrganizationId = AccountSingleton.getInstance().organizationId;
  if (response.isSuccess) {
    toast({ type: 'success', title: '除外キーワード設定を追加しました' });
    // 除外キーワードのリストを再取得
    mutate([SEARCH_KEYWORDS_KEY, currentOrganizationId]);
  } else {
    toast({
      type: 'error',
      title: '除外キーワード設定の追加に失敗しました',
      description: (response.error as any)?.detail?.error || '',
      time: 10000,
    });
  }
};

/** 常に除外中のキーワードを削除する */
export const deleteExcludedKeyword = async (id: number) => {
  const response = await ExcludedKeywordsApi.delete(id);
  const currentOrganizationId = AccountSingleton.getInstance().organizationId;
  if (response.isSuccess) {
    toast({ type: 'success', title: '除外キーワード設定を削除しました' });
    // 除外キーワードのリストを再取得
    mutate([SEARCH_KEYWORDS_KEY, currentOrganizationId]);
  } else {
    toast({
      type: 'error',
      title: '除外キーワード設定の削除に失敗しました',
      description: (response.error as any)?.detail?.error || '',
      time: 10000,
    });
  }
};

export const SearchKeywords = React.memo(() => {
  const { data: excludedKeywords = new ExcludedKeywords(), isValidating: isLoadingExcludedKeywordsList } =
    useExcludedKeywords();

  const dispatch = useDispatch();

  const [openExcludedSearchKeywordsConfig, setOpenExcludedSearchKeywordsConfig] = useState<boolean>(false);
  const [openExcludedKeywordCreateModal, setOpenExcludedKeywordCreateModal] = useState<boolean>(false);
  const [excludedKeywordForCreate, setExcludedKeywordForCreate] = useState(new ExcludedKeyword());
  const [isCreatingExcludedKeyword, setIsCreatingExcludedKeyword] = useState<boolean>(false);
  const [selectedKeyword, setSelectedKeyword] = useState<string>(''); // 選択中のキーワード
  const [isSelectedSummary, setIsSelectedSummary] = useState<boolean>(false); // 合計行が選択されているか
  const [isOpenDetailModal, setIsOpenDetailModal] = useState(false);

  const isLoadingExcludedKeyword = isLoadingExcludedKeywordsList || isCreatingExcludedKeyword;

  const { initializePage, setSearchCondition, startCsvDownload, checkCsvDownloadStatus, clearCsvDownload } = useMemo(
    () => bindActionCreators(SearchKeywordActions, dispatch),
    [dispatch],
  );
  const { committedSearchCondition, searchKeyword, isLoadingGraphData, isLoadingTableData, isProcessingCsvDownload } =
    useSelector((state) => state.searchKeyword);
  const { currentUser } = useSelector((state) => state.app);
  const { stores } = useSelector((state) => state.store);

  // 施策メモの表示設定
  const [memoDisplaySettings, setMemoDisplaySettings] = useMemoDisplaySettings('SEARCH_KEYWORDS');

  const { memoList, memoTagList, createMemo, updateMemo, deleteMemo, isLoadingMemoList, isLoadingMemoTagList } =
    useAnalysisMemos();

  // 選択中の施策メモ
  const [selectingMemoIds, setselectingMemoIds] = useState<ImmutableSet<number>>(ImmutableSet());

  const [isOpenExcludedKeywordsStickyFooter, setIsOpenExcludedKeywordsStickyFooter] = useState<boolean>(false);

  useEffect(() => {
    initializePage();
  }, [initializePage]);

  const commitSearchCondition = useCallback(
    (condition: SearchKeywordSearchCondition, pageReset = true) => {
      const newCondition = pageReset ? condition.setIn(['pagination', 'page'], 1) : condition;
      setSearchCondition({ searchCondition: newCondition, updateLocation: true });
    },
    [setSearchCondition],
  );

  const handleOnChangeGraphType = useCallback(
    (graphType: SearchKeywordGraphType) => {
      const newSearchCondition = committedSearchCondition.setIn(['filter', 'type'], graphType);
      commitSearchCondition(newSearchCondition, false);
    },
    [commitSearchCondition, committedSearchCondition],
  );

  const handleOnChangeSelectedMonth = useCallback(
    (month: string) => {
      const newSearchCondition = committedSearchCondition.setIn(['filter', 'targetMonth'], month);
      commitSearchCondition(newSearchCondition);
    },
    [commitSearchCondition, committedSearchCondition],
  );

  const handleOnChangeSort = useCallback(
    (sortKey: SortKey) => {
      const newSearchCondition = committedSearchCondition.update('sort', (sort) => sort.updateBySortKey(sortKey));
      commitSearchCondition(newSearchCondition);
    },
    [commitSearchCondition, committedSearchCondition],
  );

  const handleOnChangePage = useCallback(
    (page: number) => {
      const newSearchCondition = committedSearchCondition.setIn(['pagination', 'page'], page);
      commitSearchCondition(newSearchCondition, false);
    },
    [commitSearchCondition, committedSearchCondition],
  );

  /**
   * 常に適用する除外キーワード設定モーダルを開く
   */
  const handleOpenExcludedSearchKeywordsConfig = useCallback(() => {
    setOpenExcludedSearchKeywordsConfig(true);
  }, [setOpenExcludedSearchKeywordsConfig]);

  /**
   * 常に適用する除外キーワード設定モーダルを閉じる
   */
  const handleCloseExcludedSearchKeywordsConfig = useCallback(() => {
    setOpenExcludedSearchKeywordsConfig(false);
  }, [setOpenExcludedSearchKeywordsConfig]);

  // 新規作成する除外キーワードをバリデーションした結果のエラーメッセージ
  const errorMessageForExcludedKeywordCreate = useMemo(
    () => excludedKeywordForCreate.validate(excludedKeywords),
    [excludedKeywords, excludedKeywordForCreate],
  );

  // 除外キーワード新規作成モーダルを開く
  const handleOpenExcludedKeywordCreateModal = useCallback(
    (keyword: string) => {
      setExcludedKeywordForCreate(new ExcludedKeyword({ keyword: keyword, exactMatch: true }));
      setOpenExcludedKeywordCreateModal(true);
    },
    [setExcludedKeywordForCreate, setOpenExcludedKeywordCreateModal],
  );

  // 除外キーワード新規作成モーダルを閉じる
  const handleCloseExcludedKeywordCreateModal = useCallback(() => {
    setOpenExcludedKeywordCreateModal(false);
  }, [setOpenExcludedKeywordCreateModal]);

  // 常に適用する除外キーワードを新規作成する
  const handleCreateExcludedKeyword = useCallback(async () => {
    // 除外キーワード作成APIを実行
    setIsCreatingExcludedKeyword(true);
    await createExcludedKeyword(excludedKeywordForCreate);
    setIsCreatingExcludedKeyword(false);

    // 除外キーワード新規作成モーダルを閉じる
    setExcludedKeywordForCreate(new ExcludedKeyword());
    setOpenExcludedKeywordCreateModal(false);
    setIsOpenExcludedKeywordsStickyFooter(true);
  }, [excludedKeywordForCreate]);

  // 表のデータのうち、除外設定の対象のキーワード
  const currentExcludedKeywords = useMemo(
    () =>
      searchKeyword.tableData.items
        .filter((item) => excludedKeywords.match(item.keyword))
        .map((item) => item.keyword)
        .toSet(),
    [searchKeyword.tableData, excludedKeywords],
  );

  /** キーワードが選択された */
  const handleOnClickKeyword = useCallback(
    (keyword: string) => {
      setSelectedKeyword(keyword);
      setIsSelectedSummary(false);
      setIsOpenDetailModal(true);
    },
    [setSelectedKeyword, setIsSelectedSummary, setIsOpenDetailModal],
  );

  /** 合計行が選択された */
  const handleOnClickSummary = useCallback(() => {
    // 含むワードをカンマ区切りで格納
    const filterWords = committedSearchCondition.filter.filterWords.join();
    setSelectedKeyword(filterWords);
    setIsSelectedSummary(true);
    setIsOpenDetailModal(true);
  }, [committedSearchCondition, setSelectedKeyword, setIsSelectedSummary, setIsOpenDetailModal]);

  /** 店舗別流入数のモーダル上でCSVダウンロードが押された */
  const handleOnClickKeywordCsvDownload = useCallback(() => {
    // 単キーワードを表示中の場合はキーワードを絞る
    const filterWords = !isSelectedSummary
      ? ImmutableSet([selectedKeyword])
      : committedSearchCondition.filter.filterWords;
    // 単キーワードを表示中の場合は完全一致でマッチさせる
    const filterWordsExactMatch = !isSelectedSummary ? true : committedSearchCondition.filter.filterWordsExactMatch;

    const csvDownloadCondition = committedSearchCondition.mergeIn(['filter'], {
      filterWords,
      filterWordsExactMatch,
    });
    startCsvDownload(csvDownloadCondition);
  }, [committedSearchCondition, isSelectedSummary, selectedKeyword, startCsvDownload]);

  // 詳細モーダルで検索ボリューム調査への動線を表示するか
  const displaySearchVolumeLink = currentUser.canUseSearchVolume;

  // 表示設定のタグでの絞り込みと、開始日終了日のフィルターを適用した施策メモのリスト
  const filteredMemoList = useMemo(() => {
    // タグで絞り込み
    let _filteredMemoList = memoList.filterByTags(memoDisplaySettings.tags.toSet());
    // 開始日終了日のフィルター
    const { startMonth, endMonth } = committedSearchCondition.filter;
    const startDate = dayjs(startMonth);
    const endDate = dayjs(endMonth);
    _filteredMemoList = _filteredMemoList.filterByDates(startDate, endDate, 'month');
    return _filteredMemoList;
  }, [committedSearchCondition, memoList, memoDisplaySettings]);

  const canUseStoreConnectService = currentUser.organization
    ? currentUser.organization.canUseStoreConnectService()
    : false;

  return (
    <>
      <MainWrapper>
        <Helmet title={getPageTitle('検索キーワード')} />
        <StickyHeader>
          <Title>検索キーワード</Title>
          {canUseStoreConnectService && (
            <ServiceIconWrapper>
              <ServiceIcon.Google />
            </ServiceIconWrapper>
          )}
        </StickyHeader>
        <WideBody>
          <FilterWrapper>
            <SearchKeywordsSearchCondition
              committedSearchCondition={committedSearchCondition}
              commitSearchCondition={commitSearchCondition}
              isCommitDisabled={isLoadingGraphData || isLoadingTableData}
              isProcessingCsvDownload={isProcessingCsvDownload}
              startCsvDownload={startCsvDownload}
              checkCsvDownloadStatus={checkCsvDownloadStatus}
              clearCsvDownload={clearCsvDownload}
              openExcludedSearchKeywordsConfig={handleOpenExcludedSearchKeywordsConfig}
              isOpenExcludedKeywordsStickyFooter={isOpenExcludedKeywordsStickyFooter}
              setIsOpenExcludedKeywordsStickyFooter={setIsOpenExcludedKeywordsStickyFooter}
              excludedKeywordCount={excludedKeywords.items.size}
            />
          </FilterWrapper>
          <ChartWrapper>
            <SearchKeywordsGraph
              graphData={searchKeyword.graphData}
              graphType={committedSearchCondition.filter.type}
              startMonth={committedSearchCondition.filter.startMonth}
              endMonth={committedSearchCondition.filter.endMonth}
              selectedMonth={committedSearchCondition.filter.targetMonth}
              onChangeGraphType={handleOnChangeGraphType}
              onChangeSelectedMonth={handleOnChangeSelectedMonth}
              isLoading={isLoadingGraphData}
              memoList={filteredMemoList}
              memoDisplaySettings={memoDisplaySettings}
              onClickMemo={(ids) => setselectingMemoIds(ImmutableSet(ids))}
            />
          </ChartWrapper>
          <AnalysisMemoTable
            memoList={filteredMemoList}
            activeMemoIds={selectingMemoIds}
            clearActiveMemoIds={() => setselectingMemoIds(ImmutableSet())}
            tags={memoTagList}
            memoDisplaySettings={memoDisplaySettings}
            setMemoDisplaySettings={setMemoDisplaySettings}
            onCreateMemo={createMemo}
            onUpdateMemo={updateMemo}
            onDeleteMemo={deleteMemo}
            isLoadingMemoList={isLoadingMemoList}
            isLoadingMemoTagList={isLoadingMemoTagList}
            stickyPosition={'Title'}
          />
          <TableWrapper>
            <SearchKeywordsTable
              tableData={searchKeyword.tableData}
              sortKey={committedSearchCondition.sort.key}
              sortOrder={committedSearchCondition.sort.order}
              onChangeSort={handleOnChangeSort}
              onChangePage={handleOnChangePage}
              onChangeSelectedMonth={handleOnChangeSelectedMonth}
              openExcludedKeywordCreateModal={handleOpenExcludedKeywordCreateModal}
              openExcludeKeywordsConfig={handleOpenExcludedSearchKeywordsConfig}
              isLoading={isLoadingTableData}
              excludes={currentExcludedKeywords}
              displaySearchVolumeLink={currentUser.canUseSearchVolume}
              onClickKeyword={handleOnClickKeyword}
              onClickSummary={handleOnClickSummary}
            />
          </TableWrapper>
        </WideBody>
      </MainWrapper>
      <ExcludedSearchKeywordsConfigModal
        isOpen={openExcludedSearchKeywordsConfig}
        excludedKeywords={excludedKeywords}
        setIsOpenExcludedKeywordsStickyFooter={setIsOpenExcludedKeywordsStickyFooter}
        isLoadingExcludedKeywordsList={isLoadingExcludedKeywordsList}
        onClose={handleCloseExcludedSearchKeywordsConfig}
      />
      <ExcludedKeywordCreateModal
        isOpen={openExcludedKeywordCreateModal}
        keyword={excludedKeywordForCreate.keyword}
        exactMatch={excludedKeywordForCreate.exactMatch}
        disabled={errorMessageForExcludedKeywordCreate !== ''}
        errorMessage={errorMessageForExcludedKeywordCreate}
        onChange={(keyword, exactMatch) => setExcludedKeywordForCreate(new ExcludedKeyword({ keyword, exactMatch }))}
        onCreate={handleCreateExcludedKeyword}
        onClose={handleCloseExcludedKeywordCreateModal}
      />
      <SearchKeywordsDetailModal
        isOpen={isOpenDetailModal}
        onClose={() => setIsOpenDetailModal(false)}
        displaySearchVolumeLink={displaySearchVolumeLink}
        keyword={selectedKeyword}
        isSummary={isSelectedSummary}
        searchCondition={committedSearchCondition}
        stores={stores}
        onClickCsvDownload={handleOnClickKeywordCsvDownload}
        isProcessingCsvDownload={isProcessingCsvDownload}
        memoList={filteredMemoList}
        memoTagList={memoTagList}
        memoDisplaySettings={memoDisplaySettings}
        setMemoDisplaySettings={setMemoDisplaySettings}
        onCreateMemo={createMemo}
        onUpdateMemo={updateMemo}
        onDeleteMemo={deleteMemo}
        isLoadingMemoList={isLoadingMemoList}
        isLoadingMemoTagList={isLoadingMemoTagList}
      />
      {isLoadingExcludedKeyword && (
        <LoaderWrapper>
          <StyledLoader active={isLoadingExcludedKeyword} size='big' inline />
        </LoaderWrapper>
      )}
      {isOpenExcludedKeywordsStickyFooter && (
        <ExcludedKeywordFooter setIsOpenExcludedKeywordsStickyFooter={setIsOpenExcludedKeywordsStickyFooter} />
      )}
    </>
  );
});

const FilterWrapper = styled.div`
  background: white;
  width: 100%;
  margin-bottom: 32px;
`;
const ChartWrapper = styled.div`
  width: 100%;
  margin-bottom: 32px;
`;
const TableWrapper = styled.div`
  width: 100%;
`;

const LoaderWrapper = styled.div`
  background: #f8f8f880;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const StyledLoader = styled(Loader)`
  &&&& {
    &:before {
      border-color: rgba(0, 0, 0, 0.1);
    }
    &:after {
      border-color: #767676 transparent transparent;
    }
  }
`;

const ServiceIconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto 0 0;
  gap: 6px;
`;
