import actionCreatorFactory from 'typescript-fsa';

import { ImportResult, ImportStatus, ValidateError, ValidateWarning } from './type';

const actionCreator = actionCreatorFactory('StoresImport');

export const StoresImportActions = {
  importStores: actionCreator<File>('importStores'),
  storesImportValidate: actionCreator<File>('storesImportValidate'),
  checkCsvImportStatus: actionCreator('checkCsvImportStatus'),

  clearStatus: actionCreator('clearStatus'),
  clearValidateResult: actionCreator('clearValidateResult'),
  setValidateResult: actionCreator<{
    isValidateError: boolean;
    isValidateWarning: boolean;
    validateWarnings: ValidateWarning;
    validateErrors: ValidateError;
    totalSucceed: number;
    totalWarning: number;
    totalFailed: number;
  }>('setValidateResult'),

  setExectionArn: actionCreator<string>('setExectionArn'),
  startImport: actionCreator<string>('startImport'),
  finishImport: actionCreator('finishImport'),
  setImportStatus: actionCreator<{ importStatus: ImportStatus; importResults: ImportResult[] }>('setImportResult'),
  downloadStoresCsv: actionCreator('downloadStoresCsv'),
  setIsProcessingValidate: actionCreator<boolean>('setIsProcessingValidate'),
  setIsProcessingImport: actionCreator<boolean>('setIsProcessingImport'),
};
