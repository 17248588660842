import React from 'react';

import styled from 'styled-components';

export interface ServiceIconProps {
  color: string;
  label: string;
}

const LabelIcon = React.memo<ServiceIconProps>(({ color, label }) => {
  return <StyledIcon color={color}>{label}</StyledIcon>;
});

const StyledIcon = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  width: 20px;
  height: 20px;
  background-color: ${({ color }) => color};
  color: white;
  font-weight: bold;
  line-height: 1;
`;

const GoogleIcon = () => <LabelIcon label={'G'} color='#4585ee' />;
const AppleIcon = () => <LabelIcon label={'A'} color='black' />;
const YahooIcon = () => <LabelIcon label={'Y'} color='#ff0033' />;

const ServiceIcon = {
  Google: GoogleIcon,
  Apple: AppleIcon,
  Yahoo: YahooIcon,
} as const;

export { ServiceIcon };
