import React from 'react';

import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { EditSpecialHours } from 'components/pageComponents/BulkEditStores/EditSpecialHours';
import { GmbSpecialHours } from 'models/Domain/GmbLocation/GmbSpecialHours';
import { COLOR } from 'style/color';

export const BulkEditSpecialHours: React.FC<{
  gmbSpecialHours: GmbSpecialHours;
  isConnectedToYPlace: boolean;
  setGmbSpecialHours: (v: GmbSpecialHours) => void;
}> = ({ gmbSpecialHours, isConnectedToYPlace, setGmbSpecialHours }) => {
  return (
    <>
      <Modal.Header>特別営業時間の変更</Modal.Header>
      <Modal.Content scrolling>
        <ItemLabel>変更内容</ItemLabel>
        <Divider />
        <EditSpecialHours
          specialHours={gmbSpecialHours}
          isConnectedToYPlace={isConnectedToYPlace}
          changeSpecialHours={(value: GmbSpecialHours) => {
            setGmbSpecialHours(value);
          }}
        />
      </Modal.Content>
    </>
  );
};

const ItemLabel = styled.label`
  display: block;
  font-weight: bold;
`;

const Divider = styled.hr`
  border-top: 1px solid ${COLOR.LIGHT_GRAY};
  border-bottom: none;
  margin: 20px 0;
`;
