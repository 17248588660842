import React from 'react';

import styled from 'styled-components';

import { Link } from 'components/atoms/Link';
import { ServiceIcon } from 'components/atoms/ServiceIcon';
import { Store } from 'models/Domain/Store';
import { COLOR } from 'style/color';

type Props = {
  canUseAbc: boolean; // 組織でApple Business Connect連携が利用可能かどうか
  store: Store;
  selected: boolean;
  storeDetailLink: string;
  onClick: () => void;
};

/**
 * 店舗一覧のSP版と店舗詳細のリストに表示する店舗情報
 */
export const StoreCard = React.memo<Props>(({ canUseAbc, store, selected, storeDetailLink, onClick }) => {
  const {
    id,
    code,
    fullName,
    isConnectedGBP,
    isConnectedABC,
    isConnectedYahooPlace,
    location: { openInfo },
    locationState,
    yahooPlaceState,
  } = store;
  const isGMBError = isConnectedGBP && locationState.isGmbError;
  const isABCError = false; // TODO エラー状態を取得できるようになったら修正する
  const isYahooPlaceError = isConnectedYahooPlace && yahooPlaceState.isError;
  const storeDetailUrl = storeDetailLink.replace(':storeId', String(id));

  return (
    <Wrapper checked={selected} isError={isGMBError || isABCError || isYahooPlaceError} onClick={onClick}>
      <StatusContainer>
        {(isConnectedGBP || isConnectedABC || isConnectedYahooPlace) && (
          <ConnectedServicesWrapper>
            {isConnectedGBP && (
              <ConnectedServiceIconWrapper hasError={isGMBError}>
                <ServiceIcon.Google />
              </ConnectedServiceIconWrapper>
            )}
            {isConnectedYahooPlace && (
              <ConnectedServiceIconWrapper hasError={isYahooPlaceError}>
                <ServiceIcon.Yahoo />
              </ConnectedServiceIconWrapper>
            )}
            {isConnectedABC && (
              <ConnectedServiceIconWrapper hasError={isABCError}>
                <ServiceIcon.Apple />
              </ConnectedServiceIconWrapper>
            )}
          </ConnectedServicesWrapper>
        )}
        <BusinessStatus isOpen={openInfo.isOpen}>{openInfo.statusName}</BusinessStatus>
      </StatusContainer>
      <DetailLink onClick={(e) => e.stopPropagation()} to={storeDetailUrl}>
        {fullName}
      </DetailLink>
      {code && <Code>{code}</Code>}
    </Wrapper>
  );
});

const Wrapper = styled.div<{ checked: boolean; isError: boolean }>`
  padding: 8px 12px;

  background: ${({ checked, isError }) =>
    checked ? 'rgba(207, 242, 230, 0.6)' : isError ? 'rgba(247, 216, 216, 0.4)' : COLOR.WHITE};

  :hover {
    cursor: pointer;
    background: ${({ checked, isError }) =>
      checked ? 'rgba(207, 242, 230, 1)' : isError ? 'rgba(247, 216, 216, 1.0)' : 'rgba(0, 0, 0, 0.04)'};
  }

  border: 1px solid ${COLOR.LIGHT_GRAY};
  :not(:first-child) {
    margin-top: -1px;
  }
`;

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ConnectedServicesWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ConnectedServiceIconWrapper = styled.div<{ hasError: boolean }>`
  position: relative;
  width: 20px;
  height: 20px;

  // hasError: trueの場合は、右下にアイコンを表示する
  ${({ hasError }) =>
    hasError &&
    `
    &::after {
      content: '';
      position: absolute;
      bottom: -2px;
      right: -2px;
      width: 10px;
      height: 10px;
      background: url('/images/icon_error_inverted.svg') no-repeat;
    };
  `}
`;

const Code = styled.div`
  font-size: 12px;
`;

const BusinessStatus = styled.div<{ isOpen: boolean }>`
  &&& {
    color: ${({ isOpen }) => (isOpen ? '#757575' : COLOR.PURPLE)};
    font-size: 12px;
  }
`;

const DetailLink = styled(Link)`
  font-weight: bold;
  text-decoration: underline;
  font-size: 16px;
`;
