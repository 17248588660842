import React, { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Form, Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Input } from 'components/atoms/Input';
import { YahooPlaceActions } from 'modules/yahooPlace/actions';
import { COLOR } from 'style/color';

type Props = { isOpen: boolean; onClose: () => void; setSelectedYahooAccountId: (id: number | null) => void };

export const YahooPlaceAccountModal: React.FC<Props> = ({ isOpen, onClose, setSelectedYahooAccountId }) => {
  const dispatch = useDispatch();
  const { registerYahooPlaceAccount, yahooPlaceAuth } = useSelector((state) => ({
    registerYahooPlaceAccount: state.yahooPlace.registerYahooPlaceAccount,
    yahooPlaceAuth: state.yahooPlace.yahooPlaceAuth,
  }));

  const handleRegisterYahooPlaceAccount = useCallback(() => {
    dispatch(
      YahooPlaceActions.registerYahooPlaceAccount({
        account_id: yahooPlaceAuth.accountId,
        name: registerYahooPlaceAccount.name,
        redirect_uri: yahooPlaceAuth.redirectUri,
        state: yahooPlaceAuth.state,
        code: yahooPlaceAuth.code,
      }),
    );
    onClose();
    dispatch(YahooPlaceActions.clearRegisterYahooPlaceAccount());
    setSelectedYahooAccountId(null);
  }, [dispatch, registerYahooPlaceAccount, yahooPlaceAuth, onClose, setSelectedYahooAccountId]);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalContent>
        <Wrapper>
          <Title>Yahoo! プレイス アカウントの追加</Title>
          <ContentWrapper>
            <Description>
              連携するアカウントの名前を入力してください。
              <br />
              入力した名前は店舗をYahoo! プレイスと連携するアカウントを選択する際に表示されます。
            </Description>
            <Content>
              <Form>
                <Form.Field>
                  <label>アカウント名</label>
                  <StyledInput
                    value={registerYahooPlaceAccount.name}
                    onChange={(value) => dispatch(YahooPlaceActions.changeRegisterYahooPlaceAccountName(value))}
                    placeholder='ブランド名、会社名、オーナー名など'
                  />
                </Form.Field>
              </Form>
            </Content>
          </ContentWrapper>
          <ModalActions>
            <CancelButton onClick={onClose}>キャンセル</CancelButton>
            <StyledButton priority={'high'} onClick={handleRegisterYahooPlaceAccount}>
              連携
            </StyledButton>
          </ModalActions>
        </Wrapper>
      </ModalContent>
    </Modal>
  );
};

const ModalActions = styled(Modal.Actions)`
  display: flex;
  justify-content: flex-end;

  @media (max-width: 600px) {
    flex-direction: column;
    button {
      margin-bottom: 20px;
    }
  }
`;

const StyledButton = styled(Button)`
  &&& {
    width: 180px;
  }
`;

const CancelButton = styled(StyledButton)`
  margin-right: 12px;
`;

const Description = styled.div`
  margin-bottom: 24px;
  color: ${COLOR.DARK_GRAY};
`;

const StyledInput = styled(Input)`
  &&& {
    width: 100%;
  }
`;

const ModalContent = styled(Modal.Content)`
  height: 100%;
`;

const Wrapper = styled.div`
  height: 100%;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${COLOR.GRAY};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
`;

const Content = styled.div`
  margin-bottom: 32px;
`;
