import React, { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Form, Header, Message, Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { DetailLink } from 'components/atoms/DetailLink';
import { Link } from 'components/atoms/Link';
import { PullDown } from 'components/atoms/PullDown';
import Account from 'models/Domain/Abc/Account';
import Business from 'models/Domain/Abc/Business';
import Location from 'models/Domain/Abc/Location';
import { Store } from 'models/Domain/Store';
import { AbcActions } from 'modules/abc/actions';
import { Path } from 'routes';

type Props = { isOpen: boolean; onClose: () => void; targetStore: Store };

/** 店舗のApple Business Connect連携設定モーダル */
export const AbcConnectModal: React.FC<Props> = ({ isOpen, onClose, targetStore }) => {
  // アカウントが一つも追加されてない時に表示するメッセージ
  const MESSAGE_NODE_FOR_ACCOUNT_NOT_EXISTS = (
    <div>
      アカウントが追加されていないため、ロケーションの一覧を取得できません。
      <Link to={Path.storeAccounts.index}>
        <DetailLink>店舗連携アカウント画面</DetailLink>
      </Link>
      から追加してください。
    </div>
  );
  // 契約店舗数を超えて連携できない時に表示するメッセージ
  const REACHED_CONTRACTED_STORE_LIMIT_MESSAGE =
    '契約店舗数の上限に達しているため、新たに店舗をApple Business Connectと連携できません。上限の変更につきましては、メニューの「サポート」よりお問い合わせください。';

  const dispatch = useDispatch();
  const { abcState, appState, storeState } = useSelector((state) => ({
    abcState: state.abc,
    appState: state.app,
    storeState: state.store,
  }));

  const { accounts, notImportedLocations } = abcState;

  /** 選択中のアカウント、ビジネス、ロケーション */
  const [selectingAccountId, setSelectingAccountId] = useState<number | 'ALL'>('ALL');
  const [selectingBusinessId, setSelectingBusinessId] = useState<string>('ALL');
  const [selectingLocationId, setSelectingLocationId] = useState<string>('');

  useEffect(() => {
    dispatch(AbcActions.getLocations());
  }, [dispatch]);

  const onClickDisconnectButton = useCallback(() => {
    if (!targetStore) {
      return;
    }
    if (!confirm(`${targetStore?.fullName}のApple Business Connect連携を解除しますか？`)) {
      return;
    }
    dispatch(AbcActions.disconnect(targetStore.id));
    onClose();
  }, [dispatch, targetStore, onClose]);

  const onClickCancelButton = useCallback(() => {
    setSelectingAccountId('ALL');
    setSelectingBusinessId('ALL');
    setSelectingLocationId('');
    onClose();
  }, [setSelectingAccountId, setSelectingBusinessId, setSelectingLocationId, onClose]);

  const onClickConnectButton = useCallback(() => {
    if (!targetStore || selectingLocationId === '') {
      return;
    }

    let accountId: number;
    if (selectingAccountId === 'ALL') {
      // アカウントが選択されていない（"すべて"が選択されている）場合、selectingLocationIdに紐づくアカウントIDを取得する
      const selectingLocationAccountId = accounts.get_location(selectingLocationId)?.accountId;
      if (!selectingLocationAccountId) {
        return;
      }
      accountId = selectingLocationAccountId;
    } else {
      accountId = selectingAccountId;
    }

    const params = { storeId: targetStore.id, accountId, locationId: selectingLocationId };
    dispatch(AbcActions.connect(params));

    setSelectingAccountId('ALL');
    setSelectingBusinessId('ALL');
    setSelectingLocationId('');
    onClose();
  }, [
    accounts,
    dispatch,
    targetStore,
    selectingAccountId,
    selectingLocationId,
    setSelectingBusinessId,
    setSelectingLocationId,
    onClose,
  ]);

  const onChangeAccountId = useCallback(
    (value: number | 'ALL') => {
      setSelectingAccountId(value);
      if (selectingAccountId !== value) {
        // ビジネス、ロケーションの選択をクリア
        setSelectingBusinessId('ALL');
        setSelectingLocationId('');
      }
    },
    [selectingAccountId, setSelectingBusinessId, setSelectingLocationId],
  );

  const onChangeBusinessId = useCallback(
    (value: string) => {
      setSelectingBusinessId(value === null ? 'NOT_GROUPED' : value);
      if (selectingBusinessId !== value) {
        setSelectingLocationId(''); // ロケーションの選択をクリア
      }
    },
    [selectingBusinessId, setSelectingBusinessId, setSelectingLocationId],
  );

  const currentUser = appState.currentUser;
  const organization = currentUser.organization;
  if (organization === null) {
    return <></>;
  }

  // マップサービス連携中の店舗数
  const connectedStoresCount = storeState.stores.filterByIsConnectedToMapService().list.size;

  // 連携対象の選択肢
  const accountPulldownOptions = [
    { text: 'すべて', value: 'ALL' },
    ...accounts.list.map((account: Account) => ({ text: account.name, value: account.id })).toArray(),
  ];
  const businessPulldownOptions =
    // アカウントで絞り込みしている場合、アカウントに属するビジネスのみ選択可能
    selectingAccountId !== 'ALL'
      ? [
          { text: 'すべて', value: 'ALL' },
          ...(accounts.list
            .find((account: Account) => account.id === selectingAccountId)
            ?.businesses.map((business: Business) => ({
              text: business.displayName,
              value: business.id === null ? 'NOT_GROUPED' : business.id,
            }))
            .toArray() || []),
        ]
      : // アカウントの絞り込みをしない場合、すべてのビジネスを選択可能
        [
          { text: 'すべて', value: 'ALL' },
          ...accounts.businesses
            .map((business: Business) => ({ text: business.displayName, value: business.id }))
            .toArray(),
        ];
  const locationPulldownOptions = notImportedLocations
    .filter((location: Location) => selectingAccountId === 'ALL' || location.accountId === selectingAccountId)
    .filter((location: Location) =>
      selectingBusinessId === 'ALL'
        ? true
        : selectingBusinessId === 'NOT_GROUPED'
          ? location.businessId === null
          : location.businessId === selectingBusinessId,
    )
    .map((location: Location) => ({ text: location.displayName, value: location.id }))
    .toArray();

  // アカウントが選択されて連携できるロケーションがない場合は警告表示
  const showWarning = !accounts.list.isEmpty() && !locationPulldownOptions.length;

  // 連携ボタンの活性/非活性
  // 連携したいロケーションが選択されている かつ マップサービス連携中の店舗数が契約店舗数上限を超えていない
  const reachedContractedStoreLimit =
    !targetStore?.isConnectedToMapService && organization.contractedStores <= connectedStoresCount;
  const canConnect = !!selectingLocationId && !reachedContractedStoreLimit;
  const showDisconnectButton = targetStore?.isConnectedABC && !currentUser.isMemberUser;

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Modal.Header>Apple Business Connect連携設定</Modal.Header>
      <Modal.Content>
        {/* アカウントが一つも追加されてない時に表示するメッセージ */}
        {accounts.list.isEmpty() && <Message warning content={MESSAGE_NODE_FOR_ACCOUNT_NOT_EXISTS} />}
        {/* 契約店舗数上限に達している場合メッセージを表示 */}
        {reachedContractedStoreLimit && <Message error content={REACHED_CONTRACTED_STORE_LIMIT_MESSAGE} />}

        <Header>{targetStore.fullName}</Header>
        <Form warning={showWarning}>
          <Form.Field>
            <label>アカウント</label>
            <CustomPullDown
              value={selectingAccountId}
              options={accountPulldownOptions}
              placeholder={'アカウント選択'}
              multiple={false}
              onChange={onChangeAccountId}
              disabled={accounts.list.isEmpty()}
            />
          </Form.Field>
          <Form.Field>
            <label>ビジネス</label>
            <CustomPullDown
              value={selectingBusinessId}
              options={businessPulldownOptions}
              placeholder={'ビジネス選択'}
              multiple={false}
              onChange={onChangeBusinessId}
              disabled={accounts.businesses.isEmpty()}
            />
          </Form.Field>
          <Form.Field>
            <label>ロケーション</label>
            <CustomPullDown
              value={selectingLocationId}
              options={locationPulldownOptions}
              placeholder={'ロケーション選択'}
              multiple={false}
              onChange={(value: string) => setSelectingLocationId(value)}
              disabled={!locationPulldownOptions.length}
            />
          </Form.Field>
          <Message warning content='新規に取り込みができるロケーションがありません。' />
        </Form>
      </Modal.Content>
      <ModalActions>
        <LeftButton>
          {showDisconnectButton && (
            <StyledButton negative priority={'high'} onClick={onClickDisconnectButton}>
              連携解除
            </StyledButton>
          )}
        </LeftButton>
        <RightButtons>
          <CancelButton onClick={onClickCancelButton}>キャンセル</CancelButton>
          <StyledButton priority={'high'} disabled={!canConnect} onClick={onClickConnectButton}>
            連携
          </StyledButton>
        </RightButtons>
      </ModalActions>
    </Modal>
  );
};

const CustomPullDown = styled(PullDown)`
  margin-top: 16px;
`;

const ModalActions = styled(Modal.Actions)`
  display: flex;
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-direction: column;
    button {
      margin-bottom: 20px;
    }
  }
`;

const LeftButton = styled.div``;
const RightButtons = styled.div``;

const StyledButton = styled(Button)`
  &&& {
    width: 180px;
  }
`;

const CancelButton = styled(StyledButton)`
  margin-right: 12px;
`;
