import React, { useCallback, useMemo } from 'react';

import { List, Set } from 'immutable';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Icon } from 'components/atoms/Icon';
import { Link } from 'components/atoms/Link';
import { ServiceIcon } from 'components/atoms/ServiceIcon';
import { StickyHeader } from 'components/atoms/StickyHeader';
import { GmbLocationDiffsAlert } from 'components/molecules/StoreIndex/GmbLocationDiffsAlert';
import { LocationErrorAlert } from 'components/molecules/StoreIndex/LocationErrorAlert';
import { SelectListFooter } from 'components/pageComponents/StoreIndex/SelectListFooter';
import { StoreCardList } from 'components/pageComponents/StoreIndex/StoreCardList';
import { StoreFilter } from 'components/pageComponents/StoreIndex/StoreFilter';
import { StoreTable } from 'components/pageComponents/StoreIndex/StoreTable';
import { WideBody } from 'components/templates/MainWrapper';
import { replaceWithOrganizationId } from 'helpers/router';
import { getPageTitle } from 'helpers/utils';
import { useDisplayType } from 'hooks/useDisplayType';
import { GmbLocationDiffs } from 'models/Domain/GmbLocationDiffs';
import { SortType, Stores } from 'models/Domain/Store';
import { StoreLists } from 'models/Domain/StoreList';
import { StoreSearchCondition } from 'models/Domain/StoreSearchCondition';
import { BulkEditStoreState } from 'models/Other/BulkEditStoreState';
import { Path } from 'routes';

/**
 * 店舗のテーブル表示のコンテンツ
 */
export const StoreIndexTableContents: React.FC<{
  stores: Stores;
  storeLists: StoreLists;
  bulkEditStoreState: BulkEditStoreState;
  // Googleビジネスプロフィールからの変更依頼
  gmbLocationDiffs: GmbLocationDiffs;
  canSelectStore: boolean;
  displayManagedList: boolean;
  managedStores: List<number>;
  setBulkEditStoreState: (v: BulkEditStoreState) => void;
  searchCondition: StoreSearchCondition;
  setSearchCondition: (searchCondition: StoreSearchCondition) => void;
  showCsvImportButton: boolean;
  canUseAbc: boolean; // 組織でApple Business Connect連携が利用可能かどうか
  canUseYahooPlace: boolean; // 組織でYahoo!プレイス連携が利用可能かどうか
}> = ({
  stores,
  storeLists,
  bulkEditStoreState,
  gmbLocationDiffs,
  canSelectStore,
  setBulkEditStoreState,
  displayManagedList,
  managedStores,
  searchCondition,
  setSearchCondition,
  showCsvImportButton,
  canUseAbc,
  canUseYahooPlace,
}) => {
  const dispatch = useDispatch();
  const { key: sortKey, order: sortOrder } = searchCondition.sort;
  const filter = searchCondition.filter;
  const { searchValue, storeListId, showClosedStore } = filter;
  const selectedSortId = `${sortKey}_${sortOrder}` as SortType;
  const gbpErrorStores = useMemo(() => stores.filterByIsGmbError().list, [stores]);
  const yahooPlaceErrorStores = useMemo(() => stores.filterByIsYahooPlaceError().list, [stores]);
  let filteredStores = stores;

  const isDesktop = useDisplayType('800px').isDesktop;

  const targetFilterStores = showClosedStore ? stores : stores.filterByUnclosed();

  if (storeListId === 'all') {
    filteredStores = targetFilterStores.filterStores(searchValue).sortBy(selectedSortId);
  } else if (storeListId === 'managed_list') {
    filteredStores = targetFilterStores
      .filterStores(searchValue)
      .filterStoresById(managedStores.toArray())
      .sortBy(selectedSortId);
  } else {
    // 絞り込み・並び替えされた店舗データ
    filteredStores = targetFilterStores
      .filterStores(searchValue)
      .filterStoresByStoreListId(storeLists, storeListId)
      .sortBy(selectedSortId);
  }

  // 検索条件を引き継ぐために、パラメータを付与した店舗詳細のURL
  const storeDetailLink = `${Path.store.detail}?${searchCondition.toURLSearchParams()}`;

  const { selectedStoreIds } = bulkEditStoreState;

  // 全ての店舗が選択中かどうか
  const allChecked = !filteredStores.isEmpty && Set(filteredStores.getStoreIds()).subtract(selectedStoreIds).size === 0;

  const onChangeSearchCondition = useCallback(
    (searchCondition: StoreSearchCondition) => {
      setBulkEditStoreState(bulkEditStoreState.removeAllStoreIds());
      setSearchCondition(searchCondition);
    },
    [bulkEditStoreState, setBulkEditStoreState, setSearchCondition],
  );

  const onChangeAllSelectCheckBox = () => {
    if (allChecked) {
      // すべて選択状態なら、すべての選択を解除する
      setBulkEditStoreState(bulkEditStoreState.removeAllStoreIds());
    } else {
      // すべてが選択状態になっていないなら、すべてを選択する
      setBulkEditStoreState(bulkEditStoreState.selectAllStoreIds(filteredStores));
    }
  };

  const handleOnChangeStoreSelect = (id: number) => {
    setBulkEditStoreState(bulkEditStoreState.changeSelectedStoreIds(id));
  };

  const handleOnClickStoreCard = (id: number) => {
    // 店舗詳細へのリンクに、検索条件を引き継ぐためのパラメータを付与する
    dispatch(replaceWithOrganizationId(`${storeDetailLink.replace(':storeId', String(id))}`));
  };

  const showLocationErrorAlert = !gbpErrorStores.isEmpty() || !yahooPlaceErrorStores.isEmpty();
  const canUseStoreConnectService = canUseAbc || canUseYahooPlace;

  return (
    <>
      <Helmet title={getPageTitle('店舗一覧')} />
      <StickyHeader>
        <Title>店舗一覧</Title>
        {canUseStoreConnectService && (
          <ServiceIconWrapper>
            <ServiceIcon.Google />
            {canUseYahooPlace && <ServiceIcon.Yahoo />}
            {canUseAbc && <ServiceIcon.Apple />}
          </ServiceIconWrapper>
        )}
        <ActionContainer>
          {isDesktop && showCsvImportButton && (
            <Link to={Path.store.import}>
              <StyledButton>CSVでまとめて更新</StyledButton>
            </Link>
          )}
          <Link to={Path.store.register}>
            <StyledButton priority={'high'}>
              <StoreIcon type='store_white' />
              店舗を登録
            </StyledButton>
          </Link>
        </ActionContainer>
      </StickyHeader>
      <WideBody>
        <Wrapper>
          {/* エラーのある店舗がある場合 */}
          {showLocationErrorAlert && (
            <AlertWrapper>
              <LocationErrorAlert
                gbpStores={gbpErrorStores.toArray()}
                yahooPlaceStores={yahooPlaceErrorStores.toArray()}
              />
            </AlertWrapper>
          )}

          {/* Googleからの変更提案がある時、確認を促す */}
          {gmbLocationDiffs && !gmbLocationDiffs.list.isEmpty() && (
            <AlertWrapper>
              <GmbLocationDiffsAlert gmbLocationDiffs={gmbLocationDiffs} />
            </AlertWrapper>
          )}

          <StoreFilter
            storeLists={storeLists}
            setBulkEditStoreState={setBulkEditStoreState}
            displayManagedList={displayManagedList}
            searchCondition={searchCondition}
            setSearchCondition={setSearchCondition}
            onChangeSearchCondition={onChangeSearchCondition}
            showSwitch={!isDesktop}
          />

          <StoreCount>{filteredStores.list.size}件の検索結果</StoreCount>

          {/* リスト表示とテーブル表示を画面幅によって切り替える */}
          {isDesktop ? (
            <StoreTable
              stores={filteredStores}
              selectedStoreIds={selectedStoreIds}
              canUseAbc={canUseAbc}
              canUseYahooPlace={canUseYahooPlace}
              canSelectStore={canSelectStore}
              onChangeAllSelect={onChangeAllSelectCheckBox}
              onChangeStoreSelect={handleOnChangeStoreSelect}
              storeDetailLink={storeDetailLink}
            />
          ) : (
            <StoreCardList
              canUseAbc={canUseAbc}
              stores={filteredStores}
              onClick={handleOnClickStoreCard}
              storeDetailLink={storeDetailLink}
            />
          )}
        </Wrapper>

        {bulkEditStoreState.selectedStoreIds.size > 0 && (
          <SelectListFooter
            selectedSize={bulkEditStoreState.selectedStoreIds.size}
            onCancel={() => {
              setBulkEditStoreState(bulkEditStoreState.removeAllStoreIds());
            }}
          />
        )}
      </WideBody>
    </>
  );
};

const Wrapper = styled.div``;

const StoreCount = styled.div`
  color: #757575;
  margin-bottom: 10px;
`;

const AlertWrapper = styled.div`
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 18px;
  white-space: nowrap;
  margin-right: 8px;

  @media (max-width: 600px) {
    font-size: 21px;
  }
`;

const ServiceIconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto 0 20px;
  gap: 6px;
`;

const ActionContainer = styled.div`
  display: flex;
  > {
    :not(:last-child) {
      margin-right: 15px;
    }
  }
`;

const StyledButton = styled(Button)`
  &&& {
    width: auto;
    font-size: 14px;
    padding: 11px 13px;
  }
`;

const StoreIcon = styled(Icon)`
  &&& {
    width: 14px;
    height: 14px;
    vertical-align: middle;
    margin-right: 8px;
    padding: 0 0 2px 0;
  }
`;
