import React from 'react';

import styled from 'styled-components';

interface Props {
  className?: string;
  maxSize?: number;
  size: number;
}

export const Counter: React.FC<Props> = ({ className, maxSize, size }) => {
  return (
    <Wrapper className={className}>
      <Count error={maxSize ? size > maxSize : false}>{size}</Count>
      {maxSize && ` / ${maxSize}`}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const Count = styled.span<{ error: boolean }>`
  vertical-align: baseline;
  ${(props) => props.error && `color: #dd5252;`}
`;
