import React, { useCallback, useEffect, useMemo } from 'react';

import { List } from 'immutable';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { ServiceIcon } from 'components/atoms/ServiceIcon';
import { StickyHeader, Title } from 'components/atoms/StickyHeader';
import {
  MapCompetitorResearchSearchCondition,
  SEARCH_CONDITION_LIMIT,
} from 'components/pageComponents/MapCompetitorResearch/MapCompetitorResearchSearchCondition';
import { SearchHistoryTable } from 'components/pageComponents/MapCompetitorResearch/SearchHistoryTable';
import { MainWrapper, WideBody } from 'components/templates/MainWrapper';
import { getPageTitle } from 'helpers/utils';
import { MapCompetitorResearchSearchCondition as SearchCondition } from 'models/Domain/MapCompetitorResearch/MapCompetitorResearchSearchCondition';
import { MAX_HISTORIES } from 'models/Domain/MapCompetitorResearch/SearchHistory';
import { MapCompetitorResearchActions } from 'modules/mapCompetitorResearch/actions';
import { useCompetitorSettings, useSearchHistory } from 'modules/mapCompetitorResearch/hooks';
import { MapCompetitorResearchSelectors } from 'modules/mapCompetitorResearch/selectors';

export const MapCompetitorResearchIndex = React.memo(() => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.app);
  const searchCondition = useSelector(MapCompetitorResearchSelectors.selectSearchCondition);
  const { initializeIndexPage, setSearchCondition, commitSearchCondition } = useMemo(
    () => bindActionCreators(MapCompetitorResearchActions, dispatch),
    [dispatch],
  );
  useEffect(() => {
    initializeIndexPage();
  }, [initializeIndexPage]);

  const restoreCondition = useCallback(
    (condition: { areaNames: List<string>; searchWords: List<string> }) => {
      const searchCondition = new SearchCondition({
        areaNames: List(condition.areaNames),
        searchWords: List(condition.searchWords),
      });
      setSearchCondition(searchCondition);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    [setSearchCondition],
  );

  const [histories, , deleteHistory] = useSearchHistory();
  const [, , deleteCompetitorSetting] = useCompetitorSettings();

  const handleOnDeleteHistory = useCallback(
    (id: string) => {
      deleteHistory(id);
      deleteCompetitorSetting(id);
    },
    [deleteCompetitorSetting, deleteHistory],
  );

  const canUseStoreConnectService = currentUser.organization
    ? currentUser.organization.canUseStoreConnectService()
    : false;

  return (
    <MainWrapper>
      <Helmet title={getPageTitle('検索順位調査')} />
      <StickyHeader>
        <Title>検索順位調査</Title>
        {canUseStoreConnectService && (
          <ServiceIconWrapper>
            <ServiceIcon.Google />
          </ServiceIconWrapper>
        )}
      </StickyHeader>
      <WideBody>
        <Wrapper>
          <SearchHistoryHeader>
            検索条件
            <SmallDescription>
              検索条件数（検索地点数 × 検索ワード数）は最大{SEARCH_CONDITION_LIMIT}件まで指定できます
            </SmallDescription>
          </SearchHistoryHeader>
          <MapCompetitorResearchSearchCondition
            searchCondition={searchCondition}
            setSearchCondition={setSearchCondition}
            commitSearchCondition={commitSearchCondition}
          />
          <SearchHistoryContainer>
            <SearchHistoryHeader>
              検索履歴<SmallDescription>最大{MAX_HISTORIES}件保存されます</SmallDescription>
            </SearchHistoryHeader>
            <SearchHistoryTable
              searchHistoryList={histories}
              deleteHistory={handleOnDeleteHistory}
              restoreSearchCondition={restoreCondition}
            />
          </SearchHistoryContainer>
        </Wrapper>
      </WideBody>
    </MainWrapper>
  );
});

const Wrapper = styled.div``;
const SearchHistoryContainer = styled.div``;
const SearchHistoryHeader = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
`;
const SmallDescription = styled.span`
  display: inline-block;
  font-size: 14px;
  margin-left: 16px;
  font-weight: normal;
`;

const ServiceIconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto 0 0;
  gap: 6px;
`;
