import React from 'react';

import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Icon } from 'components/atoms/Icon';
import { Link } from 'components/atoms/Link';
import { ServiceIcon } from 'components/atoms/ServiceIcon';
import { StickyHeader, Title } from 'components/atoms/StickyHeader';
import { Path } from 'routes';

type Props = {
  canUseStoreConnectService: boolean;
};

export const PromotionInfoHeader = React.memo<Props>(({ canUseStoreConnectService }) => {
  return (
    <StickyHeader>
      <Title>投稿</Title>
      {canUseStoreConnectService && (
        <ServiceIconWrapper>
          <ServiceIcon.Google />
        </ServiceIconWrapper>
      )}
      <RightWrapper>
        <Link to={{ pathname: Path.localpost.create, state: { source: Path.localpost.index } }}>
          <StyledButton priority='high'>
            <SendIcon type='send_white' />
            投稿を作成
          </StyledButton>
        </Link>
      </RightWrapper>
    </StickyHeader>
  );
});

const ServiceIconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto 0 0;
  gap: 6px;
`;

const RightWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

const StyledButton = styled(Button)`
  &&& {
    width: auto;
    font-size: 14px;
    padding: 11px 13px;
  }
`;

const SendIcon = styled(Icon)`
  &&& {
    width: 14px;
    height: 14px;
    vertical-align: middle;
    margin-right: 8px;
    padding: 0 0 2px 0;
  }
`;
