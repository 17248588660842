import ApiClient from 'ApiClient';
import { GetAccountsResponse, GetLocationsResponse } from 'types/responses/Abc';

const apiClient = new ApiClient({});

const ABC_PATH = '/abc';

const GET_ACCOUNTS_URL = `${ABC_PATH}/accounts`;
const DELETE_ACCOUNT_URL = `${ABC_PATH}/accounts`;
const GET_LOCATIONS_URL = `${ABC_PATH}/locations`;

export class ABCAccountsAPI {
  static get() {
    return apiClient.get<GetAccountsResponse>(GET_ACCOUNTS_URL);
  }

  static delete(id: number) {
    return apiClient.delete(`${DELETE_ACCOUNT_URL}/${id}`);
  }
}

export class AbcLocationsApi {
  static get() {
    return apiClient.get<GetLocationsResponse>(GET_LOCATIONS_URL);
  }
}
